import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {OnPackageOptionChange} from './on-package-option-change';
import {DmConnectPackageOptions} from '../dm-connect-package-options';
import {expand} from 'rxjs/operators';

@Component({
  selector: 'app-file-uploader',
  template: `
    <div class="card d-block">
      <div class="card-body">
        <h5 class="card-title mb-3">Upload Previous Package Options</h5>
        <div class="input-group">
          <input name="file" class="form-control" id="file-upload" type="file" accept="application/json" (change)="onChooseFile($any($event.target).files)"/>
          <button type="button" class="btn btn-success float-end" (click)="onUploadClick()"><i class="mdi mdi-upload mr-1"></i>Upload</button>
        </div>
      </div>
    </div>
  `,
  styles: [``]
})

export class FileUploaderComponent implements OnPackageOptionChange {
  @Input() packageOptions: Subject<DmConnectPackageOptions>;
  @Output() onChange = new EventEmitter<any>();
  private fileContent: string;
  private fileReader = new FileReader();

  constructor() {
    this.fileReader.addEventListener('load', () => { this.fileContent = this.fileReader.result.toString(); }, false);
  }

  public onChooseFile(file: FileList): void {
    if (file.length > 0) {
      this.fileReader.readAsText(file[0]);
    }
  }

  onUploadClick(): void {
    if (this.fileContent) {
      this.onChange.emit({...new DmConnectPackageOptions(), ...JSON.parse(this.fileContent)});
    }
  }
}
