import {HasMergeableChildren} from './HasMergeableChildren';
import {ConfigurationOption} from '../../configuration-option';
import {TranspositionCategory} from './TranspositionCategory';
import {IdentifiableRecord} from './identifiable-record';

export class ApplicationInterface extends HasMergeableChildren<TranspositionCategory> implements ConfigurationOption, IdentifiableRecord {

  static ensure(x: ApplicationInterface): ApplicationInterface {
    const id = x.id;
    const name = x.name;
    const categories = x.categories;

    return x instanceof ApplicationInterface ? x : new ApplicationInterface(id, name, categories);
  }

  protected ensureChildType(input: TranspositionCategory): TranspositionCategory {
    return TranspositionCategory.ensure(input);
  }

  protected mergeChild(original: TranspositionCategory, supersede: TranspositionCategory, is_default?: boolean): void {
    if (original && supersede) {
      original.mergeChildren(supersede.transpositions, is_default);
    }
  }

  constructor(public readonly id: string, public readonly name: string, public readonly categories: TranspositionCategory[] = []) {
    super(categories);
    this.buildStructures(categories);
  }

  public toPersistableClone(): ApplicationInterface {
    const categories: TranspositionCategory[] = [];

    for (const category of this.children) {
      categories.push(category.toPersistableClone());
    }

    const output = new ApplicationInterface(this.id, this.name, categories);
    delete output.children_dictionary;
    delete output.children;

    return output;
  }
}
