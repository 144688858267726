import {ApplicationInterface} from './ApplicationInterface';
import {HasMergeableChildren} from './HasMergeableChildren';

export class TranspositionLibrary extends HasMergeableChildren<ApplicationInterface> {


  constructor(public readonly name: string, public interfaces: ApplicationInterface[]) {
    super(interfaces);
    this.buildStructures(interfaces);
  }

  static ensure(input: TranspositionLibrary): TranspositionLibrary {
    const name = input?.name;
    const interfaces = input?.interfaces;

    return input instanceof TranspositionLibrary ? input : new TranspositionLibrary(name, interfaces);
  }

  protected ensureChildType(input: ApplicationInterface): ApplicationInterface {
    return ApplicationInterface.ensure(input);
  }

  protected mergeChild(original: ApplicationInterface, supersede: ApplicationInterface): void {
    if (original && supersede){
      original.mergeChildren(supersede.categories);
    }
  }

  public toPersistableClone(): TranspositionLibrary {
    const interfaces: ApplicationInterface[] = [];

    for (const inter of this.children){
      interfaces.push(inter.toPersistableClone());
    }

    const output = new TranspositionLibrary(this.name, interfaces);
    delete output.children_dictionary;
    delete output.children;
    return output;
  }
}
