import {Component, OnInit} from '@angular/core';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {from, Observable, of} from 'rxjs';
import {ODataSearchResponse} from '../ODataSearchResponse';
import {FeaturesService} from '../features.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {HateoasRoutesService} from '../hateoas-routes.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Component({
  selector: 'app-interface-configuration',
  template: `
    <div class="container-fluid">

      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <h4 class="page-title">Interfaces</h4>
          </div>
        </div>
      </div>
      <!-- end page title -->

      <!-- end row-->
      <div class="row">
        <div *ngFor="let item of this.interface_page | async" class="col-lg-6 col-xxl-3" (click)="onClickInterfaceDetail(item.id)">
          <!-- project card -->
          <div class="card d-block">
            <div class="card-body">
              <h4 class="mt-0">
                <a class="text-title">{{item.name}}</a>
              </h4>
              <p class="text-muted font-13 mb-3">
                {{item.description}}
              </p>
              <ul class="list-group list-group-flush">
                <li *ngFor="let func of item.functions" class="list-group-item">{{func}}</li>
              </ul>
            </div> <!-- end card-body-->
          </div> <!-- end card-->
        </div> <!-- end col -->
      </div>

      <!-- Shimmer Effect -->
      <ng-container *ngIf="loading_interfaces">
        <div class="row">
          <div *ngFor="let x of [].constructor(1)" class="shine card d-block card-body overflow-hidden">&nbsp;</div>
          <a *ngFor="let x of [].constructor(3)" class="shine list-group-item list-group-item-action w-100 text-nowrap overflow-hidden"><i
            class="uil-books font-18 align-middle me-2 visually-hidden"></i> &nbsp;</a>
        </div>
      </ng-container>
    </div>
  `,
  styles: [`
    .card {
      min-height: 305px;
      border-radius: 1px;
      background: #fff;
      box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
      transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
      cursor: pointer;
    }

    .shine {
      background-image: linear-gradient(to right, #f1f3fa00 0%, #dfe3f0 50%, #f1f3fa00 100%);
      background-repeat: no-repeat;
      background-size: 40% 100%;
      display: inline-block;
      position: relative;

      animation-play-state: running;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: shimmer;
      animation-timing-function: linear;
    }
    .card:hover{
      transform: scale(1.05);
      box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    }

    @keyframes shimmer {
      0% {
        background-position: -100% 0;
      }
      100% {
        background-position: 200% 0;
      }
    }
  `]
})
export class InterfacesConfigurationComponent implements OnInit {
  public interface_count = 0;
  public interface_page: Observable<ApplicationInterface[]>;
  public loading_interfaces = false;

  constructor(private http: HttpClient,
              private features: FeaturesService,
              private router: Router,
              private hateoas: HateoasRoutesService,
              private googleAnalytics: GoogleAnalyticsService) {
  }


  ngOnInit(): void {
    this.LoadApplicationInterfaces();
  }

  private LoadApplicationInterfaces(): void {
    this.loading_interfaces = true;
    this.interface_page = this.features.getFeatures().pipe(mergeMap(response => {
      if (response.h_search_application_interfaces) {
        return this.http.get<ODataSearchResponse<ApplicationInterface>>(response.h_search_application_interfaces,
          {
            headers: {'X-DM-Authorize': 'management_api'},
            params: new HttpParams().set('$count', true)
          });
      } else {
        return from(this.router.navigate(['/config/error/403'])).pipe(map(() => null));
      }
    }), switchMap(response => {
      response.items.forEach(item => {
        this.hateoas.store('h_read_interface', item.id, item.h_read_interface);
      });
      this.loading_interfaces = false;
      this.interface_count = response.count;
      return of(response.items);
    }));
  }

  public onClickInterfaceDetail(id: number): void {
    this.googleAnalytics.event('clicked_interface_detail', 'dmbridge_application_interface', 'Clicked Application Interface Details');
    this.router.navigate(['/config/interface-details', id]);
  }
}

class ApplicationInterface {
  public id: number;
  public name: string;
  public description: string;
  public functions: Array<string>;
  public h_read_interface: string;
}
