import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {WorkflowService} from '../../../workflows/workflow-service.service';
import {first, map, retry} from 'rxjs/operators';
import Chart from 'chart.js/auto';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-correlation-chart',
  template: `
    <div class="card">
      <div class="card-body">
        <h4 class="header-title mb-4">Activity</h4>
        <div dir="ltr">
          <div class="mt-3 chartjs-chart" style="height: 320px;">
            <canvas id="correlation-activity">{{ activity_chart }}</canvas>
          </div>
        </div>
      </div> <!-- end card body-->
    </div> <!-- end card -->
  `,
  styles: [``],
  providers: [DatePipe]
})
export class CorrelationActivityComponent implements OnChanges {

  @Input() date_range: any;
  @Output() display_error = new EventEmitter<any>();
  public activity_chart: Chart;

  constructor(private workflowService: WorkflowService, private date_pipe: DatePipe) { }

  ngOnChanges(): void {
    this.get_data(this.date_range);
  }

  private get_data(range: any): void {
    this.activity_chart?.destroy();
    this.date_range = range;
    this.workflowService.execute('ams_analytics', {date_range: range, report_type: 'correlation_activity'})
      .pipe(first(), retry(3), map((r) => {
        if (r?.success && r?.output?.items) {
          this.display_error.emit(false);
          this.process_report_entries(this.use_local_time(r.output.report_starts_on), r.output.items);
        }
      })).subscribe();
  }

  private use_local_time(date: string): Date{
    if (date.split('-').length === 2) { // correcting for month aggregates
      date = date.substring(0, 3) + '01-' + date.substring(3);
    }

    const as_date = new Date(date);
    const timezoneOffset = as_date.getTimezoneOffset() / 60;

    const output = new Date(as_date);
    output.setHours(as_date.getHours() + timezoneOffset);
    return output;
  }

  private process_report_entries(start_date: Date, items: any): void {
    const output: { labels: string[], values: number[] } = { labels: [], values: []};
    const today = new Date(Date.now());

    const options = {
      DAY: {
        count: 24,
        format: 'h a',
        api_format: 'MM-dd-yyyy H',
        increment: (idx) =>  {
          const newDate = new Date(start_date);
          newDate.setHours(start_date.getHours() + idx);
          return newDate;
      } },
      WEEK: {
        count: 7,
        format: 'MMMM d',
        api_format: 'MM-dd-yyyy',
        increment: (idx) => {
          const newDate = new Date(start_date);
          newDate.setDate(start_date.getDate() + idx);
          return newDate;
        }
      },
      MTD: {
        count: today.getDate(),
        format: 'MMMM d',
        api_format: 'MM-dd-yyyy',
        increment: (idx) => {
          const newDate = new Date(start_date);
          newDate.setDate(idx);
          return newDate;
        }
      },
      YTD: {
        count: today.getMonth()  + 1,
        format: 'MMMM',
        api_format: 'MM-yyyy',
        increment: (idx) => {
          const newDate = new Date(start_date);
          newDate.setMonth(idx);
          return newDate;
        }
      },
      ALL: {
        count: (((today.getMonth() + 1) - start_date.getMonth()) + (today.getFullYear() - start_date.getFullYear()) * 12),
        format: 'MMMM yy',
        api_format: 'MM-yyyy',
        increment: (idx) => {
          const newDate = new Date(start_date);
          newDate.setMonth(start_date.getMonth() + idx);
          return newDate;
        }
      }
    };

    let y = 0;
    const selected_date_range_options = options[this.date_range];
    for (let x = 0; x < selected_date_range_options.count; x++) {
      const raw_label_value = selected_date_range_options.increment(x);
      const element_label = this.date_pipe.transform(raw_label_value, selected_date_range_options.format, 'UTC');
      const api_label = this.date_pipe.transform(raw_label_value, selected_date_range_options.api_format, 'UTC');

      let value = 0;
      if ( items.length > y && items[y].label === api_label) {
        value = items[y].value;
        y++;
      }

      output.labels.push(element_label);
      output.values.push(value);
    }

    this.populate_chart(output);
  }

  private populate_chart(arg: {labels: string[], values: number[] }): void {

    this.activity_chart = new Chart('correlation-activity', {
      type: 'line',
      data: {
        labels: arg.labels,
        datasets: [
          {
            label: 'Correlated Submissions',
            data: arg.values,
            borderColor: '#F48623'
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              precision: 0
            },
            min: 0
          }
        }
      }
    });
  }
}
