import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {WorkflowService} from '../../../workflows/workflow-service.service';
import {first, map, retry} from 'rxjs/operators';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-account-executive',
  template: `
    <div class="card">
      <div class="card-body">
        <h4 class="header-title mb-4">Submissions Aggregated by Account Executive</h4>
        <div dir="ltr">
          <div class="mt-3 chartjs-chart" style="height: 320px;">
            <canvas id="account_execs">{{ executive_chart }}</canvas>
          </div>
        </div>
      </div> <!-- end card body-->
    </div> <!-- end card -->
  `,
  styles: [``]
})
export class AccountExecutiveComponent implements OnChanges {

  @Input() date_range: any;
  @Output() display_error = new EventEmitter<any>();
  public executive_chart: Chart;

  constructor(private workflowService: WorkflowService) { }

  ngOnChanges(): void {
    this.get_data(this.date_range);
  }

  private get_data(range: any): void {
    this.executive_chart?.destroy();
    this.date_range = range;
    this.workflowService.execute('ams_analytics', {date_range: range, report_type: 'acct_exec'})
      .pipe(first(), retry(3), map((r) => {
        if (r?.success && r?.output?.items) {
          this.display_error.emit(false);
          this.populate_chart(r.output.items);
        }
      })).subscribe();
  }

  private populate_chart(items: any): void {
    const labels = [];
    const data = [];

    for (const exec of items) {
      labels.push(exec.label);
      data.push(exec.value);
    }

    this.executive_chart = new Chart('account_execs', {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Account Executives',
            data
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              precision: 0
            }
          }
        }
      }
    });
  }
}
