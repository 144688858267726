import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {WorkflowService} from '../../../workflows/workflow-service.service';
import {count, first, map, retry} from 'rxjs/operators';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-bound-interface',
  template: `
    <div class="card">
      <div class="card-body">
        <h4 class="header-title mb-4">Bound Submissions by Interface</h4>
        <div dir="ltr">
          <div class="mt-3 chartjs-chart" style="height: 320px;">
            <canvas id="interfaces">{{ interface_chart }}</canvas>
          </div>
        </div>
      </div> <!-- end card body-->
    </div> <!-- end card -->
  `,
  styles: [``]
})
export class BoundInterfaceComponent implements OnChanges {
  @Input() date_range: any;
  @Input() available_interfaces: any;
  @Output() display_error = new EventEmitter<any>();
  public interface_chart: Chart;

  constructor(private workflowService: WorkflowService) { }

  ngOnChanges(): void {
    this.get_data(this.date_range);
  }

  private get_data(range: any): void {
    this.interface_chart?.destroy();
    this.date_range = range;
    this.workflowService.execute('ams_analytics', {date_range: range, report_type: 'bound_by_interface'})
      .pipe(first(), retry(3), map((r) => {
        if (r?.success && r?.output?.items) {
          this.display_error.emit(false);
          this.populate_chart(r.output.items);
        }
      })).subscribe();
  }

  private populate_chart(items: any): void {
    const labels = [];
    const data = [];

    for (const item of items) {
      labels.push(this.available_interfaces[item.label] ?? item.label);
      data.push(item.value);
    }
    this.interface_chart = new Chart('interfaces', {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Bound Interfaces',
            data,
            backgroundColor: 'rgba(244, 134, 35, 0.5)'
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              precision: 0
            }
          }
        }
      }
    });
  }
}
