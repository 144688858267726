import {BrowserCacheLocation, Configuration} from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: '262bf166-6b71-4975-8c7e-8f549aff5515',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: 'https://dev.dmconnect.darkmatterins.com/support-control-panel',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  }
};
