import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OnPackageOptionChange} from './on-package-option-change';
import {Subject} from 'rxjs';
import {DmConnectPackageOptions} from '../dm-connect-package-options';

@Component({
  selector: 'app-reset-config',
  template: `
    <a class="col-4 text-end" data-bs-toggle="modal"
       data-bs-target="#ResetConfigComponent">
      <i class="dripicons-clockwise"></i>
      Reset and Start Over</a>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <div id="ResetConfigComponent" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                 style="display: none;"
                 aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header py-3 px-4 border-bottom-0">
                    <h4 class="modal-title">Reset all Options and Start Over</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>

                  <form class="ps-3 pe-3">
                    <div class="modal-body">
                      <div class="row g-2">
                        <label class="form-label" for="overwrite-confirm">Type <b>CONFIRM</b> to complete this action</label>
                        <input type="text" name="overwrite-confirm" id="overwrite-confirm" class="form-control" placeholder="CONFIRM" required
                               pattern="[Cc][Oo][Nn][Ff][Ii][Rr][Mm]" [(ngModel)]="this.confirmation" #confirm="ngModel">
                      </div>
                    </div>

                    <div class="modal-footer">
                      <button type="submit" class="btn btn-danger" data-bs-dismiss="modal" (click)="this.onReset()"
                              [disabled]="confirm.invalid">Reset
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> <!-- end col -->
      </div> <!-- end row -->
    </div><!-- end container -->

  `,
  styles: [`
    a {
      cursor: pointer;
      margin-left: .5em;
      font-size:smaller;
      font-weight:normal
    }
    .form-label {
      line-height: 0px;
    }
  `],
})

export class ResetConfigComponent implements OnPackageOptionChange {
  @Input() packageOptions: Subject<DmConnectPackageOptions>;
  @Output() onChange = new EventEmitter<any>();
  public confirmation: string;

  onReset(): void {
    this.onChange.emit(new DmConnectPackageOptions());
  }
}
