import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aim-master',
  templateUrl: './aim-master.component.html',
  styles: [
    `.aim-dialog {
        /*text-align: center;*/
        font-size: large;
        /*width: 500px;*/
      margin-top: 1em;
      margin-right: auto;
      margin-left: auto;
    }

    p {
      text-align: center;
    }

    .btn {
      min-width: 5em;
    }

    .dmc-button-panel {
      margin-top: 2.5em;
      text-align: center;
    }

    .powered-by {
      margin-top: 2em;
      max-width: 450px;
    }
    `
  ]
})
export class AimMasterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
