import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {WorkflowService} from '../workflows/workflow-service.service';
import {HttpParams} from '@angular/common/http';
import {first, map, retry} from 'rxjs/operators';
import {PluginLocalStorageService} from '../plugin-local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CanEditTranspositionGuard implements CanActivate, CanActivateChild {

  constructor(private workflowService: WorkflowService, private router: Router, private localStorage: PluginLocalStorageService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const options = { headers: {'Cache-Control': 'no-cache'}, params: new HttpParams().set('lookup_type', 'transposition_categories') };
    return this.workflowService.execute('lookup', options, 'ams/', 'GET')
      .pipe(first(), retry(3), map(response => {
        const can_edit = response.output.can_edit;
        this.localStorage.saveTranspositionData({can_edit, categories: response.output.items});

        if (route.url.toString().includes('transpositions') && !can_edit) {
          this.router.navigate(['local/about']);
          return false;
        }
        return true;
      }));
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route);
  }
}
