import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PluginLocalStorageService} from '../plugin-local-storage.service';
import {DmconnectBrowserService} from '../dmconnect-browser.service';

@Component({
  selector: 'app-roadblock',
  template: `
    <div class="alert alert-danger" role="alert">
      <p>{{errorMessage}}</p>
      <hr *ngIf="workflowId">
      <p class="mb-0" *ngIf="workflowId">Workflow Id: {{workflowId}}</p>
    </div>
  `,
  styles: [``]
})
export class RoadblockComponent implements OnInit {
  public workflowId: string;
  public errorMessage: string;

  constructor(private route: ActivatedRoute, private pluginLocalStorage: PluginLocalStorageService,
              private browserService: DmconnectBrowserService) {
  }

  ngOnInit(): void {
    const error = this.pluginLocalStorage.readError();
    const routeData = this.route.snapshot;
    this.workflowId = routeData.queryParamMap.get('workflow_id');
    this.errorMessage = routeData.queryParamMap.get('error_message');

    this.workflowId = this.workflowId ? this.workflowId : error.workflow_id;
    this.errorMessage = this.errorMessage ? this.errorMessage : error.error_message;
    const me = this;
    setTimeout(() => { me.browserService.closeWindow(); }, 10000);
  }
}
