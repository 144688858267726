import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ODataSearchResponse} from './ODataSearchResponse';

@Injectable({
  providedIn: 'root'
})
export class LocalCredentialsApiService {
  // @ts-ignore
  private readonly SERVICE_URL = Dmc.API_ADDRESS;

  constructor(private http: HttpClient) { }

  public listCredentials(): Observable<any[]> {
    return this.http.get<ODataSearchResponse<any>>(this.SERVICE_URL + 'credential', {headers: {'Cache-Control': 'no-cache'} })
      .pipe(map(d => {
        if (d && d.items){
          return d.items;
        }
      }));
  }

  public updateCredentials(uniqueIdentifier: string, payload: any): Observable<boolean> {
    return this.http.put(this.SERVICE_URL + 'credential/' + uniqueIdentifier, payload, { observe: 'response'})
      .pipe(map(p => p.status === 200 || p.status === 304), catchError(async () => false));
  }
}
