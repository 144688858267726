import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {FeaturesService} from '../../features.service';
import {ODataSearchResponse} from '../../ODataSearchResponse';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map, retry, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-config-master',
  templateUrl: './config-master.component.html',
  styleUrls: ['./config-master.component.css']
})
export class ConfigMasterComponent implements OnInit {
  public readonly ThisYear: number;
  public staging_buckets: Observable<StagingBucket[]>;

  constructor(public auth: AuthService, private googleAnalytics: GoogleAnalyticsService, private features: FeaturesService, private http: HttpClient) {
    this.ThisYear = new Date().getFullYear();
  }

  ngOnInit(): void {
    this.staging_buckets = this.features.getFeatures().pipe(
      switchMap(features => {
        if (!features.h_search_staging_buckets) {
          return of(new ODataSearchResponse<StagingBucket>());
        }
        return this.http.get<ODataSearchResponse<StagingBucket>> (features.h_search_staging_buckets, {headers: {'X-DM-Authorize': 'management_api'}}).pipe(retry(3));
      }), map(response => response.items));
  }

  logout(): void {
    this.googleAnalytics.event('sign_out_clicked', 'dmBridge', 'User logged out.');
    this.auth.logout();
  }
}

class StagingBucket
{
  public id: string;
  public name: string;
  public columns: string[];
  public is_active: boolean;
}
