import {Component, OnInit} from '@angular/core';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {v4 as uuid} from 'uuid';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, concatMap, first, mergeMap} from 'rxjs/operators';
import {ODataSearchResponse} from '../ODataSearchResponse';

@Component({
  selector: 'app-support-control-panel',
  template: `
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <h4 class="page-title">DmConnect Licensing</h4>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-12">
                  <a href="javascript:void(0);" class="btn btn-success mb-2 float-end"
                     (click)="onOpenModalGenerateKey('')"
                     data-bs-toggle="modal" data-bs-target="#GenerateKey-modal"><i class="mdi mdi-plus-circle me-2"></i>
                    Generate New License</a>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-centered w-100 dt-responsive nowrap">
                  <thead class="table-light">
                  <tr>
                    <th>Customer</th>
                    <th>Client Token</th>
                    <th>Created On</th>
                    <th>Expires On</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody app-loading-shimmer [loading]="this.isListLoading" [columns]="5">
                    <tr *ngFor="let item of this.licenses | async">
                      <td>{{item.customer_name}}</td>
                      <td>{{item.client_token}}</td>
                      <td>{{item.starts_at | date}}</td>
                      <td>{{item.expires_at | date}}</td>
                      <td>
                        <span *ngIf="item.status == 'active'" class="badge bg-success">{{item.status}}</span>
                        <span *ngIf="item.status == 'expiring'" class="badge bg-warning">{{item.status}}</span>
                        <span *ngIf="item.status == 'expired'" class="badge bg-danger">{{item.status}}</span>
                      </td>
                      <td class="table-action" style="width: 90px;">
                        <a href="javascript: void(0);" class="action-icon" (click)="onOpenModalGenerateKey(item.client_token)"
                           data-bs-toggle="modal" data-bs-target="#GenerateKey-modal"> <i
                          class="mdi mdi-refresh-circle"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="this.isNetworkException" class="alert alert-danger" role="alert">
                <h4 class="alert-heading">An error occurred while fetching licenses.</h4>
                <hr class="my-2">
                <p class="mb-0">If the error persists please contact us at <a class="text-danger"
                                                                              href="mailto:{{supportEmail}}">{{supportEmail}}</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="GenerateKey-modal" class="modal fade" tabindex="-1" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header py-3 px-4 border-bottom-0">
              <h4 class="modal-title" id="standard-modalLabel">Generate New License</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      (click)="closeGenerateKeyModalEvent()"></button>
            </div>
            <form class="ps-3 pe-3">
              <div class="modal-body">
                <div class="row g-2">
                  <div *ngIf="!showGenerationResult">
                    <div class="col-12">
                      <div class="mb-3">
                        <label for="renew_client_id" class="form-label">Client Token</label>
                        <input type="text" id="renew_client_id" readonly [(ngModel)]="newLicenseRequest.client_token"
                               name="renew_client_id"
                               class="form-control">
                      </div>
                      <div class="mb-3">
                        <label for="renew_starts_at" class="form-label">Starts On</label>
                        <input class="form-control" id="renew_starts_at" type="date"
                               [valueAsDate]="newLicenseRequest.starts_at" (change)="onStartDateChange($any($event.target).value)"
                               name="starts_at">
                      </div>
                      <div class="mb-3">
                        <label for="renew_expires_at" class="form-label">Ends On</label>
                        <input class="form-control" id="renew_expires_at" type="date"
                               [valueAsDate]="newLicenseRequest.expires_at"
                               name="expires_at" (change)="onExpiryDateChange($any($event.target).value)">
                      </div>
                    </div>
                  </div>
                  <div class="col-12" *ngIf="showGenerationResult">
                    <div *ngIf="!isLoadingLicense" class="mb-3">
                      <textarea rows="10" class="form-control" id="genKey" readonly>{{generatedKey}}</textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer d-block" *ngIf="!showGenerationResult">
                <div class="row g-2 text-end">
                  <div class="mb-3 col-12">
                    <button type="submit" class="btn btn-primary" (click)="onClickGenerateKey()">
                        <span [hidden]="!isLoadingLicense">
                          <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                          Loading...
                        </span>
                      <span [hidden]="isLoadingLicense">
                        Generate License
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
      </div>
    </div>
  `,
  styles: [``]
})

export class LicensingComponent implements OnInit {
  // @ts-ignore
  public readonly licensingUrl = Dmc.SUPPORT_ADDRESS + 'licensing';
  public readonly supportEmail = 'Support@DarkMatterIns.com';
  public generatedKey: string = null;
  public showGenerationResult = false;
  public licenses: Observable<DmConnectLicense[]>;
  public isListLoading = true;
  public isNetworkException = false;
  public newLicenseRequest = new NewLicenseRequest();
  public isLoadingLicense = false;

  constructor(private googleAnalytics: GoogleAnalyticsService, private http: HttpClient) {
  }

  ngOnInit(): void {
    this.getAllLicenses();
  }

  getAllLicenses(): void {
    this.isListLoading = true;
    this.licenses = this.http.get<ODataSearchResponse<DmConnectLicense>>(this.licensingUrl).pipe(mergeMap(response => {
      if (response) {
        this.isListLoading = false;
        return of(response.items);
      }
    }), catchError(() => {
        this.isListLoading = false;
        this.isNetworkException = true;
        return of([]);
      }
    ));
  }

  onOpenModalGenerateKey(id: string): void {
    if (!id){
      this.googleAnalytics.event('generate_new_key_button_clicked', 'support-control', 'Opened Generate Key Modal');
      id = uuid().replace(/-/g, '');
    }
    else {
      this.googleAnalytics.event('renew_license_button_clicked', 'support-control', 'Opened Generate Key Modal');
    }

    const today = new Date();
    const after5Year = new Date(today.getFullYear() + 5, today.getMonth(), today.getDate() + 1);
    this.newLicenseRequest = {
      client_token: id,
      starts_at: today,
      expires_at: after5Year
    } as NewLicenseRequest;

    this.showGenerationResult = false;
    this.generatedKey = null;
    const element = document.getElementById('GenerateKey-modal');
    // @ts-ignore
    const modal = bootstrap.Modal.getInstance(element);
    modal._config.backdrop = 'static';
    modal._config.keyboard = false;
  }

  onClickGenerateKey(): void {
    this.googleAnalytics.event('generate_key_button_clicked', 'support-control', 'Clicked Generate Key Button');
    this.isLoadingLicense = true;
    this.http.post<any>(this.licensingUrl + '/generate', this.newLicenseRequest, {observe: 'response', responseType: 'json'})
      .pipe(first(), concatMap(response => {
        this.generatedKey = response?.body?.license;
        this.showGenerationResult = true;
        return of(true);
      }), catchError(async () => {
        return false;
      })).subscribe((generateSuccessful) => {

      if (!generateSuccessful) {
        this.isNetworkException = true;
        this.closeGenerateKeyModalEvent();
      }

      this.isLoadingLicense = false;
    });
  }

  closeGenerateKeyModalEvent(): void {
    const element = document.getElementById('GenerateKey-modal');
    // @ts-ignore
    const modal = bootstrap.Modal.getInstance(element);
    if (modal) {
      modal.hide();
    }
    this.googleAnalytics.event('generate_key_modal_closed', 'support-control', 'Closed Generate Key Modal');
  }

  public onStartDateChange(date: any): void {
    this.newLicenseRequest.starts_at = new Date(date);
  }

  public onExpiryDateChange(date: any): void {
    this.newLicenseRequest.expires_at = new Date(date);
  }
}

class DmConnectLicense {
  public client_token: string;
  public starts_at: Date;
  public expires_at: Date;
  public status: string;
  public customer_name: string;
}

class NewLicenseRequest {
  public client_token: string;
  public starts_at: Date;
  public expires_at: Date;
}
