import {HttpClient} from '@angular/common/http';
import {SupportLocalStorageService} from '../../support-local-storage.service';
import {Component, OnInit } from '@angular/core';
import {Observable, of} from 'rxjs';
import {ODataSearchResponse} from '../../../ODataSearchResponse';
import {catchError, concatMap, first, map, share, switchMap} from 'rxjs/operators';
import {DmConnectPackageOptions} from '../../dm-connect-package-options';
import {ActivatedRoute} from '@angular/router';
import {TransposableValue} from './transposable-value';
import {ApplicationInterface} from './ApplicationInterface';
import {TranspositionCategory} from './TranspositionCategory';
import {EditableTransposableValue} from './editable-transposable-value';
import {TranspositionLibrary} from './TranspositionLibrary';

@Component({
  selector: 'app-transposition-editor',
  template: `
    <div class="container-fluid">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Editing '{{this.current_library?.name}}' Transposition Library
            <a class="col-4 text-end" *ngIf="available_libraries && available_libraries.length > 0" data-bs-toggle="modal"
               data-bs-target="#overwrite-library-modal">
              <i class="uil uil-file-redo-alt"></i>
              Overwrite from a different library</a>
          </h4>
        </div>

        <div class="card-body pb-2">

          <!-- navbar -->
          <div class="row justify-content-center">
            <div class="col-7" [ngSwitch]="(included_interfaces | async)?.length">
              <div *ngSwitchCase="0" class="card">
                <div class="card-body">
                  <div class="alert alert-danger mb-0" role="alert">
                    <h4 class="alert-heading">No interfaces were selected.</h4>
                    <hr class="my-2">
                    <p class="mb-0">Please <a [routerLink]="['../generate-configuration']">return</a> to the Customer Configuration page and
                      select an interface before continuing.</p>
                  </div>
                </div>
              </div>
              <div *ngSwitchDefault class="horizontal-steps pb-5" [class.finish]="this.progress.is_finished">
                <div class="horizontal-steps-content">
                  <div *ngFor="let app_interface of (this.included_interfaces | async); let i = index" class="step-item"
                       [class.current]="this.progress.selected === i">
                    <span data-bs-placement="bottom" (click)="this.onInterfaceSelect(app_interface.id, i)">{{app_interface.name}}</span>
                  </div>
                  <div class="step-item" [class.current]="this.progress.is_finished">
                    <span data-bs-placement="bottom" (click)="this.finish()">Finish</span>
                  </div>
                </div>
                <div class="process-line" [ngStyle]="{width: this.progress.selected / this.progress.total * 100 + '%'}"></div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="this.progress.is_finished && (included_interfaces | async)?.length">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="alert alert-info mb-0" role="alert">
                    <h4 class="alert-heading">You've visited all transposition interfaces.</h4>
                    <hr class="my-2">
                    <p class="mb-0"><a [routerLink]="['../generate-configuration']">Return</a> to the main configuration page to generate the installation files.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- categories -->
          <div class="row" *ngIf="selected_interface && !this.progress.is_finished">
            <div class="col-12 col-lg-6" *ngFor="let category of this.selected_interface.categories">
              <div class="card d-flex">
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-9">
                      <h5 class="card-title mb-3">{{category.id}}</h5>
                      <h6 class="card-subtitle text-muted">*Category Description*</h6>
                    </div>
                    <div class="col-3">
                      <button type="button" class="btn btn-success mb-2 mt-2 float-end text-nowrap" data-bs-toggle="modal"
                              data-bs-target="#transposition-modal"
                              (click)="openEditor(category, null)">
                        <i class="mdi mdi-plus mr-1"></i>Add
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body pt-0 table-nowrap" data-simplebar>
                  <table class="table table-striped mb-0 table-layout">
                    <thead>
                    <tr>
                      <th colspan="4">Left Value</th>
                      <th colspan="4">Right Value</th>
                      <th colspan="3"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of category.transpositions">
                      <td class="text-truncate" colspan="4">{{item.left_value}}</td>
                      <td class="text-truncate" colspan="4">{{item.right_value}}</td>
                      <td class="text-end" colspan="3">
                        <a (click)="openEditor(category, item)" class="action-icon" data-bs-toggle="modal"
                           data-bs-target="#transposition-modal">
                          <i class="mdi mdi-square-edit-outline"></i></a>
                        <a (click)="deleteTransposition(category, item)" class="action-icon">
                          <i class="mdi mdi-delete"></i></a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div> <!-- end card-body-->
              </div>
            </div>
          </div> <!-- end row -->

        </div>
      </div> <!-- end col -->
    </div><!-- end container -->

    <div id="transposition-modal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" style="display: none;"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header py-3 px-4 border-bottom-0">
            <h4 class="modal-title" id="standard-modalLabel">Edit Transposition Values</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form class="ps-3 pe-3">
            <div class="modal-body">
              <div class="row g-2">
                <label class="form-label" for="left_value">Left Value</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" id="left_value" name="left_value"
                         [disabled]="!editable_transposition.is_left_editable"
                         [(ngModel)]="editable_transposition.left_value">
                </div>
              </div>
              <div class="row g-2">
                <label class="form-label" for="right_value">Right Value</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" id="right_value" name="right_value"
                         [disabled]="!editable_transposition.is_right_editable"
                         [(ngModel)]="editable_transposition.right_value">
                </div>
              </div>
            </div>
            <div class="modal-footer d-block">
              <div class="row g-2 text-end">
                <div class="mb-3 col-12">
                  <button type="submit" class="btn btn-primary" (click)="onTransposableValueUpdate()"
                          data-bs-dismiss="modal">{{ this.editable_transposition.is_new ? 'Add' : 'Update' }}</button>
                </div>
              </div>
            </div>
          </form>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div>

    <div id="overwrite-library-modal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
         style="display: none;"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header py-3 px-4 border-bottom-0">
            <h4 class="modal-title">Overwrite {{this.current_library.name }}</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <form class="ps-3 pe-3">
            <div class="modal-body">
              <div class="form-floating row g-2">
                <select name="library-target" class="form-select mb-3" required [(ngModel)]="this.desired_source_library" #target="ngModel">
                  <ng-container *ngFor="let library of this.available_libraries">
                    <option value="{{library}}">{{library}}</option>
                  </ng-container>
                </select>
                <label class="form-label">Select Library</label>
              </div>

              <div class="row g-2">
                <label class="form-label">Type <b>CONFIRM</b> to complete this action</label>
                <input type="text" name="overwrite-confirm" class="form-control" placeholder="confirm" required
                       pattern="[Cc][Oo][Nn][Ff][Ii][Rr][Mm]" [(ngModel)]="this.confirmation" #confirm="ngModel">
              </div>
            </div>

            <div class="modal-footer">
              <button type="submit" class="btn btn-danger" data-bs-dismiss="modal" (click)="this.onOverwriteLibrary()"
                      [disabled]="confirm.invalid || target.invalid">Overwrite
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .step-item span {
      cursor: pointer;
    }

    .step-item:not(.current) span:hover {
      color: #6c757d;
    }

    .table-layout {
      table-layout: fixed;
    }

    .horizontal-steps.finish .step-item, .horizontal-steps.finish .step-item:last-child span {
      color: var(--bs-success);
    }

    .horizontal-steps.finish .process-line {
      background-color: var(--bs-success);
    }

    a {
      cursor: pointer;
    }

    .page-title a {
      margin-left: .5em;
      font-size:smaller;
      font-weight:normal
    }
  `],
})

export class TranspositionEditorComponent implements OnInit {
  public included_interfaces = new Observable<ApplicationInterface[]>();
  public progress = new ProgressBar(0, 0);
  public selected_interface: ApplicationInterface;
  public package_options: DmConnectPackageOptions;
  public editable_transposition: EditableTransposableValue = new EditableTransposableValue(null, null, false, null);
  public focused_category: TranspositionCategory;
  public current_library: TranspositionLibrary;
  public available_libraries: string[] = [];

  public desired_source_library: string;
  public confirmation: string;

  constructor(private http: HttpClient, private local_storage: SupportLocalStorageService, private route: ActivatedRoute) {
    this.package_options = this.local_storage.read();
    this.initializeLibrary();
  }

  ngOnInit(): void {
    this.included_interfaces = this.getApplicationInterfaces();
    this.included_interfaces.subscribe(r => console.log(r));
  }

  private initializeLibrary(): void {
    const library_name = this.route.snapshot.queryParamMap.get('library_name');
    const libraries = this.package_options.transposition_libraries ?? {};
    this.available_libraries = Object.getOwnPropertyNames(libraries);

    if (libraries[library_name]) {
      this.available_libraries.splice(this.available_libraries.indexOf(library_name), 1);
      this.current_library = TranspositionLibrary.ensure(libraries[library_name]);
    } else {
      this.current_library = new TranspositionLibrary(library_name, []);
    }
  }

  public getApplicationInterfaces(): Observable<ApplicationInterface[]> {
    // @ts-ignore
    return this.http.get<ODataSearchResponse<ApplicationInterface>>( Dmc.SUPPORT_ADDRESS + 'interface-scripts/application-interface').pipe(
      // @ts-ignore
      concatMap(interface_response => this.http.get<ODataSearchResponse<ApplicationInterface>>(Dmc.SUPPORT_ADDRESS + 'interface-scripts/ams').pipe(
        switchMap(ams_response => {
          const output = new Array<ApplicationInterface>();

          if (ams_response) {
            for (const ams of ams_response.items) {
              if (this.package_options.ams_id === ams.id) {
                output.push(ams);
                break;
              }
            }
          }
          if (interface_response) {
            for (const app of interface_response.items) {
              if (this.package_options.application_interface_ids.includes(app.id)) {
                output.push(app);
              }
            }
          }

          this.progress.total = output.length;
          this.current_library.mergeChildren(output);
          this.onInterfaceSelect(output[0].id, 0);
          return of(output);
        })
      )), catchError(() => {
        return of([]);
      }), share());
  }

  public onInterfaceSelect(app_interface_id: string, index: number): void {
    this.progress.selected = index;
    const app_interface = ApplicationInterface.ensure(this.current_library.find(app_interface_id));

    this.loadCategories(app_interface_id).pipe(switchMap(r => {
      if (r.items) {
        app_interface.mergeChildren(r.items, true);
      }

      this.current_library.mergeSingle(app_interface, true);
      this.selected_interface = this.current_library.find(app_interface.id);

      this.updateLocalStorage();
      return of(false);
    })).subscribe();
  }

  private loadCategories(interfaceId: string): Observable<ODataSearchResponse<TranspositionCategory>> {
    // @ts-ignore
    return this.http.get<ODataSearchResponse<TranspositionCategory>>(Dmc.SUPPORT_ADDRESS + `application-interface/${interfaceId}/transposition`)
      .pipe(first());
  }

  public openEditor(category: TranspositionCategory, transposition?: TransposableValue): void {
    this.focused_category = category;
    this.editable_transposition = new EditableTransposableValue(transposition?.left_value, transposition?.right_value, category.is_left_editable, transposition);
  }

  public onTransposableValueUpdate(): void {
    this.focused_category.mergeSingle(this.editable_transposition.toTransposableValue());
    this.updateLocalStorage();
  }

  public deleteTransposition(category: TranspositionCategory, item: TransposableValue): void {
    if (category && item) {
      category.transpositions.splice(category.transpositions.indexOf(item), 1);
      this.updateLocalStorage();
    }
  }

  public onOverwriteLibrary(): void {

    if ('confirm' === this.confirmation) {
      const supersede_library = this.package_options.transposition_libraries[this.desired_source_library];
      const target = new TranspositionLibrary(this.current_library.name, []);

      for (const x of supersede_library.interfaces) {
        const target_interface = ApplicationInterface.ensure(x);
        this.loadCategories(target_interface.name).pipe(first(), map(r => {
          if (r.items) {
            target_interface.mergeChildren(r.items);
          }
        })).subscribe();
      }

      target.mergeChildren(supersede_library.interfaces);
      this.package_options.transposition_libraries[this.current_library.name] = target;
      this.current_library = target;
      this.selected_interface = this.current_library.find(this.selected_interface.id);
      this.updateLocalStorage();
    }

    // Resetting modal
    this.confirmation = null;
    this.desired_source_library = null;
  }

  private updateLocalStorage(): void {
    this.package_options.transposition_libraries[this.current_library.name] = this.current_library.toPersistableClone();
    this.local_storage.save(this.package_options);
  }

  finish(): void {
    this.progress.selected = this.progress.total;
  }
}

class ProgressBar {
  constructor(public selected: number = 0, public total: number = 0) {
  }

  public get is_finished(): boolean {
    return this.selected === this.total;
  }
}
