import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {DmConnectPackageOptions} from '../dm-connect-package-options';

@Component({
  selector: 'app-concept-one-config',
  template: `
    <div *ngIf="is_concept_one_selected" class="card d-block">
      <div class="card-body">
        <h5 class="card-title mb-3">Concept One Configurations</h5>
        <div class="input-group mb-3">
          <label class="input-group-text" for="conceptProdUrl">Production</label>
          <input type="text" class="form-control" id="conceptProdUrl" [(ngModel)]="concept_production_url"
                (change)="conceptOneConfigChanged()" placeholder="https://c1apihost.local">
        </div>
        <div class="input-group mb-3">
          <label class="input-group-text" for="conceptTestUrl">Testing</label>
          <input type="text" class="form-control" id="conceptTestUrl" [(ngModel)]="concept_development_url"
                 (change)="conceptOneConfigChanged()" placeholder="https://c1apihost.local">
        </div>
        <div class="input-group">
          <label class="input-group-text" for="customColumnName">Custom Column Name</label>
          <input type="text" class="form-control" id="customColumnName" [(ngModel)]="custom_column_name"
                 (change)="conceptOneConfigChanged()" placeholder="Application Number">
        </div>
      </div> <!-- end card-body-->
    </div> <!-- end card-->
  `,
  styles: [`
    .input-group-text {
      min-width: 8em
    }
  `]
})

export class ConceptOneConfigComponent implements OnInit {
  public concept_production_url: string;
  public concept_development_url: string;
  public custom_column_name: string;
  public is_concept_one_selected: boolean;
  @Input() packageOptions: Subject<DmConnectPackageOptions>;
  @Output() onChange = new EventEmitter<any>();

  constructor() {
  }
  ngOnInit(): void {
    this.packageOptions.subscribe((next) => {
      this.is_concept_one_selected = next.ams_id === 'concept_one';
      this.concept_development_url = next.concept_development_url;
      this.concept_production_url = next.concept_production_url;
      this.custom_column_name = next.custom_column_name;
    });
  }

  public conceptOneConfigChanged(): void {
    this.onChange.emit({
      concept_development_url: this.concept_development_url,
      concept_production_url: this.concept_production_url,
      custom_column_name: this.custom_column_name
    });
  }
}
