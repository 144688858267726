import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HateoasRoutesService {
  private available_operations: Map<string, Map<any, string>> = new Map();
  private nestedMap: Map<any, string> = new Map();

  constructor() {
  }

  store(operation_name: string, identifier: any, route: string): void {
    this.available_operations.set(operation_name, this.nestedMap.set(identifier, route));
  }

  load(operation_name: string, identifier: any): string {
    if ((this.available_operations == null) ||
      !(this.available_operations.has(operation_name)) ||
      !(this.available_operations.get(operation_name).has(identifier))) {
      return null;
    }
    return this.available_operations.get(operation_name).get(identifier);
  }
}
