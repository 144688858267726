import {Component} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {DmConnectApiService, StatusApiResult} from '../dm-connect-api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkflowService} from '../workflows/workflow-service.service';
import {PluginLocalStorageService, StoredDataType} from '../plugin-local-storage.service';
import {HttpClient} from '@angular/common/http';
import {catchError, concatMap, first, map, retry} from 'rxjs/operators';
import {ODataSearchResponse} from '../ODataSearchResponse';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Component({
  selector: 'app-preflight-check',
  template: `
    <div class="container-fluid">
      <app-loading-indicator loading_text="{{loading_text}}" [is_loading]="status.IsLoading"></app-loading-indicator>
      <ng-template [ngIf]="!status.IsLoading && !this.current_user_luid">
        <div class="row justify-content-center">
          <div class="col-lg-6 text-center">
              <img alt="help icon" class="user-help-icon" src="assets/images/help-icon.svg" />
              <h4 class="text-uppercase text-danger">Confirm your account</h4>
              <p class="text-muted">Because this is the first time we're seeing you in DmConnect, or your browser cache was recently cleared, we need to confirm who you are.</p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-6 form-floating text-center">
            <select class="form-select" id="selectUserId" aria-label="Select a User" #s1="ngModel" required [class.is-invalid]="!selected_user_id && s1.touched" [(ngModel)]="selected_user_id">
              <option value="">{{this.status.IsLoading ? 'Loading Users...' : 'Select Your Account'}}</option>
              <option *ngFor="let user of user_list" value="{{user.user_hash}}">{{user.user_name}}</option>
            </select>
            <label for="selectUserId">Select Your Account</label>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-6 text-center">
            <button type="button" class="btn btn-primary btn-block mt-4" (click)="clickSelectUser()" [disabled]="!selected_user_id">Continue</button>
          </div>
        </div>
      </ng-template>
    </div>
  `,
  styles: [`
    .user-help-icon {
      max-width: 200px;
    }
  `]
})
export class PreflightCheckComponent {
  private hasPayloadAndLuid$: Subject<void> = new Subject<void>();
  private readonly portal_id: string;
  private payload: any = {id: ''};
  private payload_received: boolean;

  public status = new LoadingStatus();
  public current_user_luid: string;
  public user_list: any[];
  public selected_user_id = '';
  public loading_text: string = this.status.ValidatingAccount;

  // @ts-ignore
  private readonly SERVICE_URL = Dmc.API_ADDRESS;

  constructor(private route: ActivatedRoute, private workflowService: WorkflowService, private routerService: Router,
              private apiService: DmConnectApiService, private localStorage: PluginLocalStorageService, private http: HttpClient,
              private googleAnalytics: GoogleAnalyticsService) {
    this.portal_id = route.snapshot.queryParamMap.get('portal_id');
    const idFieldName = route.snapshot.queryParamMap.get('id_field');
    this.current_user_luid = this.localStorage.readDmLuid();
    this.hasPayloadAndLuid$.pipe(first(), concatMap(() => this.requestStatus(this.portal_id, this.find_record_id(this.payload, idFieldName))),
      map(r => this.processStatus(r))).subscribe();

    if (!this.current_user_luid) {
      this.requestUsers();
    }

    window.addEventListener('message', (ev) => {
      if (!ev.data || !ev.data.dmconnect) {
        return;
      }
      this.payload = ev.data;
      this.payload_received = true;
      this.evaluateAndContinue(false);
    });
  }

  private find_record_id(payload: any, id_field: string): string {
    let record_id = payload?.dm_record_id;

    if (payload && !record_id) {
      let working_reference = payload;
      for (const key of id_field.split('.')) {
        working_reference = working_reference[key];
        if (!working_reference) {
          break;
        }
      }
      record_id = working_reference;
    }

    return record_id ?? null;
  }

  clickSelectUser(): void {
    if (!this.selected_user_id) {
      return;
    }
    this.googleAnalytics.event('user_account_selected', 'plugin_modal', 'User Account Selected');
    this.selectUser(this.selected_user_id);
  }

  selectUser(userId: string): void {
    this.current_user_luid = userId;
    this.localStorage.saveDmLuid(userId);
    this.status.showLoading();
    this.evaluateAndContinue(false);
  }

  requestUsers(): void {
    const newUrl = this.SERVICE_URL.substring(0, this.SERVICE_URL.indexOf('dmconnect'));
    this.http.get<ODataSearchResponse<any>>(`${newUrl}host/user`).pipe(first(), retry(3), map(responseBody => {
      let output: any[] = [];
      if (responseBody) {
        if (responseBody.items.length === 1) {
          this.selectUser(responseBody.items[0].user_hash);
        }
        output = responseBody.items;
      }
      if (!this.current_user_luid) {
        this.status.hideLoading();
      }
      this.user_list = output;
      return output;
    }), catchError(() => {
      this.evaluateAndContinue(true);
      return null;
    })).subscribe();
  }

  requestStatus(portalId: string, recordId: string): Observable<StatusApiResult> {
    return this.apiService.getStatus2(portalId, recordId);
  }

  processStatus(statusResult: StatusApiResult): void {
    // TODO: Referrer is "" when using the browser extension
    // const parentUrl = document.referrer;
    // const parentScheme = parentUrl.substring(0, parentUrl.indexOf(':'));
    // if (parentScheme !== 'https') {
    //   this.routerService.navigate(['../roadblock'], {relativeTo: this.route, queryParams: {
    //         workflow_id: 'SECURE_CONNECTION_DOWNGRADED',
    //         error_message: 'The portal you are using is not using TLS (https://..), and for security purposes has been blocked.'
    //   }});
    // }

    if (!statusResult || !statusResult.success) {
      console.log('Status request failed. DmConnect configuration likely incorrect.');
      return;
    }
    if (statusResult.correlation_id || statusResult.skip_clearance) {
      this.loading_text = this.status.ImportingData;
      this.workflowService
        .execute('inbound', {dm_portal_id: this.portal_id, dm_target_id: statusResult.correlation_id, ...this.payload})
        .subscribe();
    } else {
      this.loading_text = this.status.AggregatingData;
      this.workflowService
        .execute('read_only', {dm_portal_id: this.portal_id, ...this.payload})
        .subscribe(r => {
          let route = '../clearance';

          if (r.output.show_id_roadblock){
            route = '../id-roadblock';
          }

          this.localStorage.save(this.portal_id, this.payload, StoredDataType.PORTAL_PAYLOAD);
          this.localStorage.save(this.portal_id, r.output, StoredDataType.PREFLIGHT_PAYLOAD);
          this.routerService.navigate([route], {relativeTo: this.route});
        });
    }
  }

  private evaluateAndContinue(bypassUserId: boolean): void {
    if (bypassUserId || this.current_user_luid && this.payload_received) {
      this.hasPayloadAndLuid$.next();
    }
  }
}

class LoadingStatus {
  private isLoading = true;

  public showLoading(): void {
    this.isLoading = true;
  }

  public hideLoading(): void {
    this.isLoading = false;
  }

  public reset(): void {
    this.isLoading = true;
  }

  public get ValidatingAccount(): string {
    return 'Validating your account...';
  }

  public get DeterminingAssignment(): string {
    return 'Determining if this submission has been previously assigned...';
  }

  public get AggregatingData(): string {
    return 'Aggregating available data...';
  }

  public get ImportingData(): string {
    return 'Importing data into the Agency Management System...';
  }

  public get IsLoading(): boolean {
    return this.isLoading;
  }
}
