import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-interface-configuration',
  template: `                    <div class="container-fluid">

    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Surplus Lines Tax</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-xl-8">
                <form class="row gy-2 gx-2 align-items-center justify-content-xl-start justify-content-between">
                  <div class="col-auto">
                    <label for="inputPassword2" class="visually-hidden">Search</label>
                    <input type="search" class="form-control" id="inputPassword2" placeholder="Search...">
                  </div>
                </form>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-centered mb-0">
                <thead class="table-light">
                <tr>
                  <th style="width: 20px;">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="customCheck1">
                      <label class="form-check-label" for="customCheck1">&nbsp;</label>
                    </div>
                  </th>
                  <th>Policy Id</th>
                  <th>State</th>
                  <th>Amount</th>
                  <th style="width: 125px;">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="customCheck2">
                      <label class="form-check-label" for="customCheck2">&nbsp;</label>
                    </div>
                  </td>
                  <td>
                    1234
                  </td>
                  <td>
                    Washington
                  </td>
                  <td>
                    $25,000
                  </td>
                  <td>
                    <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                    <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div> <!-- end card-body-->
        </div> <!-- end card-->
      </div> <!-- end col -->
    </div>
    <!-- end row -->

  </div>`,
  styles: [``]
})
export class SLTComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
