import {Component} from '@angular/core';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {PluginLocalStorageService} from '../plugin-local-storage.service';

@Component({
  selector: 'app-plugin',
  template: `
    <div class="container-fluid" role="document">
      <div class="row p-2 pb-0 border-bottom">
            <div class="col-11">
              <h3>Importing to Agency Management System</h3>
            </div>
            <div class="pr-0 col-1 text-lg-end">

              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeWindow()">
                <h2 class="mt-0">
                  X
                </h2>
              </button>
            </div>
      </div>
      <div class="row">
            <div class="col-12 pt-2 pb-2">
              <router-outlet></router-outlet>
            </div>
      </div>
    </div>
  `,
  styles: [
    `
      .mt-0 { margin-top: 0; }
      .pr-0 { padding-right: 0; }

      .close {
        color: #6c757d;
        line-height: 1;
        text-align: right;
        font-size: xxx-large;
        border: none;
        background: transparent;
      }
    `
  ]
})
export class PluginComponent {

  constructor(private localStorage: PluginLocalStorageService, private googleAnalytics: GoogleAnalyticsService) {}

  closeWindow(): void {
    this.localStorage.clearSubmissionData();
    this.googleAnalytics.event('click', 'plugin_modal', 'Close Modal');
    window.parent.postMessage({dmconnect: true}, '*');
  }
}
