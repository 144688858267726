import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  template: ``,
  styles: []
})
export class DmbridgeAuthorizeComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}
