import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {DmconnectBrowserService} from '../dmconnect-browser.service';
import {PluginLocalStorageService} from '../plugin-local-storage.service';
import {DmVersion} from '../DmVersion';

const DISPLAY_TEXT_INTERFACES = {
  clarion_door: 'ClarionDoor MGA Hub',
  cloud_services: 'dmBridge'
};

const DISPLAY_TEXT_AMS = {
  aim: 'Vertafore\'s Agency Information Manager (AIM)',
  walt: 'WALT'
};

@Component({
  selector: 'app-root',
  template:
  `
      <div class="col col-lg-6 col-sm-12 col-md-12">
        <div class="card d-block">
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item disabled"><i class="uil-layer-group me-1"></i> DmConnect Version {{ version }}</li>
              <li class="list-group-item disabled"><i class="mdi mdi-picture-in-picture-top-right-outline me-1"></i> DmConnect UI Version {{ uiVersion }}</li>
              <li class="list-group-item disabled"><i class="uil-cloud-info me-1"></i> {{ destInterface }}</li>
              <li class="list-group-item disabled"><i class="uil-database me-1"></i> {{ sourceAms }}</li>
              <li class="list-group-item disabled"><i class="uil-cloud-computing me-1"></i> {{ IndicateEnvironment() }} Environment</li>
              <li class="list-group-item disabled text-truncate"><i class="uil-shield-check me-1"></i> {{ luid }}</li>
            </ul>
          </div>
          <div class="card-body text-center mb-0">
            <a href="mailto:{{ supportEmail }}" class="btn btn-secondary">Email Us</a>
            <p class="mt-2">
              <a href="mailto:{{ supportEmail }}" class="card-link">{{ supportEmail }}</a>
            </p>
          </div>
        </div>
      </div>
  `,
  styles: [``]
})
export class AboutComponent implements OnInit {
  public readonly version: string | null;
  public readonly sourceAms: string | null;
  public readonly destInterface: string | null;
  public readonly supportEmail = 'Support@DarkMatterIns.com';
  public luid: string;
  public readonly uiVersion: string | null;

  constructor(private route: ActivatedRoute, private browser: DmconnectBrowserService, private localStorage: PluginLocalStorageService) {
    const routeData = route.snapshot;
    this.version = routeData.queryParamMap.get('version');
    const ams = routeData.queryParamMap.get('source')?.toLowerCase() || 'aim';
    const interf = routeData.queryParamMap.get('interface')?.toLowerCase() || 'clarion_door';
    this.sourceAms = DISPLAY_TEXT_AMS[ams] || null;
    this.destInterface = DISPLAY_TEXT_INTERFACES[interf] || null;
    this.browser.resize(600,  740);
    this.luid = this.localStorage.readDmLuid();
    this.uiVersion = DmVersion.version;
  }

  ngOnInit(): void {
  }

  IndicateEnvironment(): string {
    const hostname = window.location.hostname.toLowerCase();
    return hostname === 'dmconnect.darkmatterins.com' ? 'Production' : 'Development';
  }
}
