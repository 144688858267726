import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DmconnectBrowserService} from '../dmconnect-browser.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {HttpClient} from '@angular/common/http';
import {ODataSearchResponse} from '../ODataSearchResponse';
import {map, retry, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {WorkflowService} from '../workflows/workflow-service.service';
import {FeaturesService} from '../features.service';

@Component({
  selector: 'app-aim-screens',
  template: `
    <div class="container">
      <app-loading-indicator loading_text="Checking for assigned interfaces..." [is_loading]="this.is_loading"></app-loading-indicator>
      <ng-template [ngIf]="!this.has_interfaces && !this.is_loading">
        <div class="alert alert-danger" role="alert">
          <h4 class="alert-heading">There are no workflow Interfaces assigned to this account.</h4>
          <p>Please reach out to your admin to get workflows assigned to your account.</p>
        </div>
      </ng-template>
      <div class="row">
        <ng-container *ngFor="let value of interface_list | async">
          <div class="col-lg-3">
            <div class="card shadow-none hover-card" (click)="onClickInterface(value)" [ngClass]="{'disabled-card' : this.is_disabled }">
              <div class="disabled-card-overlay">&nbsp;</div>
              <div class="grid-container">
                <div class="card-img-top p-2">
                  <img class="mx-auto w-75 d-block" src="{{value.image}}" alt="{{value.title}}">
                </div>
                <div class="card-body p-2 text-center">
                  <a class="btn stretched-link">
                    <h5 class="card-title">{{value.description}}</h5>
                  </a>
                </div>
                <div class="card-footer bg-primary text-light text-center">
                  <span *ngIf="value.is_working" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                  {{ value.is_working ? 'In Progress...' : value.button_text }}
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      .card-title {
        height: 2em;
      }

      .card-img-top {
        height: 127px;
      }

      .card-img-top img {
        max-width: 165px;
        max-height: 123px;
      }

      .hover-card {
        border: 1px solid #eee;
      }

      .hover-card:hover {
        -webkit-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.075) !important;
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.075) !important;
        transform: scale(1.05);
      }

      .disabled-card .disabled-card-overlay {
        position: absolute;
        z-index: 9999;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.25);
        border-radius: 0.25rem;
       }
    `
  ]
})
export class DmbridgeAimLandingComponent implements OnInit {
  public is_loading = true;
  public interface_list: Observable<WorkflowInterface[]>;
  public is_disabled = false;
  public has_interfaces: boolean;
  constructor(private router: Router,
              private route: ActivatedRoute,
              private dmConnectBrowser: DmconnectBrowserService,
              private googleAnalytics: GoogleAnalyticsService,
              private http: HttpClient,
              private workflowService: WorkflowService,
              private features: FeaturesService) {
  }

  ngOnInit(): void {
    this.interface_list = this.features.getFeatures().pipe(
      switchMap(features => {
        if (!features.h_assigned_workflows) {
          return of(new ODataSearchResponse<WorkflowInterface>());
        }
        return this.http.get<ODataSearchResponse<WorkflowInterface>>
          (features.h_assigned_workflows, { headers: {'Cache-Control': 'no-cache'} }).pipe(retry(3));
      }),
      map(response => {
        this.is_loading = false;
        this.has_interfaces = !!response.items;
        return response.items;
      })
    );
  }

  onClickInterface(value: WorkflowInterface): void {
    if (this.is_disabled) {
      return;
    }
    this.googleAnalytics.event('clicked_interface_' + value.workflow_id, 'dmbridge_interface', 'Clicked dmBridge interface');
    value.is_working = true;
    this.is_disabled = true;
    if (value.query_page) {
      this.router.navigate([value.query_page], {queryParams: this.route.snapshot.queryParams});
      return;
    }
    this.workflowService.execute(value.workflow_id, { ...this.route.snapshot.queryParams }).subscribe((r) => {
      value.is_working = false;
      this.is_disabled = false;
    });
  }
}

class WorkflowInterface
{
  public workflow_id: string;
  public query_page: string;
  public image: string;
  public title: string;
  public description: string;
  public button_text: string;
  public is_working: boolean | undefined;
}
