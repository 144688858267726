import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {DmConnectPackageOptions} from '../dm-connect-package-options';

@Component({
  selector: 'app-input-one-pbs',
  template: `
    <div *ngIf="is_input1_pbs_selected" class="card d-block">
      <div class="card-body">
        <h5 class="card-title mb-3">Input1 PBS</h5>
        <div class="input-group mb-3">
          <label class="input-group-text" for="alternateAttachDir">Alternate Attach Directory</label>
          <input type="text" class="form-control" id="alternateAttachDir" [(ngModel)]="alternate_attach_dir"
                (change)="inputPbsConfigChanged()" placeholder="\\\\fileshare\\dm\\alternate-directory">
        </div>
        <div class="input-group mb-3">
          <label class="input-group-text" for="defaultProductRate">Default Product Rate</label>
          <input type="text" class="form-control" id="defaultProductRate" [(ngModel)]="default_product_rate"
                 (change)="inputPbsConfigChanged()" placeholder="PKG1">
        </div>
        <div class="input-group mb-3">
          <label class="input-group-text" for="defaultQuoteConfiguration">Default Quote Configuration</label>
          <input type="text" class="form-control" id="defaultQuoteConfiguration" [(ngModel)]="default_quote_configuration"
                 (change)="inputPbsConfigChanged()" placeholder="PKG1">
        </div>
      </div> <!-- end card-body-->
    </div> <!-- end card-->
  `,
  styles: [`
    .input-group-text { min-width: 15em }
  `]
})

export class InputOnePbsConfigComponent implements OnInit {
  public alternate_attach_dir: string;
  public default_product_rate: string;
  public default_quote_configuration: string;
  public is_input1_pbs_selected: boolean;
  @Input() packageOptions: Subject<DmConnectPackageOptions>;
  @Output() onChange = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
    this.packageOptions.subscribe((next) => {
      this.is_input1_pbs_selected = next.application_interface_ids.includes('input1');
      this.alternate_attach_dir = next.alternate_attach_dir;
      this.default_product_rate = next.default_product_rate;
      this.default_quote_configuration = next.default_quote_configuration;
    });
  }

  inputPbsConfigChanged(): void {
    this.onChange.emit({
      alternate_attach_dir: this.alternate_attach_dir,
      default_product_rate: this.default_product_rate,
      default_quote_configuration: this.default_quote_configuration
    });
  }
}
