import {Component, OnInit} from '@angular/core';
import {PluginLocalStorageService, StoredDataType} from '../plugin-local-storage.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {WorkflowService} from '../workflows/workflow-service.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-clearance',
  template: `
    <div class="row align-items-center row-cols-lg-auto">
      <div class="col-auto">
        <div class="input-group mt-2">
          <div class="input-group-text"><i class="uil-search"></i></div>
          <input id="searchField" type="text" class="form-control" [(ngModel)]="search_name" placeholder="AMS ID or Insured Name" (keydown.enter)="onClickSearch()"/>
        </div>
      </div>
      <div class="col-auto">
        <div class="input-group mt-2">
          <div class="input-group-text"><i class="uil-map-marker"></i></div>
          <input type="text" class="form-control" id="zipCode" [(ngModel)]="search_zip" placeholder="Zip Code" (keydown.enter)="onClickSearch()"/>
        </div>
      </div>
      <div class="col-auto">
        <button id="btn-search" class="btn btn-primary mt-2" type="button" (click)="onClickSearch()" [disabled]="loading || is_assigning">
          <span *ngIf="loading" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>{{ loading ? "Loading ..." : "Search" }}
        </button>
      </div>
      <div class="col-auto">
       <button id="btn-create-submission" class="btn btn-secondary mt-2" type="button" (click)="onClickCreateNewInsured()" [disabled]="is_assigning || loading" *ngIf="!disable_new_insured">
         New Insured
       </button>
      </div>
    </div>
    <div class="row">
      <div class="col"><br/></div>
    </div>

    <div class="table-responsive">
      <table class="table table-striped position-relative">
        <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col" *ngIf="custom_column_name">{{custom_column_name}}</th>
          <th scope="col">Name</th>
          <th scope="col" *ngIf="display_retailer_column">Retail Agent</th>
          <th scope="col">Zip</th>
          <th scope="col">Coverage</th>
          <th scope="col">Effective</th>
          <th scope="col" class="mw-8em">
            Assignment <i class="uil uil-info-circle" placement="bottom"
                          ngbTooltip="Indicates if the submission has previously been assigned."></i>
          </th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngIf="!loading && search_results && search_results.length > 0">
          <tr *ngFor="let match of search_results | slice: (page - 1) * pageSize : page * pageSize">
            <td class="align-middle">{{ match.id }}</td>
            <td class="align-middle" *ngIf="custom_column_name">{{ match.custom_column1 }}</td>
            <td class="align-middle">{{ match.named_insured }}</td>
            <td class="align-middle text-truncate mxw-10vw" *ngIf="display_retailer_column"><span *ngIf="match.insured?.retailer?.agency_id" placement="bottom-left" ngbTooltip="{{ match.insured?.retailer?.agency_name }} ({{ match.insured?.retailer?.agency_id }})">{{ match.insured?.retailer?.agency_name }} ({{ match.insured?.retailer?.agency_id }})</span></td>
            <td class="align-middle">{{ match.insured?.mailing_address?.postal_code }}</td>
            <td class="align-middle text-truncate mxw-10vw"><span placement="bottom-left" ngbTooltip="{{ match.coverage }}">{{ match.coverage }}</span></td>
            <td class="align-middle text-truncate mxw-10vw"><span placement="bottom-left" ngbTooltip="{{ match.effective | date:'MM-dd-yyyy' }}">{{ match.effective | date:'MM-dd-yyyy' }}</span></td>
            <td class="align-middle text-center"><span class="badge badge-outline-secondary mw-8em"
                                                       [ngClass]="{'bg-secondary text-light': match.correlation_id}">{{match.correlation_id ? 'Assigned' : 'Not Assigned'}}</span>
            </td>
            <td class="text-right align-middle buttons-col">
              <button [disabled]="active_button || (match.correlation_id) || is_assigning" class="btn btn-sm btn-primary rounded"
                      (click)="onClickAssign(match)">
                <span *ngIf="is_assigning && active_button == match.id" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                <span *ngIf="!is_assigning">Assign</span>
                <span *ngIf="is_assigning">Assigning</span>
              </button>&nbsp;
              <button *ngIf="!disable_new_submission" [disabled]="active_button || is_assigning" class="btn btn-sm btn-secondary rounded"
                      (click)="onClickCreateNewSubmission(match)">New Submission
              </button>
            </td>
          </tr>
          <tr class="bg-transparent">
            <td [colSpan]="column_count" class="w-100">
              <ngb-pagination [disabled]="is_assigning" [collectionSize]="search_results.length" [(page)]="page" [pageSize]="pageSize"
                              class="d-flex justify-content-center"></ngb-pagination>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="!loading && (!search_results || search_results.length == 0)">
          <tr>
            <td [colSpan]="column_count">No records found. Try a broader search phrase.</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="loading">
          <tr>
            <td [colSpan]="column_count">Searching Agency Management System for Submissions...</td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  `,
  styles: [`
    td, th {
      padding: .5em;
      vertical-align: center;
    }

    .text-right {
      text-align: right;
      padding-right: 1em;
    }

    .mw-8em {
      min-width: 8em;
    }

    .mxw-10vw {
      max-width: 10vw;
    }

    .buttons-col {
      min-width: 16.2em;
    }
  `]
})
export class ClearanceComponent implements OnInit {
  public active_button: string;
  public search_name: string;
  public search_zip: string;
  public preflight_payload: any;
  public portal_payload: any;
  public loading: boolean;
  public search_results: any[];
  public page = 1;
  public pageSize = 5;
  public disable_new_submission: boolean;
  public disable_new_insured: boolean;
  public is_assigning = false;
  public custom_column_name: string = null;
  public display_retailer_column = false;
  public column_count: number;
  public search_physical_address = false;
  readonly portal_id: string;

  constructor(private route: ActivatedRoute,
              private localStorage: PluginLocalStorageService, private workflowService: WorkflowService,
              private routerService: Router,  private googleAnalytics: GoogleAnalyticsService) {
    const temp = localStorage.read(StoredDataType.PREFLIGHT_PAYLOAD);
    this.portal_id = temp.portal_id;
    this.preflight_payload = temp.payload;
    this.portal_payload = localStorage.read(StoredDataType.PORTAL_PAYLOAD);
    this.search_name = this.preflight_payload?.insured?.dba ?? this.preflight_payload?.insured?.name;
    this.search_physical_address = !!+this.preflight_payload?.search_physical_address;
    this.search_zip = this.search_physical_address ? this.preflight_payload?.insured?.physical_address?.postal_code : this.preflight_payload?.insured?.mailing_address?.postal_code;
  }

  ngOnInit(): void {
    this.search(this.search_name, this.search_zip);
  }

  onClickSearch(): void {
    this.googleAnalytics.event('clicked_search', 'non_correlated', 'Clicked Search Button');
    this.search(this.search_name, this.search_zip);
  }

  onClickCreateNewSubmission(submission?: any): void {
    this.googleAnalytics.event('clicked_create_submission', 'non_correlated', 'Clicked Create New Submission');
    this.localStorage.save(this.portal_id, submission, StoredDataType.PREVIOUS_INSURED);
    this.routerService.navigate(['../new-submission'], { relativeTo: this.route });
  }

  onClickCreateNewInsured(): void {
    this.googleAnalytics.event('clicked_create_insured', 'non_correlated', 'Clicked Create Insured');
    this.routerService.navigate(['../new-submission'], { relativeTo: this.route });
  }

  onClickAssign(submission: any): void {
    this.is_assigning = true;
    this.active_button = submission.id;
    this.googleAnalytics.event('clicked_assign', 'non_correlated', 'Clicked Assign Button');
    this.workflowService.execute('inbound', { dm_target_id: submission.id, dm_portal_id: this.portal_payload.portal_id, dm_ams_submission: submission, ...this.portal_payload.payload}).subscribe(() => {
      this.active_button = null; this.is_assigning = false;
    });
  }

  search(query, zipQuery?): void {
    this.column_count = 7;
    this.loading = true;
    const filterValues = this.generateFilters(query, zipQuery);
    if (filterValues) {
      this.workflowService.execute('clearance', filterValues, 'ams/').subscribe(res => {
        if (res.success) {
          this.search_results = res.output.items;
          this.disable_new_insured = res.output.disable_new_insured;
          this.disable_new_submission = res.output.disable_new_submission;
          this.custom_column_name = res.output.custom_column_name;
          this.display_retailer_column = res.output.items.findIndex(x => x.insured?.retailer?.agency_id) !== -1;
          this.setColumnSize(this.display_retailer_column);
          this.setColumnSize(this.custom_column_name);
        }

        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }

  private setColumnSize(show_item: any): void {
    if (show_item) {
      this.column_count++;
    }
  }

  private generateFilters(query, zipQuery): any {
    const filter: any = {dm_portal_id: this.portal_id, search_physical_address: this.search_physical_address};
    if (query && !/^\s+$/.test(query)) {
      filter.id = query;
      filter.named_insured = query;
    }
    if (zipQuery && !/^\s+$/.test(zipQuery)) {
      filter.address = { zip: zipQuery};
    }
    return filter;
  }
}
