import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {WorkflowService} from '../../../workflows/workflow-service.service';
import {first, map, retry} from 'rxjs/operators';

@Component({
  selector: 'app-submission-totals',
  template: `
    <div class="row">
      <div class="col-sm-6">
        <div class="card tilebox-one">
          <div class="card-body">
            <i class="uil uil-exchange-alt float-end"></i>
            <h4 class="header-title">Correlations</h4>
            <h2 class="my-2">{{ totals_information?.total_correlations }}</h2>
          </div> <!-- end card-body-->
        </div> <!-- end card-->
      </div> <!-- end col-->
      <div class="col-sm-6">
        <div class="card tilebox-one">
          <div class="card-body">
            <i class="uil uil-clipboard-notes float-end"></i>
            <h4 class="header-title">Submissions</h4>
            <h2 class="my-2">{{ totals_information?.total_submissions }}</h2>
          </div> <!-- end card-body-->
        </div> <!-- end card-->
      </div> <!-- end col-->
    </div> <!-- end row -->
    <div class="row">
      <div class="col-sm-6">
        <div class="card tilebox-one">
          <div class="card-body">
            <i class="uil uil-lock-alt float-end"></i>
            <h4 class="header-title">Bound Submissions</h4>
            <h2 class="my-2">{{ totals_information?.total_bound }}</h2>
          </div> <!-- end card-body-->
        </div> <!-- end card-->
      </div> <!-- end col-->
      <div class="col-sm-6">
        <div class="card tilebox-one">
          <div class="card-body">
            <i class="uil uil-dollar-sign float-end"></i>
            <h4 class="header-title">Bound Premium</h4>
            <h2 class="my-2">{{ totals_information?.bound_premium | currency }}</h2>
          </div> <!-- end card-body-->
        </div> <!-- end card-->
      </div> <!-- end col-->
    </div> <!-- end row -->
  `,
  styles: [``]
})
export class SubmissionTotalsComponent implements OnChanges {

  @Input() date_range: any;
  @Output() display_error = new EventEmitter<any>();
  public totals_information: AggregateTotals;

  constructor(private workflowService: WorkflowService) {}

  ngOnChanges(): void {
    this.get_data(this.date_range);
  }

  private get_data(range: any): void {
    this.date_range = range;
    this.workflowService.execute('ams_analytics', {date_range: range, report_type: 'totals'})
      .pipe(first(), retry(3), map((r) => {
        if (r?.success && r?.output?.items.length > 0) {
          this.display_error.emit(false);
          this.totals_information = new AggregateTotals(r.output.items);
        }
      })).subscribe();
  }
}

export class AggregateTotals {
  public readonly total_correlations: number;
  public readonly total_bound: number;
  public readonly total_submissions: number;
  public readonly bound_premium: number;

  constructor(items: {value: number, label: string}[]) {
    for (const i of items) {
      this[i.label] = i.value;
    }
  }
}
