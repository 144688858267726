import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Component({
  selector: 'app-root',
  template:
    `
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <h4 class="page-title">Analytics (BETA)</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="text-end text-nowrap">
            <div class="btn-group mb-3">
              <button type="button" class="btn text-uppercase" [class]="range == DateRange.ALL ? 'btn-primary' : 'btn-light'"
                      (click)="update_range(DateRange.ALL)">All Time
              </button>
            </div>
            <div class="btn-group mb-3 ms-1">
              <button type="button" class="btn text-uppercase" [class]="range == DateRange.DAY ? 'btn-primary' : 'btn-light'"
                      (click)="update_range(DateRange.DAY)">24 Hours
              </button>
              <button type="button" class="btn text-uppercase" [class]="range == DateRange.WEEK ? 'btn-primary' : 'btn-light'"
                      (click)="update_range(DateRange.WEEK)">Week
              </button>
              <button type="button" class="btn text-uppercase" [class]="range == DateRange.MTD ? 'btn-primary' : 'btn-light'"
                      (click)="update_range(DateRange.MTD)">MTD
              </button>
              <button type="button" class="btn text-uppercase" [class]="range == DateRange.YTD ? 'btn-primary' : 'btn-light'"
                      (click)="update_range(DateRange.YTD)">YTD
              </button>
            </div>
          </div>
        </div><!-- end col-->
      </div> <!-- end row -->
      <div class="row" [class.visually-hidden]="display_error">
        <div class="col-xl-6">
          <app-correlation-chart class="w-100" [date_range]="range" (display_error)="display_error = $event"></app-correlation-chart>
        </div>
        <div class="col-xl-6">
          <app-submission-totals class="w-100" [date_range]="range" (display_error)="display_error = $event"></app-submission-totals>
        </div>
        <div class="col-xl-6">
          <app-account-executive class="w-100" [date_range]="range" (display_error)="display_error = $event"></app-account-executive>
        </div>
        <div class="col-xl-6">
          <app-aggregate-interface class="w-100" [date_range]="range" (display_error)="display_error = $event"
                                   [available_interfaces]="AVAILABLE_INTERFACES"></app-aggregate-interface>
        </div>
        <div class="col-xl-6">
          <app-bound-interface class="w-100" [date_range]="range" (display_error)="display_error = $event"
                               [available_interfaces]="AVAILABLE_INTERFACES"></app-bound-interface>
        </div>
        <div class="col-xl-6">
          <app-unbound-by-interface class="w-100" [date_range]="range" (display_error)="display_error = $event" [available_interfaces]="AVAILABLE_INTERFACES"></app-unbound-by-interface>
        </div>
      </div> <!-- end row -->
      <div class="row justify-content-center" *ngIf="display_error">
        <div class="col-lg-4">
          <div class="text-center">
            <img src="assets/images/file-searching.svg" height="120" alt="Under maintenance image">
            <h4 class="text-uppercase text-danger mt-3">Contact Support</h4>
            <p class="text-muted mt-3">To enable DmConnect analytics contact Dark Matter Support</p>
            <a class="btn btn-primary mt-3" href="mailto: Support@DarkMatterIns.com"><i class="uil-envelope"></i> Contact</a>
          </div>
        </div> <!-- end col-->
      </div> <!-- end row -->
    `,
  styles: [``]
})
export class DashboardComponent implements OnInit  {
  public DateRange = DateRange;
  public display_error = true;
  public range: any;
  public readonly AVAILABLE_INTERFACES = {
    ategrity_cwb: 'Ategrity',
    atlantic_casualty: 'Atlantic Casualty',
    au_goldv1: 'AU Gold',
    cd_mgahub: 'Clarion Door',
    cd_pl: 'Clarion Door PL',
    ftp_pl: 'FTP Personal Lines',
    input1: 'Personal Finance',
    markel_mol: 'Markel',
    nationwide: 'Nationwide',
    nic_one: 'Nautilus',
    sga_cpl_portal: 'SGA PL Portal',
    sga_garage_portal: 'SGA GARAGE Portal',
    sga_glprop_portal: 'SGA GL/PROP Portal',
    spg_invoice_bridge: 'SPG Invoice Bridge',
    spg_aim_2_oracle: 'SPG AIM to Oracle',
    tsga_invoice_bridge: 'TSGA Invoice Bridge',
    western_world_portal: 'Western World'
  };

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private googleAnalytics: GoogleAnalyticsService) {}

  ngOnInit(): void {
    const param = this.activatedRoute.snapshot.queryParamMap.get('date_range');
    this.range = DateRange[param?.toUpperCase()] ?? DateRange.DAY;
  }

  update_range(range: any): void {
    this.range = range;
    this.googleAnalytics.event('changed_date_range', 'analytics', 'Changed Date Range');
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { date_range: range }});
  }
}

export enum DateRange {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MTD = 'MTD',
  YTD = 'YTD',
  ALL = 'ALL'
}
