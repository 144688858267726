import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {OnPackageOptionChange} from './on-package-option-change';
import {DmConnectPackageOptions} from '../dm-connect-package-options';

@Component({
  selector: 'app-nexsure-config',
  template: `
    <div class="card d-block" [class.d-none]="!is_visible">
      <div class="card-body">
        <h5 class="card-title mb-3">Nexsure Configurations</h5>
        <div class="input-group mb-3">
          <label class="input-group-text">Production</label>
          <input type="text" class="form-control" (change)="nexsureConfigChanged()" [(ngModel)]="production_url" placeholder="https://nexsureapihost.local">
        </div>
        <div class="input-group mb-3">
          <label class="input-group-text">Testing</label>
          <input type="text" class="form-control" (change)="nexsureConfigChanged()" [(ngModel)]="development_url" placeholder="https://nexsureapihost.local">
        </div>
        <div class="input-group">
          <label class="input-group-text">Custom Column Name</label>
          <input type="text" class="form-control" (change)="nexsureConfigChanged()" [(ngModel)]="custom_column_name" placeholder="Application Number">
        </div>
      </div> <!-- end card-body-->
    </div>
  `,
  styles: [`.input-group-text { min-width: 7em }`]
})

export class NexsureConfigComponent implements OnInit, OnPackageOptionChange {
  @Input() packageOptions: Subject<DmConnectPackageOptions>;
  @Output() onChange = new EventEmitter<any>();
  public production_url: string;
  public development_url: string;
  public custom_column_name: string;
  public is_visible = false;

  constructor() {}

  ngOnInit(): void {
    this.packageOptions.subscribe((next) => {
      this.production_url = next.nexsure_production_url;
      this.development_url = next.nexsure_development_url;
      this.custom_column_name = next.custom_column_name;
      this.is_visible = next.ams_id === 'nexsure';
    });
  }

  nexsureConfigChanged(): void {
    this.onChange.emit({nexsure_production_url: this.production_url, nexsure_development_url: this.development_url, custom_column_name: this.custom_column_name});
  }
}
