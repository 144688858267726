import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  template:
    `
      <app-loading-indicator loading_text="Initializing Dark Matter Components..." is_loading="true"></app-loading-indicator>
    `,
  styles: []
})
export class InitializingComponent implements OnInit {

  constructor() {

  }

  ngOnInit(): void {
  }
}
