import {AuthGuard, AuthService} from '@auth0/auth0-angular';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, concatMap, first, mergeMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {FeaturesService} from '../features.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnsureUserAuthGuard extends AuthGuard {

  constructor(private http: HttpClient, private features: FeaturesService, private authService: AuthService) {
    super(authService);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (next.queryParams.code && next.queryParams.state) {
      this.authService.isAuthenticated$.pipe(concatMap(isAuthenticated => {
        if (isAuthenticated) {
          return this.features.getFeatures().pipe(concatMap(f =>
            this.http.post(f.h_ensure_user, {}, {headers: {'X-DM-Authorize': 'management_api'}})
          ), catchError(async err => false));
        }
        return of(false);
      }), first()).subscribe();
    }
    return super.canActivate(next, state);
  }
}
