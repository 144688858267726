import {SqlConfiguration} from './sql-configuration';
import {JsonTranspositions} from './json-transpositions';
import {SqlTranspositions} from './sql-transpositions';
import {TranspositionLibrary} from './packaging/transpositions/TranspositionLibrary';

export class DmConnectPackageOptions {
  public ams_id: string;
  public application_interface_ids: Array<string> = new Array<string>();
  public sign_files = true;
  public include_stored_procedures = true;
  public client_token: string;
  public support_details: string;
  public location_production: string;
  public location_development: string;
  public sql_configuration: Array<SqlConfiguration> = new Array<SqlConfiguration>();
  public cors_origins: Array<string> = new Array<string>();
  public cdmgahub_production_url: string;
  public cdmgahub_development_url: string;
  public markets: string;
  public uar_use_commercial_property_coverage: boolean;
  public uar_use_gl_rating_detail: boolean;
  public uar_use_active_user: boolean;
  public clariondoor_url: string;
  public use_current_user: boolean;
  public allow_updates: boolean;
  public excluded_retailer_status_codes: string;
  public processed_xml_folder: string;
  public unprocessed_xml_folder: string;
  public alternate_attach_dir: string;
  public default_product_rate: string;
  public default_quote_configuration: string;
  public json_transpositions: JsonTranspositions = new JsonTranspositions();
  public sql_transpositions: SqlTranspositions;
  public is_service: boolean;
  public concept_production_url: string;
  public concept_development_url: string;
  public custom_column_name: string;
  public disable_new_insured: boolean;
  public disable_new_submission: boolean;
  public nexsure_production_url: string;
  public nexsure_development_url: string;
  public transposition_libraries: { [key: string]: TranspositionLibrary } = {};
}
