import { Component, OnInit } from '@angular/core';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {catchError, first, map, switchMap} from 'rxjs/operators';
import {BehaviorSubject, from, of, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {FeaturesService} from '../features.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ODataSearchResponse} from '../ODataSearchResponse';
import {StagingBucket} from './stagingBucket/StagingBucket';

@Component({
  selector: 'app-create-staging-bucket-configuration',
  template: `
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <h4 class="page-title">Create Staging Bucket</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <app-loading-indicator [is_loading]="(this.h_create_entity_route | async) === null"
                                       loading_text="Checking permissions..."></app-loading-indicator>
              </div>
              <div *ngIf="creation_status" class="row g-2" [ngSwitch]="creation_status">
                <div class="mt-3 col-xl-12">
                  <div class="alert alert-danger" role="alert" *ngSwitchCase="'failure'">
                    <h4 class="alert-heading">An error occurred while creating the staging bucket.</h4>
                    <hr>
                    <p class="mb-0">If the error persists please contact us at <a class="text-danger"
                                                                                  href="mailto:{{support_email}}">{{support_email}}</a>.</p>
                  </div>
                  <div class="alert alert-success" role="alert" *ngSwitchCase="'success'">
                    <h4 class="alert-heading">Creation Successful</h4>
                    <hr>
                    <p>The staging bucket creation was successful.</p>
                  </div>
                  <span *ngSwitchDefault></span>
                </div>
              </div>
              <div class="row" *ngIf="(this.h_create_entity_route | async) !== null">
                <div class="col-xl-6">
                  <div class="mb-3">
                    <label for="inputId" class="form-label">Bucket Reference Id</label>
                    <input type="text" class="form-control" id="reference_id" [(ngModel)]="bucket.reference_id" #reference_id="ngModel"
                           [class.is-invalid]="!bucket.reference_id && reference_id.touched" name="reference_id" placeholder="Reference ID"
                           required>
                    <div class="invalid-feedback">
                      Please enter a Reference Id
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="inputName" class="form-label">Bucket Name</label>
                    <input type="text" class="form-control" id="inputName" [(ngModel)]="bucket.name" #name="ngModel"
                           [class.is-invalid]="!bucket.name && name.touched" name="name" placeholder="Name" required>
                    <div class="invalid-feedback">
                      Please enter a Name
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label" for="active">Active</label>
                    <select class="form-select" id="active" [(ngModel)]="bucket.is_active" name="active">
                      <option [ngValue]="true">Active</option>
                      <option [ngValue]="false">Inactive</option>
                    </select>
                  </div>
                  <button type="button" class="btn btn-primary" (click)="createStagingBucket()"
                          [disabled]="creation_in_progress || !bucket.reference_id || !bucket.name || bucket.columns.length <= 0">
                    <span *ngIf="creation_in_progress">
                      <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                      Creating...
                    </span>
                    <span *ngIf="!creation_in_progress">Create</span></button>
                </div>
                <div class="col-xl-6">
                  <div class="row">
                    <div class="col-xl-5"><label class="form-label">Display Column Key</label></div>
                    <div class="col-xl-5"><label class="form-label">Display Column Description</label></div>
                    <div class="col-xl-2">&nbsp;</div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-xl-5">
                      <input type="text" class="form-control" id="inputColumnName" [(ngModel)]="column_key_field" #key="ngModel"
                             [class.is-invalid]="!column_key_field && bucket.columns.length <= 0 && key.touched"
                             name="columnKey"
                             placeholder="Column Key">
                      <div class="invalid-feedback mb-3">
                        Please enter a key
                      </div>
                    </div>
                    <div class="col-xl-5">
                      <input type="text" class="form-control" id="inputColumnName" [(ngModel)]="column_description_field"
                             #description="ngModel"
                             [class.is-invalid]="!column_description_field && bucket.columns.length <= 0 && description.touched"
                             name="columnDescription" placeholder="Column Description">
                      <div class="invalid-feedback mb-3">
                        Please enter a description
                      </div>
                    </div>
                    <div class="col-xl-2 align-top">
                      <button [disabled]="!this.column_description_field && !this.column_key_field" class="btn btn-outline-success"
                              (click)="addColumn()">+
                      </button>
                    </div>
                  </div>
                  <div class="row mb-1" *ngFor="let column of this.bucket.columns; let i = index">
                    <div class="col-xl-5">
                      <input type="text" class="form-control" name="columnKey" placeholder="{{column.key}}" readonly>
                    </div>
                    <div class="col-xl-5">
                      <input type="text" class="form-control" name="columnDescription" placeholder="{{column.description}}" readonly>
                    </div>
                    <div class="col-xl-2">
                      <button class="btn btn-outline-danger" (click)="removeColumn(i)">-</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    input:not(.is-invalid) ~ .invalid-feedback, select:not(.is-invalid) ~ .invalid-feedback {
      display: block;
      display: none;
    }
  `]
})
export class CreateStagingBucketComponent implements OnInit{
  public h_create_entity_route: Subject<string> = new BehaviorSubject<string>(null);
  public bucket: any = new StagingBucket();
  public column_description_field: string;
  public column_key_field: any;
  public creation_status: string;
  public readonly support_email = 'Support@DarkMatterIns.com';
  public creation_in_progress: boolean;

  constructor(private features: FeaturesService, private router: Router, private route: ActivatedRoute,
              private googleAnalytics: GoogleAnalyticsService, private http: HttpClient) {
  }

  ngOnInit(): void
  {
    this.features.getFeatures().pipe(first(), switchMap(feats => {
      if (feats.h_search_staging_buckets) {
        return this.http.get<ODataSearchResponse<any>>(feats.h_search_staging_buckets, { headers: {'X-DM-Authorize': 'management_api'}});
      } else {
        return from(this.router.navigate(['errors/403'])).pipe(switchMap( f => of(new ODataSearchResponse<any>())));
      }
    }), map(response => {
      if (response && response.h_create_entity) {
        this.h_create_entity_route.next(response.h_create_entity);
      } else {
        this.router.navigate(['errors/403']);
      }
    })).subscribe();
  }

  addColumn(): void {
      this.googleAnalytics.event('clicked_add_column', 'dmbridge_staging_buckets', 'Clicked Add Column To Staging Bucket');
      this.bucket.columns.push({key: this.column_key_field, description: this.column_description_field});
      this.column_description_field = null;
      this.column_key_field = null;
  }

  removeColumn(index: number): void {
    this.googleAnalytics.event('clicked_remove_column', 'dmbridge_staging_buckets', 'Clicked Remove Column From Staging Bucket');
    this.bucket.columns.splice(index, 1);
  }

  createStagingBucket(): void {
    this.creation_in_progress = true;
    this.googleAnalytics.event('clicked_create', 'dmbridge_staging_buckets', 'Clicked Submit For Creating Staging Bucket');
    this.h_create_entity_route.pipe(
      switchMap( r => this.http.post(r, this.bucket, { observe: 'response', headers: {'X-DM-Authorize': 'management_api'}})),
      switchMap( response => {
        if (response && response.status === 201) {
          this.creation_status = 'success';
          setTimeout(() => {
            this.router.navigate(['..'], { relativeTo: this.route });
          }, 3000);
          return of(true);
        } else {
          this.creation_status = 'failure';
          return of(false);
        }
      }),
      catchError(() => {
        this.creation_status = 'failure';
        return of(false); }
      ),
      map(r => this.creation_in_progress = false)).subscribe();
  }
}
