import {IdentifiableRecord} from './identifiable-record';

export class TransposableValue implements IdentifiableRecord {
  private direction: string;

  constructor(public left_value: string, public right_value: string, public is_left_editable: boolean) {
    this.direction = is_left_editable ? 'LEFT' : 'RIGHT';
  }

  static ensure(input: TransposableValue, is_left_editable: boolean): TransposableValue {
    const lvalue = input?.left_value;
    const rvalue = input?.right_value;
    const is_left = input?.is_left_editable ?? is_left_editable;

    return input instanceof TransposableValue ? input : new TransposableValue(lvalue, rvalue, is_left);
  }

  get id(): string {
    return this.formatId(this.left_value, this.right_value, this.direction);
  }

  private formatId(left_value: string, right_value: string, direction: string): string {
    return `${left_value ?? ''}_${direction}_${right_value ?? ''}`;
  }

  get noneditable_id(): string {
    const left_value = this.is_left_editable ? '' : this.left_value;
    const right_value = this.is_left_editable ? this.right_value : '';

    return this.formatId(left_value, right_value, this.direction);
  }

  public toPersistableClone(): TransposableValue {
    const transposableValue = new TransposableValue(this.left_value, this.right_value, this.is_left_editable);
    delete transposableValue.is_left_editable;
    delete transposableValue.direction;

    return transposableValue;
  }
}
