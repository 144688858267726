import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ODataSearchResponse} from '../../ODataSearchResponse';
import {catchError, mergeMap} from 'rxjs/operators';
import {ConfigurationOption} from '../configuration-option';
import {OnPackageOptionChange} from './on-package-option-change';
import {DmConnectPackageOptions} from '../dm-connect-package-options';

@Component({
  selector: 'app-ams-selector',
  template: `
    <div class="card">
      <div class="card-body">
        <h5 class="card-title mb-3">Agency Management Systems (AMS)</h5>
        <div class="form-floating">
          <select class="form-select" [ngModel]="selectedAms" (ngModelChange)="selectAms($event)">
            <option disabled="true" [ngValue]="undefined" [hidden]="!isLoading">Loading Options...</option>
            <option *ngFor="let ams of amsOptions | async" value="{{ams.id}}">{{ams.name}}</option>
          </select>
          <label>Select AMS</label>
        </div>
      </div>
    </div>
  `,
  styles: [``]
})

export class AmsSelectorComponent implements OnInit, OnPackageOptionChange {
  // @ts-ignore
  private readonly SERVICE_URL = Dmc.SUPPORT_ADDRESS + 'interface-scripts/ams';
  public isLoading = true;
  public amsOptions = new Observable<ConfigurationOption[]>();
  public selectedAms: string;
  @Input() packageOptions: Subject<DmConnectPackageOptions>;
  @Output() onChange = new EventEmitter<any>();

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.packageOptions.subscribe((next) => {
      if (next.ams_id !== this.selectedAms) {
        this.selectedAms = next.ams_id;
      }
    });

    this.amsOptions = this.getAmsOptions();
  }

  getAmsOptions(): Observable<ConfigurationOption[]> {
    this.isLoading = true;
    return this.http.get<ODataSearchResponse<ConfigurationOption>>(this.SERVICE_URL).pipe(mergeMap(response => {
      if (response) {
        if (response.items.length > 0 && !response.items.find((ams) => ams.id === this.selectedAms)) {
          this.selectAms(response.items[0].id);
        }

        this.isLoading = false;
        return of(response.items);
      }
    }), catchError(() => {
      return of([]);
    }));
  }

  selectAms(amsId: string): void {
    this.selectedAms = amsId;
    this.onChange.emit({ams_id: amsId});
  }
}
