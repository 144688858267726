export abstract class HasMergeableChildren<T extends { id: string }> {
  protected children_dictionary?: { [key: string]: T } = {};

  protected constructor(protected children?: T[]) {
  }

  protected abstract ensureChildType(input: T): T;

  protected abstract mergeChild(original: T, supersede: T, is_default?: boolean): void;

  public mergeSingle(input: T, is_default?: boolean): void {
    this.mergeChildren([ input ], is_default);
  }

  public mergeChildren(input: T[], is_default?: boolean): void {
    if (input) {
      for (const item of input) {
        if (this.children_dictionary[item.id]) {
          this.mergeChild(this.children_dictionary[item.id], item, is_default);
        } else {
          const child = this.ensureChildType(item);
          this.children_dictionary[item.id] = child;
          this.children.push(child);
        }
      }
    }
  }

  public find(key: string): T {
    return this.children_dictionary[key];
  }

  protected buildStructures(input: T[]): void {
    if (input) {
      for (let x = 0; x < input.length; x++) {
        const record = this.ensureChildType(input[x]);
        this.children_dictionary[record.id] = record;
        input[x] = record;
      }
    } else {
      input = [];
    }

    this.children = input;
  }
}
