import { Injectable } from '@angular/core';
import {WorkflowResult} from '../dm-connect-api.service';
import {Observable} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {WorkflowResponseHandler} from './workflow-response-handler.service';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  constructor(private http: HttpClient, private handler: WorkflowResponseHandler) { }

  execute(workflowName: string, inputFact: any, path: string = 'workflow/', httpMethod: string = 'POST'): Observable<WorkflowResult> {
    return this.executePath(path, workflowName, inputFact, httpMethod);
  }

  protected executePath(path: string, workflowName: string, inputFact: any, httpMethod: string): Observable<WorkflowResult> {
    // @ts-ignore
    return this.http.request<WorkflowResult>(httpMethod, Dmc.API_ADDRESS + path + workflowName, { observe: 'response', headers: inputFact.headers, params: inputFact.params, body: inputFact })
      .pipe(switchMap(p => this.evaluateResult(workflowName, p)),
        catchError(async (err) => this.evaluateResult(workflowName, err)));
  }

  private evaluateResult(workflowName: string, r: any): Observable<any> {
    let output;

    if (r.status === 408) {
      output = this.handler.handle(workflowName, {error: {workflow_id: 'REQUEST_TIMEOUT', message: 'The service took too long to respond.'}});
    } else if (r.status !== 200 && r.status !== 304) {
      output = this.handler.handle(workflowName, { error: { workflow_id: 'INVALID_RESPONSE', message: 'The service is temporarily unavailable.' }});
    } else {
      output = this.handler.handle(workflowName, r.body);
    }

    return output;
  }
}
