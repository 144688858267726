import {Component, Input} from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tbody[app-loading-shimmer]',
  template: `
    <ng-container *ngIf="loading">
      <tr *ngFor="let i of [].constructor(rows)">
        <td [colSpan]="columns"><div class="w-100 shimmer">&nbsp;</div></td>
      </tr>
    </ng-container>
    <ng-content *ngIf="!loading"></ng-content>`,
  styles: [`
    .shimmer {
      background-image: linear-gradient(to right, #f1f3fa 0%, #dfe3f0 50%, #f1f3fa 100%);
      background-repeat: no-repeat;
      background-size: 10em 100%;
      -webkit-mask-image: linear-gradient(to right, #0000 0%, #000f 50%, #0000 100%);
      mask-image: linear-gradient(to right, #0000 0%, #000f 50%, #0000 100%);
      height: 2.25em;

      animation-name: shimmer;
      animation-play-state: running;
      animation-duration: 1.5s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }

    @keyframes shimmer {
      from {
        background-position: -10em 0;
      }
      to {
        background-position: calc(100% + 10em) 0;
      }
    }
  `]
})

export class LoadingShimmerComponent
{
  @Input() columns = 1;
  @Input() rows = 3;
  @Input() loading: boolean;

  constructor() {}
}
