import {Component, Input, OnInit} from '@angular/core';
import {catchError, first, map, timeout} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {WorkflowService} from '../../workflows/workflow-service.service';
import {DmConnectApiService} from '../../dm-connect-api.service';

@Component({
  selector: 'app-test-correlation',
  template: `
    <p>
      This web form will allow you to verify the connection to your Agency Management System, and whether a particular piece of business has been previously correlated.
    </p>
    <p>
      The most common problem identified when using this form is a misconfigured username and password combination.
    </p>
    <p>
      <code>Submission Id</code> can be the value from within your Agency Management System or the unique identifier used on the corresponding Portal.
    </p>
    <ng-container *ngIf="show_message">
      <div class="alert" [class.alert-success]="!show_error" [class.alert-danger]="show_error" role="alert">
        <h5 *ngIf="!show_error && correlation_id" class="alert-heading">Connection to your AMS was validated successfully and the record is associated to value <code>{{correlation_id}}</code></h5>
        <h5 *ngIf="!show_error && !correlation_id" class="alert-heading">Connection to your AMS was validated successfully, but this record is not currently correlated.</h5>
        <h5 *ngIf="show_error" class="alert-heading">Connection to your AMS was not validated. Check that you've selected a Portal and entered a Submission Id. Otherwise check your credentials for the Agency Management System.</h5>
      </div>
    </ng-container>
    <div class="row">
      <div class="col-lg-6">
        <div class="mb-3">
          <label for="portal-id" class="form-label">Portal</label>
          <select class="form-select" name="portal-id" [(ngModel)]="portal_id">
            <option [ngValue]="null" [selected]="true">-- Select --</option>
            <option *ngFor="let i of configured_interfaces | async" value="{{ i.id }}">{{i.name}}</option>
          </select>
        </div>
      </div> <!-- end col -->
      <div class="col-lg-6">
        <div class="mb-3">
          <label for="record-id" class="form-label">Submission Id</label>
          <input type="text" id="record-id" class="form-control" name="record-id" [(ngModel)]="record_id" placeholder="021587">
        </div>
      </div> <!-- end col -->
    </div> <!-- end row-->
    <div class="row">
      <div class="col-auto">
        <button type="submit" class="btn btn-primary mb-2" (click)="getCorrelation()" [disabled]="loading_correlation">
          <ngb-panel *ngIf="loading_correlation">
            <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
            Checking Correlation...
          </ngb-panel>
          <ngb-panel *ngIf="!loading_correlation">
            Check Correlation
          </ngb-panel>
        </button>
      </div>
    </div>
  `,
  styles: [``]
})
export class TestCorrelationComponent implements OnInit {

  @Input() configured_interfaces: Subject<any[]>;

  public credentials: Observable<any[][]>;
  public portal_id: string = null;
  public record_id: string;
  public correlation_id: string;
  public loading_correlation = false;
  public show_message = false;
  public show_error = false;

  constructor(private workflowService: WorkflowService, private apiService: DmConnectApiService) { }
  ngOnInit(): void {
  }

  public getCorrelation(): void {
    this.show_message = false;
    this.loading_correlation = true;
    if (this.record_id && this.portal_id) {
      this.apiService.getStatus2(this.portal_id, this.record_id).pipe(first(), timeout(10000), map(response => {
          this.correlation_id = (response && response.success) ? response.correlation_id : null;
          this.show_message = true;
          this.show_error = !response || !response.success;
          this.loading_correlation = false;
        }),
        catchError(async () => {
          this.show_message = true;
          this.show_error = true;
          this.loading_correlation = false;
        })).subscribe();
    } else {
      this.show_error = true;
      this.show_message = true;
      this.loading_correlation = false;
    }
  }
}
