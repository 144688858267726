import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {of, Subject} from 'rxjs';
import {catchError, concatMap, first} from 'rxjs/operators';
import {SupportLocalStorageService} from './support-local-storage.service';
import {DmConnectPackageOptions} from './dm-connect-package-options';

@Component({
  selector: 'app-generate-configuration',
  template: `
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <h4 class="page-title">Generate Configuration
              <app-reset-config [packageOptions]="configSubject" (onChange)="updateConfiguration($event)"></app-reset-config>
            </h4>
          </div>
          <div *ngIf="display_message" class="card">
            <div class="card-body">
              <div class="alert alert-{{display_message.type}}" role="alert">
                <h4 class="alert-heading">{{display_message.message}}</h4>
                <hr class="my-2">
                <p class="mb-0">If the error persists please contact the development team.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xxl-12 col-lg-12">
          <app-file-uploader [packageOptions]="configSubject" (onChange)="updateConfiguration($event)"></app-file-uploader>
        </div>
        <div class="col-xxl-8 col-lg-8">
          <!-- project card -->

          <app-general-settings [packageOptions]="configSubject" (onChange)="updateConfiguration($event)"></app-general-settings>

          <app-aim-config [packageOptions]="configSubject" (onChange)="updateConfiguration($event)"></app-aim-config>

          <app-concept-one-config [packageOptions]="configSubject" (onChange)="updateConfiguration($event)"></app-concept-one-config>

          <app-nexsure-config [packageOptions]="configSubject" (onChange)="updateConfiguration($event)"></app-nexsure-config>

          <app-cors-configurator [packageOptions]="configSubject" (onChange)="updateConfiguration($event)"></app-cors-configurator>

          <app-gateway-config [packageOptions]="configSubject" (onChange)="updateConfiguration($event)"></app-gateway-config>

          <app-json-transpositions-selector [packageOptions]="configSubject" (onChange)="updateConfiguration($event)"></app-json-transpositions-selector>

          <app-sql-transpositions-selector [packageOptions]="configSubject" (onChange)="updateConfiguration($event)"></app-sql-transpositions-selector>

          <app-sql-configuration [packageOptions]="configSubject" (onChange)="updateConfiguration($event)"></app-sql-configuration>

          <app-python-sql-config [packageOptions]="configSubject" (onChange)="updateConfiguration($event)"></app-python-sql-config>

          <app-clariondoor-config [packageOptions]="configSubject" (onChange)="updateConfiguration($event)"></app-clariondoor-config>

          <app-tsga-invoice-bridge-config [packageOptions]="configSubject" (onChange)="updateConfiguration($event)"></app-tsga-invoice-bridge-config>

          <app-input-one-pbs [packageOptions]="configSubject" (onChange)="updateConfiguration($event)"></app-input-one-pbs>

        </div> <!-- end col -->

        <div class="col-lg-4 col-xxl-4">
          <app-ams-selector [packageOptions]="configSubject" (onChange)="updateConfiguration($event)"></app-ams-selector>
          <app-application-interface-selector [packageOptions]="configSubject" (onChange)="updateConfiguration($event)"></app-application-interface-selector>

          <div class="d-block">
            <div class="row g-2">
              <div class="mb-3 col-12">
                <button type="submit" [disabled]="isGenerating" class="btn btn-primary" (click)="Generate()">
                  <span [hidden]="!isGenerating">
                    <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                    Generating...
                  </span>
                  <span [hidden]="isGenerating">
                    Generate
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div> <!-- end col -->
      </div> <!-- end row -->
    </div><!-- end container -->

  `,
  styles: [``],
})

export class GenerateConfigurationComponent implements OnInit {
  public base_url = 'http://127.0.0.1:8200/interface-scripts/';
  public config = new DmConnectPackageOptions();
  public configSubject = new Subject<DmConnectPackageOptions>();
  public isGenerating = false;
  public display_message: any;

  constructor(private http: HttpClient, private local_storage: SupportLocalStorageService) {
    const storedConfiguration = this.local_storage.read();
    if (storedConfiguration) {
      this.updateConfiguration(storedConfiguration);
    }
  }

  ngOnInit(): void {
  }

  public updateConfiguration(change: any): void {
    this.config = {...this.config, ...change};
    this.configSubject.next(this.config);
    this.local_storage.save(this.config);
  }

  public Generate(): void {
    this.isGenerating = true;
    this.display_message = null;
    this.http.post(this.base_url + 'generate', this.config,
      {observe: 'response', responseType: 'blob' as 'json'}).pipe(first(), concatMap(response => {
      if (response) {
        const warningMessage = response.headers.get('Dm-Warning');
        if (warningMessage)
        {
          this.display_message = { type: 'warning', message: warningMessage };
        }
        const filename = response.headers.get('content-disposition')
          ?.split(';')[1].split('=')[1];
        const downloadLink = document.createElement('a');
        const blob = response.body as Blob;
        downloadLink.href = window.URL.createObjectURL(blob);
        if (filename) {
          downloadLink.setAttribute('download', filename);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
      } else {
        return of(false);
      }
    }), catchError(async (response: any) => {
      if (response.error) {
        this.display_message = { type: 'danger', message: response.error.error };
      }
      return false;
    })).subscribe(() => this.isGenerating = false);
  }
}

