import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {DmConnectPackageOptions} from '../dm-connect-package-options';

@Component({
  selector: 'app-python-sql-config',
  template: `
    <div class="card d-block">
      <div class="card-body">
        <h5 class="card-title mb-3">Python & SQL File Location</h5>
        <div class="input-group mb-3">
          <label class="input-group-text" for="locationProd">Production</label>
          <input type="text" class="form-control" id="locationProd" [(ngModel)]="locationProduction"
                (change)="fileLocationChanged()" placeholder="\\\\fileshare\\dm\\production\\mappings">
        </div>
        <div class="input-group mb-3">
          <label class="input-group-text" for="locationDev">Testing</label>
          <input type="text" class="form-control" id="locationDev" [(ngModel)]="locationDevelopment"
                 (change)="fileLocationChanged()" placeholder="\\\\fileshare\\dm\\testing\\mappings">
        </div>
      </div> <!-- end card-body-->
    </div> <!-- end card-->
  `,
  styles: [`
    .input-group-text { min-width: 7em }
  `]
})

export class PythonSqlConfigComponent implements OnInit {2;
  public locationProduction: string;
  public locationDevelopment: string;
  @Input() packageOptions: Subject<DmConnectPackageOptions>;
  @Output() onChange = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
    this.packageOptions.subscribe((next) => {
      this.locationProduction = next.location_production;
      this.locationDevelopment = next.location_development;
    });
  }

  fileLocationChanged(): void {
    this.onChange.emit({location_production: this.locationProduction, location_development: this.locationDevelopment});
  }
}
