import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {DmConnectPackageOptions} from '../dm-connect-package-options';

@Component({
  selector: 'app-aim-config',
  template: `
    <div *ngIf="is_aim_selected" class="card d-block">
      <div class="card-body">
        <h5 class="card-title mb-3">AIM Options</h5>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="usePropCoverage"
                 [(ngModel)]="uar_use_commercial_property_coverage" (change)="aimConfigChanged()">
          <label class="form-check-label" for="usePropCoverage"> Use Commercial Property Coverage Table</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="useGLRatingDtlTble" [(ngModel)]="uar_use_gl_rating_detail"
                 (change)="aimConfigChanged()">
          <label class="form-check-label" for="useGLRatingDtlTble"> Use GL Rating Detail Table</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="useCurrentUser" [(ngModel)]="use_current_user"
                 (change)="aimConfigChanged()">
          <label class="form-check-label" for="useCurrentUser"> Use Current User</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="allowUpdates" [(ngModel)]="allow_updates"
                 (change)="aimConfigChanged()">
          <label class="form-check-label" for="allowUpdates"> Allow Updates</label>
        </div>
        <div class="input-group mt-3">
          <label class="input-group-text" for="excludedRetailerStatusCodes">Excluded Retailer Status Codes</label>
          <input type="text" class="form-control" id="excludedRetailerStatusCodes" [(ngModel)]="excluded_retailer_status_codes"
                 placeholder="X, S, D" (change)="aimConfigChanged()">
        </div>
      </div> <!-- end card-body-->
    </div> <!--end card-->
  `,
  styles: [`
    .input-group-text {
      min-width: 8em
    }
  `]
})

export class AimConfigComponent implements OnInit {
  public uar_use_commercial_property_coverage: boolean;
  public uar_use_gl_rating_detail: boolean;
  public use_current_user: boolean;
  public allow_updates: boolean;
  public excluded_retailer_status_codes: string;
  public is_aim_selected: boolean;
  @Input() packageOptions: Subject<DmConnectPackageOptions>;
  @Output() onChange = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
    this.packageOptions.subscribe((next) => {
      this.is_aim_selected = next.ams_id === 'aim';
      this.uar_use_commercial_property_coverage = next.uar_use_commercial_property_coverage;
      this.uar_use_gl_rating_detail = next.uar_use_gl_rating_detail;
      this.use_current_user = next.use_current_user;
      this.allow_updates = next.allow_updates;
      this.excluded_retailer_status_codes = next.excluded_retailer_status_codes;
    });
  }

  public aimConfigChanged(): void {
    this.onChange.emit({
      uar_use_commercial_property_coverage: this.uar_use_commercial_property_coverage,
      uar_use_gl_rating_detail: this.uar_use_gl_rating_detail,
      use_current_user: this.use_current_user,
      allow_updates: this.allow_updates,
      excluded_retailer_status_codes: this.excluded_retailer_status_codes
    });
  }
}
