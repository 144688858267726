import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {DmConnectPackageOptions} from '../dm-connect-package-options';
import {SqlConfiguration} from '../sql-configuration';
import {OnPackageOptionChange} from './on-package-option-change';

@Component({
  selector: 'app-sql-transpositions-selector',
  template: `
    <div *ngIf="this.sqlTranspositionsObject" class="card d-block">
      <div class="card-body">
        <div class="col-12" id="contextSQL">
          <h5 class="card-title mb-3">SQL Transpositions Configuration</h5>
          <div class="mb-3">
            <div class="form-floating">
              <select class="form-select" id="defaultLibSql" aria-label="Select Default Library" (change)="onSqlTranspositionChange()"
                      [(ngModel)]="sqlTranspositionsObject.connection_string">
                <option *ngFor="let item of sqlConfigurationArray" value="{{item.key}}">{{item.key}}</option>
              </select>
              <label for="defaultLibSql">Select the SQL Connection</label>
            </div>
          </div>
          <div class="input-group mb-3">
            <label class="input-group-text" for="defaultlibrary">Default Library</label>
            <input type="text" class="form-control" id="defaultlibrary" [(ngModel)]="sqlTranspositionsObject.default_library" (change)="onSqlTranspositionChange()">
          </div>
        </div>
      </div> <!-- end card-body-->
    </div> <!-- end card-->
  `,
  styles: [``]
})

export class SqlTranspositionConfiguratorComponent implements OnInit , OnPackageOptionChange {

  @Input() packageOptions: Subject<DmConnectPackageOptions>;
  @Output() onChange = new EventEmitter<any>();
  public sqlConfigurationArray = new Array<SqlConfiguration>();
  public sqlTranspositionsObject;

  ngOnInit(): void {
    this.packageOptions.subscribe((x) => {
      this.sqlConfigurationArray = x.sql_configuration;
      this.sqlTranspositionsObject = x.sql_transpositions;
    });

  }

  public onSqlTranspositionChange(): void{
    this.onChange.emit({ sql_transpositions: this.sqlTranspositionsObject });
  }

}
