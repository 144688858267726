import {Component, OnInit, OnDestroy} from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-support-control-master',
  templateUrl: 'support-control-master.component.html' ,
  styles: [`
    .content-page {
      padding: 0 12px 65px;
      min-width: 400px;
    }
  `]
})
export class SupportControlMasterComponent implements OnInit, OnDestroy {
  public readonly this_year: number;
  private readonly destroying$ = new Subject<void>();

  constructor(private broadcast_service: MsalBroadcastService) {
    this.this_year = new Date().getFullYear();
  }

  ngOnInit(): void {
    this.broadcast_service.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.destroying$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }
}
