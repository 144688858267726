import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {AuthGuard} from '@auth0/auth0-angular';
import {LocalCredentialsComponent} from './local/local-credentials.component';
import {AimClarionDoorComponent} from './aim/aim-clarion-door.component';
import {InitializingComponent} from './initializing/initializing.component';
import {DmbridgeAuthorizeComponent} from './dmbridge/dmbridge-authorize.component';
import {TsgaAimLandingComponent} from './aim/tsga-aim-landing.component';
import {HazardhubAimLandingComponent} from './aim/hazardhub-aim-landing.component';
import {RoadblockComponent} from './portals/roadblock.component';
import {PluginComponent} from './portals/plugin.component';
import {PreflightCheckComponent} from './portals/preflight-check.component';
import {ClearanceComponent} from './portals/clearance.component';
import {NewSubmissionComponent} from './portals/new-submission.component';
import {SuccessComponent} from './portals/success.component';
import {LocalMasterComponent} from './local/local-master/local-master.component';
import {DmBridgeMasterComponent} from './dmbridge/dmbridge-master/dmbridge-master.component';
import {ConfigMasterComponent} from './config/config-master/config-master.component';
import {ConfigHomeComponent} from './config/config-home.component';
import {UserManagementComponent} from './config/user-management.component';
import {DataLibraryComponent} from './config/data-library.component';
import {ConnectorsConfigurationComponent} from './config/connectors-configuration.component';
import {InterfacesConfigurationComponent} from './config/interfaces-configuration.component';
import {WorkflowsConfigurationComponent} from './config/workflows-configuration.component';
import {CreateWorkflowComponent} from './config/create-workflow.component';
import {StagingBucketListComponent} from './config/staging-bucket-list.component';
import {CreateStagingBucketComponent} from './config/create-staging-bucket.component';
import {SLTComponent} from './config/slt.component';
import {AimMasterComponent} from './aim/aim-master/aim-master.component';
import {AimSuccessComponent} from './aim/aim-success.component';
import {ErrorComponent} from './config/error.component';
import {EnsureUserAuthGuard} from './security/EnsureUserAuthGuard';
import {LuidGuard} from './security/LuidGuard';
import {SelectSubmissionComponent} from './aim/select-submission.component';
import {DmbridgeAimLandingComponent} from './aim/dmbridge-aim-landing.component';
import {AmsLandingComponent} from './aim/ams-landing.component';
import {ApplicationInterfaceDetailsComponent} from './config/application-interface-details.component';
import {FunctionDetailsComponent} from './config/function-details.component';
import {TranspositionMappingsComponent} from './config/transposition-mappings.component';
import {IdRoadblockComponent} from './portals/id-roadblock.component';
import {InterfaceCostingComponent} from './config/interface-costing.component';
import {InterfaceAssignmentComponent} from './config/interface-assignment.component';
import {TestLauncherComponent} from './support-control/test-launcher/test-launcher.component';
import {LicensingComponent} from './support-control/licensing.component';
import {
  SupportControlMasterComponent
} from './support-control/support-control-master/support-control-master.component';
import {GenerateConfigurationComponent} from './support-control/generate-configuration.component';
import {ExtensionLauncherComponent} from './support-control/test-launcher/extension-launcher.component';
import {TranspositionEditorComponent} from './support-control/packaging/transpositions/transposition-editor';
import {DiagnosticsComponent} from './local/diagnostics.component';
import {FtpPlProcessorComponent} from './local/ftp-pl-processor.component';
import {BatchProcessorComponent} from './local/batch-processor.component';
import {DashboardComponent} from './local/analytics/dashboard.component';
import {MsalGuard} from '@azure/msal-angular';
import {CustomerComponent} from './support-control/customer.component';
import {TranspositionTopicsComponent} from './local/transposition_topics.component';
import {CanEditTranspositionGuard} from './security/CanEditTranspositionGuard';

export const routes: Routes = [
  {
    path: 'aim', component: AimMasterComponent,
    canActivate: [LuidGuard],
    canActivateChild: [LuidGuard],
    children: [
      { path: 'roadblock', component: RoadblockComponent, data: { is_error : true } },
      { path: 'success', component: AimSuccessComponent, data: { is_success : true } },
      { path: 'clarion_door', component: AimClarionDoorComponent },
      { path: 'tsga_landing', component: TsgaAimLandingComponent },
      { path: 'hazardhub_landing', component: HazardhubAimLandingComponent },
      { path: 'select_submission', component: SelectSubmissionComponent },
      { path: 'dmbridge_aim_landing', component: DmbridgeAimLandingComponent }
    ]
  },
  {
    path: 'ams', component: AimMasterComponent,
    canActivate: [LuidGuard],
    canActivateChild: [LuidGuard],
    children: [
      { path: 'roadblock', component: RoadblockComponent, data: { is_error : true } },
      { path: 'success', component: AimSuccessComponent, data: { is_success : true } },
      { path: 'landing', component: AmsLandingComponent }
    ]
  },
  { path: 'initializing', component: InitializingComponent, canActivate: [LuidGuard]},
  {
    path: 'plugins', component: PluginComponent,
    canActivate: [LuidGuard],
    canActivateChild: [LuidGuard],
    children: [
      { path: 'roadblock', component: RoadblockComponent, data: { is_error: true } },
      { path: 'success', component: SuccessComponent,  data: { is_success: true }  },
      { path: 'preflight', component: PreflightCheckComponent },
      { path: 'clearance', component: ClearanceComponent },
      { path: 'new-submission', component: NewSubmissionComponent },
      { path: 'id-roadblock', component: IdRoadblockComponent },
      { path: 'initializing', component: InitializingComponent }
    ]
  },
  {
    path: 'local', component: LocalMasterComponent,
    canActivate: [LuidGuard, CanEditTranspositionGuard],
    canActivateChild: [LuidGuard, CanEditTranspositionGuard],
    children: [
      { path: 'about', component: DiagnosticsComponent,  data: { auto_load_version: true } },
      { path: 'credentials', component: LocalCredentialsComponent },
      { path: 'ftp_processor', component: FtpPlProcessorComponent },
      { path: 'batch_processor/:workflow_name', component: BatchProcessorComponent },
      { path: 'analytics', component: DashboardComponent },
      { path: 'transpositions', component: TranspositionTopicsComponent }
    ]
  },
  {
    path: 'config',
    component: ConfigMasterComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'home', component: ConfigHomeComponent },
      { path: 'users', component: UserManagementComponent },
      { path: 'data', component: DataLibraryComponent },
      { path: 'connectors', component: ConnectorsConfigurationComponent },
      { path: 'interfaces', component: InterfacesConfigurationComponent },
      { path: 'workflows', component: WorkflowsConfigurationComponent },
      { path: 'workflows/create', component: CreateWorkflowComponent },
      { path: 'batch-management', component: StagingBucketListComponent },
      { path: 'batch-management/create', component: CreateStagingBucketComponent },
      { path: 'batch-management/slt', component: SLTComponent },
      { path: 'error/403', component: ErrorComponent},
      { path: 'interface-details/:id', component: ApplicationInterfaceDetailsComponent},
      { path: 'function-details/:id', component: FunctionDetailsComponent },
      { path: 'transposition-mappings', component: TranspositionMappingsComponent },
      { path: 'cost', component: InterfaceCostingComponent },
      { path: 'manage-access/:id', component: InterfaceAssignmentComponent}
    ]
  },
  {
    path: 'support-control-panel', component: SupportControlMasterComponent,
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard],
    children: [
      { path: 'licensing', component: LicensingComponent },
      { path: 'customer', component: CustomerComponent },
      { path: 'generate-configuration', component: GenerateConfigurationComponent },
      { path: 'transposition-editor', component: TranspositionEditorComponent},
      { path: 'test-launcher', component: TestLauncherComponent},
      { path: '**', redirectTo: 'licensing', pathMatch: 'full' }
    ]
  },
  { path: 'dmbridge/authorize', component: DmbridgeAuthorizeComponent, canActivate: [EnsureUserAuthGuard] },
  {
    path: 'dmbridge2', component: DmBridgeMasterComponent,
    children: []
  },
  { path: 'glp/Quote.php', component: ExtensionLauncherComponent },
  { path: 'innovation', component: ExtensionLauncherComponent },
  { path: 'WWIP/QuoteDetailNew.aspx', component: ExtensionLauncherComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
