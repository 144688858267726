import {Component, OnInit} from '@angular/core';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, concatMap, first, mergeMap, switchMap, toArray} from 'rxjs/operators';
import {ODataSearchResponse} from '../ODataSearchResponse';
import {Router} from '@angular/router';

@Component({
  selector: 'app-support-control-panel',
  template: `
      <div class="container-fluid">
          <!-- start page title -->
          <div class="row">
              <div class="col-12">
                  <div class="page-title-box">
                      <h4 class="page-title">DmConnect Licensing</h4>
                  </div>
              </div>
          </div>
          <!-- end page title -->
          <div class="row">
              <div class="col-12">
                  <div class="card">
                      <div class="card-body">
                          <div *ngIf="this.exception != null" class="alert alert-danger" role="alert">
                              <h4 class="alert-heading">{{ this.exception }}</h4>
                              <hr class="my-2">
                              <p class="mb-0">
                                  If the error persists please contact us at
                                  <a class="text-danger" href="mailto:{{SUPPORT_EMAIL}}">{{ SUPPORT_EMAIL }}</a>.
                              </p>
                          </div>

                          <div class="table-responsive">
                              <table class="table table-centered w-100 dt-responsive nowrap">
                                  <thead class="table-light">
                                  <tr>
                                      <th>Customer</th>
                                      <th>Client Token</th>
                                      <th>Created On</th>
                                      <th>Expires On</th>
                                      <th>Status</th>
                                      <th></th>
                                  </tr>
                                  </thead>
                                  <tbody app-loading-shimmer [loading]="this.loading_list" [columns]="5">
                                  <tr *ngFor="let item of this.customers | async">
                                      <td>{{ item.name }}</td>
                                      <td>{{ item.client_token }}</td>
                                      <td *ngIf="item.license === undefined; else elseLicense" colspan="3">
                                          <table class="w-100">
                                              <tbody app-loading-shimmer [loading]="true" [rows]="1"></tbody>
                                          </table>
                                      </td>
                                      <ng-template #elseLicense>
                                          <td>{{item.license.starts_at | date}}</td>
                                          <td>{{item.license.expires_at | date}}</td>
                                          <td>
                                              <span *ngIf="item.license.status == 'active'" class="badge bg-success">{{item.license.status}}</span>
                                              <span *ngIf="item.license.status == 'expiring'" class="badge bg-warning">{{item.license.status}}</span>
                                              <span *ngIf="item.license.status == 'expired'" class="badge bg-danger">{{item.license.status}}</span>
                                          </td>
                                      </ng-template>
                                      <td class="table-action" style="width: 90px;">
                                          <div class="dropdown d-inline" *ngIf="item.profiles?.length > 0">
                                              <a href="#" class="dropdown-toggle arrow-none card-drop action-icon" data-bs-toggle="dropdown">
                                                  <i class="mdi mdi-square-edit-outline"></i>
                                              </a>

                                              <div class="dropdown-menu dropdown-menu-end">
                                                  <ng-container *ngFor="let profile of item.profiles">
                                                      <span class="dropdown-item fw-bold">{{ profile.name }}</span>
                                                      <ng-container *ngFor="let environment of profile.environments">
                                                          <a (click)="onSelectProfile(item, profile)" href="javascript: void(0);" class="dropdown-item">&nbsp;&nbsp;{{ environment.name }}</a>
                                                      </ng-container>
                                                  </ng-container>
                                                  <div *ngIf="item.profiles?.length > 0" class="dropdown-divider"></div>
                                                  <a class="dropdown-item text-success" data-bs-toggle="modal" data-bs-target="#add-profile-modal" (click)="onOpenProfileModal(item)" href="javascript: void(0);">
                                                      <i class="mdi mdi-plus"></i>Add
                                                  </a>
                                              </div>
                                          </div>
                                      </td>
                                  </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div id="add-profile-modal" class="modal fade">
              <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                      <div class="modal-header py-3 px-4 border-bottom-0">
                          <h4 class="modal-title">Add Customer Profile</h4>
                          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                      </div>
                      <form class="ps-3 pe-3">
                          <div class="modal-body">
                              <div class="row mb-3">
                                  <label class="form-label" for="profile-name">Name</label>
                                  <div class="input-group">
                                      <input type="text" class="form-control" id="profile-name" name="profile-name" [(ngModel)]="profile_state.name">
                                  </div>
                              </div>
                              <div class="row mb-3">
                                  <div class="form-floating w-100">
                                      <select class="form-select" name="profile-stereotype" [(ngModel)]="profile_state.stereotype">
                                          <option value="client">Client</option>
                                          <option value="server">Server</option>
                                          <option value="replay">Replay Client</option>
                                      </select>
                                      <label class="ps-3">Stereotype</label>
                                  </div>
                              </div>
                          </div>
                          <div class="modal-footer mb-3">
                              <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" (click)="onAddProfile()">Add</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  `,
  styles: [``]
})

export class CustomerComponent implements OnInit {
  // @ts-ignore
  public readonly CUSTOMER_URL = Dmc.SUPPORT_ADDRESS + 'customer';
  // @ts-ignore
  public readonly LICENSE_URL = Dmc.SUPPORT_ADDRESS + 'licensing';
  public readonly SUPPORT_EMAIL = 'Support@DarkMatterIns.com';
  public customers: Observable<DmConnectCustomer[]>;
  public loading_list = true;
  public exception: string;
  public profile_state = new ProfileState();

  constructor(private googleAnalytics: GoogleAnalyticsService, private http: HttpClient, private router: Router) {}

  ngOnInit(): void {
    this.loadCustomers();
  }

  loadCustomers(): void {
    this.loading_list = true;
    this.customers = this.http.get<ODataSearchResponse<DmConnectCustomer>>(this.CUSTOMER_URL)
      .pipe(first(), mergeMap(response => {
          if (response) {
            this.loading_list = false;
            return of(...response.items);
          }
        }), concatMap(customer => {
          if (customer.profiles.length > 0) {
            customer.profiles = []; // hiding flyout
            return this.loadEnvironments(customer);
          }
          return of(customer);
        }), toArray(), switchMap(customers => {
          return this.loadLicenses(customers);
        }), catchError(() => {
          this.loading_list = false;
          this.exception = 'An error occurred while fetching customers.';
          return of([]);
        })
      );
  }

  loadEnvironments(customer: DmConnectCustomer): Observable<DmConnectCustomer> {
    return this.http.get<ODataSearchResponse<DmConnectProfile>>(this.CUSTOMER_URL + `/${customer.id}/profile`)
      .pipe(first(), mergeMap(response => {
        if (response) {
          customer.profiles = response.items;
          return of(customer);
        }
      }), catchError(() => {
        this.exception = 'An error occurred while fetching environments.';
        return of(null);
      }));
  }

  loadLicenses(customers: DmConnectCustomer[]): Observable<DmConnectCustomer[]> {
    return this.http.get<ODataSearchResponse<DmConnectLicense>>(this.LICENSE_URL)
      .pipe(first(), mergeMap(response => {
        if (response) {
          for (const license of response.items) {
            for (const customer of customers) {
              if (customer.client_token === license.client_token) {
                customer.license = license;
              }
            }
          }
          return of(customers);
        }
      }), catchError(() => {
        this.exception = 'An error occurred while fetching licenses.';
        return of([]);
      })
    );
  }

  onOpenProfileModal(customer: DmConnectCustomer): void {
    this.googleAnalytics.event('new_profile_clicked', 'support_control', 'Opened Customer Profile Modal');
    this.profile_state.customer = customer;
    this.profile_state.name = '';
    this.profile_state.stereotype = 'client';
  }

  onAddProfile(): void {
    this.googleAnalytics.event('add_profile_clicked', 'support_control', 'Clicked Add Profile');

    const customer = this.profile_state.customer;
    if (!customer.profiles) {
      customer.profiles = [];
    }

    const body = {
      name: this.profile_state.name,
      stereotype: this.profile_state.stereotype,
      id: this.profile_state.customer.id
    };
    this.http.post<any>(this.CUSTOMER_URL + `/${this.profile_state.customer.id}/profile`, body, {observe: 'response', responseType: 'json'})
      .pipe(first(), concatMap(() => of(true)), catchError(() => of(false)))
      .subscribe(success => {
        if (!success) {
          this.exception = 'An error occurred while creating profile.';
        } else {
          this.exception = null;
          customer.profiles.push({
            name: this.profile_state.name,
            stereotype: this.profile_state.stereotype
          });
        }
      });
  }

  onSelectProfile(customer: DmConnectCustomer, profile: DmConnectProfile): void {
    this.router.navigate(['/support-control-panel/generate-configuration']);
  }
}


class DmConnectCustomer {
  public id: number;
  public name: string;
  public client_token: string;
  public profiles: DmConnectProfile[];
  public license: DmConnectLicense;
}

class DmConnectProfile {
  public name: string;
  public stereotype: string;
  public environments?: DmConnectEnvironment[];
}

class DmConnectEnvironment {
  public name: string;
}

class DmConnectLicense {
  public client_token: string;
  public starts_at: Date;
  public expires_at: Date;
  public status: string;
}

class ProfileState {
  public customer: DmConnectCustomer;
  public name: string;
  public stereotype: string;
}
