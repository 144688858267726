import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {ConfigurationOption} from '../configuration-option';
import {HttpClient} from '@angular/common/http';
import {ODataSearchResponse} from '../../ODataSearchResponse';
import {catchError, mergeMap} from 'rxjs/operators';
import {DmConnectPackageOptions} from '../dm-connect-package-options';

@Component({
  selector: 'app-application-interface-selector',
  template: `
    <div class="card">
      <div class="card-body">
        <h5 class="card-title mb-3">Application Interfaces</h5>
        <div class="form-check mb-2" *ngFor="let appInterface of appInterfaces | async">
          <input class="form-check-input" type="checkbox" value="{{ appInterface.id }}"
                 [checked]="selectedInterfaces.indexOf(appInterface.id) !== -1"
                 id="chbox{{ appInterface.id }}"
                 (click)="onInterfaceChkBoxClick(appInterface.id, $any($event.target).checked)">
          <label class="form-check-label" for="chbox{{ appInterface.id }}">{{ appInterface.name }}</label>
        </div>
      </div> <!-- end card body -->
    </div> <!-- end card -->
  `,
  styles: [``]
})

export class ApplicationInterfaceSelectorComponent implements OnInit {
  // @ts-ignore
  private readonly SERVICE_URL = Dmc.SUPPORT_ADDRESS + 'interface-scripts/application-interface';
  public appInterfaces = new Observable<ConfigurationOption[]>();
  public isLoading = true;
  public selectedInterfaces = new Array<string>();
  @Input() packageOptions: Subject<DmConnectPackageOptions>;
  @Output() onChange = new EventEmitter<any>();

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.appInterfaces = this.getApplicationInterfaces();
    this.packageOptions.subscribe((next) => {
      this.selectedInterfaces = next.application_interface_ids;
    });
  }

  public getApplicationInterfaces(): Observable<ConfigurationOption[]> {
    this.isLoading = true;
    return this.http.get<ODataSearchResponse<ConfigurationOption>>(this.SERVICE_URL).pipe(mergeMap(response => {
      if (response) {
        this.isLoading = false;
        return of(response.items);
      }
    }), catchError(() => {
      return of([]);
    }));
  }

  public onInterfaceChkBoxClick(id: string, checked: boolean): void {
    if (checked) {
      this.selectedInterfaces.push(id);
    } else {
      if (this.selectedInterfaces.length > 0) {
        this.selectedInterfaces.forEach((element, index) => {
          if (element === id) {
            this.selectedInterfaces.splice(index, 1);
          }
        });
      }
    }
    this.onChange.emit({application_interface_ids: this.selectedInterfaces});
  }
}
