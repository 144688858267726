import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {mergeMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {FeaturesService} from '../features.service';
import {HateoasRoutesService} from '../hateoas-routes.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Component({
  selector: 'app-application-interface-details',
  template: `


    <div *ngIf="(application_interface|async) || {} ; let interfaceDetails" class="container-fluid">

      <ng-container *ngIf="!loading_interface">
        <!-- start page title -->
        <div class="row">
          <div class="col-12">
            <div class="page-title-box">
              <h4 class="page-title">{{interfaceDetails.name}}</h4>
            </div>
          </div>
        </div>
        <!-- end page title -->


        <div class="row">
          <div class="col-xxl-8 col-lg-6">
            <!-- project card -->
            <div class="card d-block">
              <div class="card-body">
                {{interfaceDetails.description}}
              </div> <!-- end card-body-->
            </div> <!-- end card-->
          </div> <!-- end col -->


          <div class="col-lg-6 col-xxl-4">
            <div class="text-lg-right">
              <button type="button" class="btn btn-primary mb-2 mr-2" (click)="OnClickManageAccess(interfaceDetails.id)"><i class="mdi mdi-basket mr-1"></i> Manage Access </button>
            </div>
            <div class="card">
              <div class="card-body">
                <h5 class="card-title mb-3">Assets</h5>

                <div *ngFor="let asset of interfaceDetails.assets" class="card mb-1 shadow-none border">
                  <div class="p-2">
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <img src="assets/images/projects/icon-file.png" class="avatar-sm rounded" alt="file-image"/>
                      </div>
                      <div class="col ps-0">
                        <a href="javascript:void(0);" class="text-muted fw-bold">{{asset.slug}}</a>
                      </div>
                      <div class="col-auto">
                        <!-- Button -->
                        <a href="javascript:void(0);" class="btn btn-link btn-lg text-muted">
                          <i class="dripicons-download"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

              </div> <!-- end card body -->
            </div> <!-- end card -->
          </div> <!-- end col -->
        </div> <!-- end row -->


        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-centered table-nowrap table-hover mb-0">
                    <tbody>
                    <tr *ngFor="let func of interfaceDetails.functions">
                      <td>
                        <h5 class="font-14 my-1"><a href="javascript:void(0);" class="text-body">{{func.name}}</a></h5>
                      </td>
                      <td>
                        <span class="text-muted font-13">Status</span> <br/>
                        <span class="badge badge-warning-lighten">Pending</span>
                      </td>
                      <td>
                        <span class="text-muted font-13">Last modified at</span>
                        <h5 class="font-14 mt-1 fw-normal">{{func.workflow_definition.modified_at | date:'MMM d, y, h:mm a'}}</h5>
                      </td>
                      <td class="table-action" style="width: 90px;">
                        <a href="javascript: void(0);" class="action-icon" (click)="onEditClick(func.id)"> <i class="mdi mdi-pencil"></i></a>
                        <a href="javascript: void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div> <!-- end table-responsive-->
              </div> <!-- end card body-->
            </div> <!-- end card -->
          </div> <!-- end col -->
        </div><!--end row-->
      </ng-container>

      <ng-container *ngIf="loading_interface">

        <div class="row">
          <div class= "shine card d-block card-body min-h-305px overflow-hidden">&nbsp;</div>
          <a class="shine list-group-item list-group-item-action w-100 text-nowrap overflow-hidden">
            <i class="uil-books font-18 align-middle me-2 visually-hidden"></i>
          </a>
          <a class="shine list-group-item list-group-item-action w-100 text-nowrap overflow-hidden">
            <i class="uil-books font-18 align-middle me-2 visually-hidden"></i>
          </a>
          <a class="shine list-group-item list-group-item-action w-100 text-nowrap overflow-hidden">
            <i class="uil-books font-18 align-middle me-2 visually-hidden"></i>
          </a>
        </div>
      </ng-container>
    </div><!-- end container-->

  `,
  styles: [`
    .min-h-305px {
      min-height: 305px;
    }
    .shine {
      background-image: linear-gradient(to right, #f1f3fa00 0%, #dfe3f0 50%, #f1f3fa00 100%);
      background-repeat: no-repeat;
      background-size: 40% 100%;
      display: inline-block;
      position: relative;

      animation-play-state: running;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: shimmer;
      animation-timing-function: linear;
    }

    @keyframes shimmer {
      0% {
        background-position: -100% 0;
      }
      100% {
        background-position: 200% 0;
      }
    }
  `]
})
export class ApplicationInterfaceDetailsComponent implements OnInit {
  private id: number;
  public loading_interface = false;
  public application_interface: Observable<ApplicationInterface>;

  constructor(private route: ActivatedRoute,
              private http: HttpClient,
              private router: Router,
              private features: FeaturesService,
              private hateoas: HateoasRoutesService,
              private googleAnalytics: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params.id;
    });
    this.getApplicationInterfaceById(this.id);
  }

  public getApplicationInterfaceById(id: number): void {
    this.loading_interface = true;
    const h_read_interface = this.hateoas.load('h_read_interface', Number(id));
    if (h_read_interface != null){
      this.application_interface = this.http.get<ApplicationInterface>(h_read_interface,
        {headers: {'X-DM-Authorize': 'management_api'}}).pipe(mergeMap(response => {
          this.loading_interface = false;
          return of(response);
      }));
    }
    else {
      this.router.navigate(['/config/error/403']);
    }
  }
  public onEditClick(id: number): void {
    this.googleAnalytics.event('clicked_edit', 'dmbridge_application_interface_detail', 'Clicked Edit Interface Function');
    this.router.navigate(['/config/function-details', id]);
  }
  public OnClickManageAccess(id: number): void {
    this.googleAnalytics.event('clicked_manage_access', 'dmbridge_application_interface_detail', 'Clicked Manage Access Function');
    this.router.navigate(['/config/manage-access', id]);
  }

}

class ApplicationInterface {
  public id: number;
  public name: string;
  public description: string;
  public functions: Array<ApplicationInterfaceFunction>;
  public assets: Array<Asset>;
}

class ApplicationInterfaceFunction {
  public id: number;
  public name: string;
  public application_interface_id: number;
  public workflow_definition_id: number;
  public workflow_definition: WorkflowDefinition;
}

class Asset {
  public id: number;
  public content_type: string;
  public slug: string;
  public application_interface_id: number;
}

class WorkflowDefinition {
  public id: number;
  public qualifying_expression: string;
  public workflow_reference_id: string;
  public modified_at: Date;
}
