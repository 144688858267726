import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-test-launcher',
  template: `
    <div class="container-fluid">
      <div class="wrapper">
        <div class="content">
          <div class="page-title-box">
            <h2 class="page-title">&nbsp;</h2>
          </div>
          <div class="col-xl-12 col-lg-12">
            <div class="row">
              <div class="offset-lg-2 col-lg-8 col-sm-10">
                <div class="card widget-flat">
                  <div class="card-body">
                    <h2 class="header-title mb-4">Extension Launcher ({{this.portal_id}})</h2>
                    <div *ngIf="this.exception_occurred" class="alert alert-danger" role="alert">
                      <h4 class="alert-heading">Connection error</h4>
                      <hr class="my-2">
                      <p class="mb-0">An error occurred while connecting to the DmConnect server.</p>
                    </div>
                    <div class="col-8 mb-4">
                      <div class="input-group">
                        <input type="text" class="form-control" placeholder="Record ID" [(ngModel)]="this.record_id">
                        <button class="btn btn-info" type="button" (click)="this.generate_id()">Generate ID</button>
                      </div>
                    </div>

                    <ng-container [ngSwitch]="this.portal_id">
                      <div class="row d-none" *ngSwitchCase="'nationwide_ec2'">
                        <span class="is-active">
                          <a id="Wizard_CompleteQuote"></a>
                        </span>
                        <div id="QuoteAppSummary_QuoteAppNumber">{{this.record_id}}</div>
                      </div>

                      <div class="row d-none" *ngSwitchCase="'western_world_portal'">
                        <span id="ctl00_cphMst_lblQteNo2">{{this.record_id}}</span>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [``]
})
export class ExtensionLauncherComponent implements OnInit {
  public record_id: string;
  public exception_occurred = false;
  public portal_id: string;

  public ategrity_trigger = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.generate_id();
    this.route.queryParams.subscribe(params => {
      this.portal_id = params.portal_id;
    });
  }

  generate_id(length: number = 10): void {
    this.record_id = Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1).toUpperCase();
  }
}
