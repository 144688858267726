import {TransposableValue} from './transposable-value';

export class EditableTransposableValue {
  constructor(public left_value: string, public right_value: string, private category_direction_left: boolean,
              public underlying_transposition: TransposableValue) {
  }

  get is_left_editable(): boolean {
    return this.category_direction_left || !this.underlying_transposition;
  }

  get is_right_editable(): boolean {
    return !this.category_direction_left || !this.underlying_transposition;
  }

  get is_new(): boolean {
    return !this.underlying_transposition;
  }

  public toTransposableValue(): TransposableValue {
    const output = this.underlying_transposition ?? new TransposableValue(this.left_value, this.right_value, this.category_direction_left);
    output.left_value = this.left_value;
    output.right_value = this.right_value;

    return output;
  }
}
