import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {WorkflowService} from '../../../workflows/workflow-service.service';
import {Chart, ChartType} from 'chart.js';
import {first, map, retry} from 'rxjs/operators';

@Component({
  selector: 'app-aggregate-interface',
  template: `
    <div class="card">
      <div class="card-body">
        <h4 class="header-title mb-4">Submissions by Interface</h4>
        <div class="mt-3 chartjs-chart">
          <canvas id="aggregate-interface">{{ chart }}</canvas>
        </div>
      </div>
    </div>
  `,
  styles: [``]
})

export class AggregateInterfaceComponent implements OnChanges
{
  @Input() date_range: string;
  @Input() available_interfaces: any;
  @Output() display_error = new EventEmitter<boolean>();
  public chart: Chart;

  constructor(private workflow_service: WorkflowService) {}

  ngOnChanges(): void
  {
    this.workflow_service.execute('ams_analytics', {report_type: 'aggregate_interface', date_range: this.date_range})
      .pipe(first(), retry(3), map((r) => {
        if (r.output.items) {
          this.populateChart(r.output.items);
          this.display_error.emit(false);
        }
      })).subscribe();
  }

  populateChart(items: any): void
  {
    const interfaces = [];
    const counts = [];
    for (const item of items) {
      interfaces.push(this.available_interfaces[item.label] ?? item.label);
      counts.push(item.value);
    }

    this.chart?.destroy();
    this.chart = new Chart('aggregate-interface', {
      type: 'pie' as ChartType,
      data: {
        labels: interfaces,
        datasets: [{
          data: counts
        }]
      }
    });
  }
}
