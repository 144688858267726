import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkflowService} from '../workflows/workflow-service.service';
import {DmconnectBrowserService} from '../dmconnect-browser.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Component({
  selector: 'app-aim-screens',
  template: `
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="card-group">
            <div class="card" (click)="onClickHazardHub()">
              <div class="aim-progress-overlay" *ngIf="isWorking">
                <div class="aim-progress-text">Working...</div>
              </div>
              <div class="card-img-top p-2">
                  <img class="hazardhub-img" src="/assets/images/brands/hazardhub.png" alt="Hazard Hub">
              </div>
              <div class="card-body p-2">
                <a class="btn stretched-link">
                  <h5 class="card-title">Load Property Data</h5>
                </a>
              </div>
              <div class="card-footer bg-primary text-light">
                Load
              </div>
            </div>
            <div class="card" (click)="onClickCdMgaHub()">
              <div class="card-img-top p-2">
                <img class="mx-auto w-75 d-block" src="/assets/images/brands/clarion-door.png" alt="ClarionDoor MGA Hub"/>
              </div>
              <div class="card-body p-2">
                <a class="btn stretched-link">
                  <h5 class="card-title">Generate Quote Indication</h5>
                </a>
              </div>
              <div class="card-footer bg-primary text-light">
                Rate
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .card-img-top {
      height: 127px;
      text-align: center;
    }

    .card-img-top img {
      max-width: 165px;
      max-height: 123px;
    }

    .card-group .card {
      margin: 1em;
    }

    .aim-progress-overlay {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 24px;
      font-weight: 600;
    }

    .hazardhub-img {
      height: 48px;
      margin: 50px auto auto;
    }
  `]
})
export class HazardhubAimLandingComponent implements OnInit {
  public isWorking: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private workflowService: WorkflowService,
              private dmConnectBrowser: DmconnectBrowserService,
              private googleAnalytics: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
    this.dmConnectBrowser.resize(825, 455);
  }

  onClickCdMgaHub(): void {
    this.googleAnalytics.event('clicked_clarion_door', 'hazardhub_product_landing', 'Clicked MGAHub option');
    this.router.navigate(['/aim/clarion_door'], { queryParams: this.route.snapshot.queryParams });
  }

  onClickHazardHub(): void {
    this.googleAnalytics.event('clicked_hazardhub', 'hazardhub_product_landing', 'Clicked HazardHub option');
    if (this.isWorking) { return; }
    this.isWorking = true;
    this.workflowService.execute('hazardhub', { ...this.route.snapshot.queryParams }).subscribe((r) => {
      this.isWorking = false;
    });
  }
}
