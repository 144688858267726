import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DmVersion} from '../../DmVersion';
import {DmConnectApiService, StatusApiResult} from '../../dm-connect-api.service';
import {first, map, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {PluginLocalStorageService} from '../../plugin-local-storage.service';

@Component({
  selector: 'app-local-master',
  templateUrl: './local-master.component.html',
  styles: [`
    .topnav {
      background-color: #fff;
    }

    .text-right {
      text-align: right;
    }
  `]
})
export class LocalMasterComponent implements OnInit {
  public readonly current_year: number;
  public readonly ui_version = DmVersion.version;
  public desktop_version: string = null;
  public show_transpositions: boolean;

  constructor(private route: ActivatedRoute, private router: Router, private apiService: DmConnectApiService, private localStorage: PluginLocalStorageService) {
    const routeData = route.snapshot;
    const query_string_version = routeData.queryParamMap.get('version');

    if (query_string_version) {
      this.desktop_version = query_string_version;
    }

    this.current_year = new Date().getFullYear();

  }

  ngOnInit(): void {
    this.registerEvents();
    this.show_transpositions = this.localStorage.readTranspositionData()?.can_edit === 1;
  }

  public loadDesktopVersion(): void {
    this.loadDesktopVersionAsync().subscribe();
  }

  private loadDesktopVersionAsync(): Observable<StatusApiResult> {
    return this.apiService.getStatus2(null, null).pipe(first(), map(r => {
      this.desktop_version = r.desktop_version ?? this.desktop_version;
      return r;
    }));
  }

  private registerEvents(): void {
    this.route.url.pipe(first(), switchMap(() => {
      return this.checkRouteData(this.route.firstChild.snapshot.data);
    })).subscribe();

    this.router.events.pipe(switchMap(v => {
      if (v instanceof NavigationEnd) {
        return this.checkRouteData(this.route.firstChild.snapshot.data);
      }

      return of();
    })).subscribe();
  }

  private checkRouteData(d): Observable<unknown> | Observable<StatusApiResult> {
    if (d?.auto_load_version) {
      return this.loadDesktopVersionAsync();
    }

    return of();
  }
}
