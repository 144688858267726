import {Component, OnInit} from '@angular/core';
import {catchError, concatMap, first, map, mergeMap, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {from, Observable, of} from 'rxjs';
import {FeaturesService} from '../features.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Component({
  selector: 'app-interface-costing',
  template: `
    <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Interface # {{this.interface_id}}</h4>
        </div>
      </div>
    </div>
      <div class="row">
        <div class="col-4">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <h4>Divisions</h4>
                </div>
                <div class="col-6">
                  <div class="text-lg-right">
                    <button type="button" class="btn btn-primary mb-2 mr-2" data-bs-toggle="modal" data-bs-target="#division-modal" (click)="this.onAddDivisionClick()"><i class="mdi mdi-plus mr-1"></i> Add </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="table-responsive">
                  <table class="table table-centered mb-0">
                    <thead class="thead-light">
                    <tr>
                      <th>Divisions</th>
                      <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor= "let item of this.assigned_divisions | async">
                      <td>{{item.name}}</td>
                      <td>
                        <a href="javascript:void(0);" class="action-icon" (click)="DeleteDivision(item.id)"> <i class="mdi mdi-delete"></i></a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <h4>Teams</h4>
                </div>
                <div class="col-6">
                  <div class="text-lg-right">
                    <button type="button" class="btn btn-primary mb-2 mr-2" data-bs-toggle="modal" data-bs-target="#team-modal" (click)="this.onAddTeamClick()"><i class="mdi mdi-plus mr-1"></i> Add </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="table-responsive">
                  <table class="table table-centered mb-0">
                    <thead class="thead-light">
                    <tr>
                      <th>Teams</th>
                      <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor= "let item of this.assigned_teams | async">
                      <td>{{item.name}}</td>
                      <td>
                        <a href="javascript:void(0);" class="action-icon" (click)="DeleteTeam(item.id)"> <i class="mdi mdi-delete"></i></a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <h4>Users</h4>
                </div>
                <div class="col-6">
                  <div class="text-lg-right">
                    <button type="button" class="btn btn-primary mb-2 mr-2" data-bs-toggle="modal" data-bs-target="#user-modal" (click)="this.onAddUserClick()"><i class="mdi mdi-plus mr-1"></i> Add </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="table-responsive">
                  <table class="table table-centered mb-0">
                    <thead class="thead-light">
                    <tr>
                      <th>Users</th>
                      <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor= "let item of this.assigned_users | async">
                      <td>{{item.given_name + ' ' + item.family_name}}</td>
                      <td>
                        <a href="javascript:void(0);" class="action-icon" (click)="DeleteUser(item.id)"> <i class="mdi mdi-delete"></i></a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div id="division-modal" class="modal fade" tabindex="-1" style="display: none;" aria-hidden="true" (close)="closeDivisionModalEvent()">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header py-3 px-4 border-bottom-0">
            <h4 class="modal-title" id="standard-modalLabel">Assign Divisions</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeDivisionModalEvent()"></button>
          </div>
          <form class="ps-3 pe-3">
            <div class="modal-body">
              <div *ngIf="(status == 'failure' || status == 'success') && !this.isModalOpened" class="row g-2" [ngSwitch]="status">
                <div class="col-12">
                  <div class="alert alert-danger" role="alert" *ngSwitchCase="'failure'">
                    <h4 class="alert-heading">An error occurred while assigning divisions to the interface.</h4>
                    <hr class="my-2">
                    <p class="mb-0">If the error persists please contact us at <a class="text-danger"
                                                                                  href="mailto:{{support_email}}">{{support_email}}</a>.</p>
                  </div>
                  <div class="alert alert-success" role="alert" *ngSwitchCase="'success'">
                    <h4 class="alert-heading">Assignment Successful</h4>
                    <hr class="my-2">
                    <p class="mb-0">Divisions assigned to interface successfully.</p>
                  </div>
                  <span *ngSwitchDefault></span>
                </div>
              </div>
              <div class="row g-2">
                <div class="mb-3 col-12">
                    <div class="table-responsive">
                      <table class="table table-centered mb-0">
                        <thead class="thead-light">
                        <tr>
                          <th style="width: 20px;">
                          </th>
                          <th>Divisions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngIf="(this.unassigned_divisions | async)?.length==0">
                          <td colspan="4">
                            <span>No unassigned divisions found.</span>
                          </td>
                        </tr>
                        <tr *ngFor="let item of this.unassigned_divisions | async">
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="item.id" [value]="item.id" (click)="onDivisionChkBoxClick(item.id,$event.target.checked)">
                              <label class="custom-control-label" for="customCheck2"></label>
                            </div>
                          </td>
                          <td>
                            {{item.name}}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div> <!-- end table-responsive-->
                </div>
              </div>
            </div>
            <div class="modal-footer d-block">
              <div class="row g-2 text-end">
                <div class="mb-3 col-12">
                  <button type="submit" class="btn btn-primary" (click)="AssignDivisionsToInterface()" [disabled]="this.isDivisionAssignButtonDisbaled"> Assign
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div>
      <div id="team-modal" class="modal fade" tabindex="-1" style="display: none;" aria-hidden="true" (close)="closeTeamModalEvent()">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header py-3 px-4 border-bottom-0">
              <h4 class="modal-title" id="standard-modalLabel">Assign Team</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeTeamModalEvent()"></button>
            </div>
            <form class="ps-3 pe-3">
              <div class="modal-body">
                <div *ngIf="(status == 'failure' || status == 'success') && !this.isModalOpened" class="row g-2" [ngSwitch]="status">
                  <div class="col-12">
                    <div class="alert alert-danger" role="alert" *ngSwitchCase="'failure'">
                      <h4 class="alert-heading">An error occurred while assigning team to the interface.</h4>
                      <hr class="my-2">
                      <p class="mb-0">If the error persists please contact us at <a class="text-danger"
                                                                                    href="mailto:{{support_email}}">{{support_email}}</a>.</p>
                    </div>
                    <div class="alert alert-success" role="alert" *ngSwitchCase="'success'">
                      <h4 class="alert-heading">Assignment Successful</h4>
                      <hr class="my-2">
                      <p class="mb-0">Teams assigned to Interface successfully.</p>
                    </div>
                    <span *ngSwitchDefault></span>
                  </div>
                </div>
                <div class="row g-2">
                  <div class="mb-3 col-12">
                      <div class="table-responsive">
                        <table class="table table-centered mb-0">
                          <thead class="thead-light">
                          <tr>
                            <th style="width: 20px;">
                            </th>
                            <th>Teams</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngIf="(this.unassigned_teams | async)?.length==0">
                            <td colspan="4">
                              <span>No unassigned teams found.</span>
                            </td>
                          </tr>
                          <tr *ngFor="let item of this.unassigned_teams | async">
                            <td>
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="item.id" [value]="item.id" (click)="onTeamChkBoxClick(item.id,$event.target.checked)">
                                <label class="custom-control-label" for="customCheck2"> </label>
                              </div>
                            </td>
                            <td>
                              {{item.name}}
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div> <!-- end table-responsive-->
                  </div>
                </div>
              </div>
              <div class="modal-footer d-block">
                <div class="row g-2 text-end">
                  <div class="mb-3 col-12">
                    <button type="submit" class="btn btn-primary" (click)="AssignTeamsToInterface()" [disabled]="this.isTeamAssignButtonDisbaled"> Assign
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
      </div>

      <div id="user-modal" class="modal fade" tabindex="-1" style="display: none;" aria-hidden="true" (close)="closeUserModalEvent()">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header py-3 px-4 border-bottom-0">
              <h4 class="modal-title" id="standard-modalLabel">Assign Users</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeUserModalEvent()"></button>
            </div>
            <form class="ps-3 pe-3">
              <div class="modal-body">
                <div *ngIf="(status == 'failure' || status == 'success') && !this.isModalOpened" class="row g-2" [ngSwitch]="status">
                  <div class="col-12">
                    <div class="alert alert-danger" role="alert" *ngSwitchCase="'failure'">
                      <h4 class="alert-heading">An error occurred while assigning user to the interface.</h4>
                      <hr class="my-2">
                      <p class="mb-0">If the error persists please contact us at <a class="text-danger"
                                                                                    href="mailto:{{support_email}}">{{support_email}}</a>.</p>
                    </div>
                    <div class="alert alert-success" role="alert" *ngSwitchCase="'success'">
                      <h4 class="alert-heading">Assignment Successful</h4>
                      <hr class="my-2">
                      <p class="mb-0">Users assigned to Interface successfully.</p>
                    </div>
                    <span *ngSwitchDefault></span>
                  </div>
                </div>

                <div class="row g-2">
                  <div class="mb-3 col-12">
                      <div class="table-responsive">
                        <table class="table table-centered mb-0">
                          <thead class="thead-light">
                          <tr>
                            <th style="width: 20px;">
                            </th>
                            <th>Users</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngIf="(this.unassigned_users | async)?.length==0">
                            <td colspan="4">
                              <span>No unassigned users found.</span>
                            </td>
                          </tr>
                          <tr *ngFor="let item of this.unassigned_users | async">
                            <td>
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="item.id" [value]="item.id" (click)="onUserChkBoxClick(item.id,$event.target.checked)">
                                <label class="custom-control-label" for="customCheck2"> </label>
                              </div>
                            </td>
                            <td>
                              {{item.given_name+" "+item.family_name}}
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div> <!-- end table-responsive-->
                  </div>
                </div>
              </div>
              <div class="modal-footer d-block">
                <div class="row g-2 text-end">
                  <div class="mb-3 col-12">
                    <button type="submit" class="btn btn-primary" (click)="AssignUsersToInterface()" [disabled]="this.isUserAssignButtonDisbaled"> Assign
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
      </div>
      <ng-container *ngIf="loading">
        <div class="row">
          <div *ngFor="let x of [].constructor(1)" class="shine shinecard d-block card-body overflow-hidden">&nbsp;</div>
          <a *ngFor="let x of [].constructor(3)" class="shine list-group-item list-group-item-action w-100 text-nowrap overflow-hidden"><i
            class="uil-books font-18 align-middle me-2 visually-hidden"></i> &nbsp;</a>
        </div>
      </ng-container>
    </div>
  `,
  styles: [`
    .shinecard {
      min-height: 305px;
      border-radius: 1px;
      background: #fff;
      box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
      transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
      cursor: pointer;
    }
    .shine {
      background-image: linear-gradient(to right, #f1f3fa00 0%, #dfe3f0 50%, #f1f3fa00 100%);
      background-repeat: no-repeat;
      background-size: 40% 100%;
      display: inline-block;
      position: relative;

      animation-play-state: running;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: shimmer;
      animation-timing-function: linear;
    }
    @keyframes shimmer {
      0% {
        background-position: -100% 0;
      }
      100% {
        background-position: 200% 0;
      }
    }
  `]})


export class InterfaceAssignmentComponent implements OnInit {

  public interface_id: number;
  public assigned_users: Observable<User[]>;
  public unassigned_users: Observable<User[]>;
  public assigned_teams: Observable<Team[]>;
  public unassigned_teams: Observable<Team[]>;
  public assigned_divisions: Observable<Division[]>;
  public unassigned_divisions: Observable<Division[]>;
  public userIdList: number[] = [];
  public teamIdList: number[] = [];
  public divisionIdList: number[] = [];
  public interface_user_url: string;
  public interface_team_url: string;
  public interface_division_url: string;
  public status: string;
  public isUserDeleted = false;
  public isTeamDeleted = false;
  public isDivisionDeleted = false;
  public isModalOpened = false;
  public isTeamAssignButtonDisbaled = true;
  public isDivisionAssignButtonDisbaled = true;
  public isUserAssignButtonDisbaled = true;
  public loading = false;
  public readonly support_email = 'Support@DarkMatterIns.com';

  constructor(private route: ActivatedRoute,
              private http: HttpClient,
              private features: FeaturesService,
              private router: Router,
              private googleAnalytics: GoogleAnalyticsService){}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.interface_id = params.id;
    });
    this.GetInterfaceUsers(this.interface_id, true);
    this.GetInterfaceTeams(this.interface_id, true);
    this.GetInterfaceDivisions(this.interface_id, true);
  }
  private GetInterfaceUsers(interface_id: number, isAssigned: boolean): void {
    this.loading = true;
    if (isAssigned){
      this.assigned_users = this.features.getFeatures().pipe(mergeMap(response => {
        if (response.h_search_application_interface_users) {
          this.interface_user_url = response.h_search_application_interface_users + '/' + interface_id;
          return this.http.get<User>(this.interface_user_url + '/' + isAssigned,
            {
              headers: {'X-DM-Authorize': 'management_api'}
            });
        } else {
          return from(this.router.navigate(['/config/error/403'])).pipe(map(() => null));
        }
      }), switchMap(response => {
        this.loading = false;
        return of(response);
      }));
    }
    else{
      this.unassigned_users = this.features.getFeatures().pipe(mergeMap(response => {
        if (response.h_search_application_interface_users) {
          this.interface_user_url = response.h_search_application_interface_users + '/' + interface_id;
          return this.http.get<User>(this.interface_user_url + '/' + isAssigned,
            {
              headers: {'X-DM-Authorize': 'management_api'}
            });
        } else {
          return from(this.router.navigate(['/config/error/403'])).pipe(map(() => null));
        }
      }), switchMap(response => {
        this.loading = false;
        return of(response);
      }));
    }
  }
  private GetInterfaceTeams(interface_id: number, isAssigned: boolean): void {
    this.loading = true;
    if (isAssigned){
      this.assigned_teams = this.features.getFeatures().pipe(mergeMap(response => {
        if (response.h_search_application_interface_teams) {
          this.interface_team_url = response.h_search_application_interface_teams + '/' + interface_id;
          return this.http.get<Team>(this.interface_team_url + '/' + isAssigned,
            {
              headers: {'X-DM-Authorize': 'management_api'}
            });
        } else {
          return from(this.router.navigate(['/config/error/403'])).pipe(map(() => null));
        }
      }), switchMap(response => {
        this.loading = false;
        return of(response);
      }));
    }
    else{
      this.unassigned_teams = this.features.getFeatures().pipe(mergeMap(response => {
        if (response.h_search_application_interface_teams) {
          this.interface_team_url = response.h_search_application_interface_teams + '/' + interface_id;
          return this.http.get<Team>(this.interface_team_url + '/' + isAssigned,
            {
              headers: {'X-DM-Authorize': 'management_api'}
            });
        } else {
          return from(this.router.navigate(['/config/error/403'])).pipe(map(() => null));
        }
      }), switchMap(response => {
        this.loading = false;
        return of(response);
      }));
    }
  }
  private GetInterfaceDivisions(interface_id: number, isAssigned: boolean): void {
    this.loading = true;
    if (isAssigned){
      this.assigned_divisions = this.features.getFeatures().pipe(mergeMap(response => {
        if (response.h_search_application_interface_divisions) {
          this.interface_division_url = response.h_search_application_interface_divisions + '/' + interface_id;
          return this.http.get<Division>(this.interface_division_url + '/' + isAssigned,
            {
              headers: {'X-DM-Authorize': 'management_api'}
            });
        } else {
          return from(this.router.navigate(['/config/error/403'])).pipe(map(() => null));
        }
      }), switchMap(response => {
        this.loading = false;
        return of(response);
      }));
    }
    else{
      this.unassigned_divisions = this.features.getFeatures().pipe(mergeMap(response => {
        if (response.h_search_application_interface_divisions) {
          this.interface_division_url = response.h_search_application_interface_divisions + '/' + interface_id;
          return this.http.get<Division>(this.interface_division_url + '/' + isAssigned,
            {
              headers: {'X-DM-Authorize': 'management_api'}
            });
        } else {
          return from(this.router.navigate(['/config/error/403'])).pipe(map(() => null));
        }
      }), switchMap(response => {
        this.loading = false;
        return of(response);
      }));
    }
  }
  public onAddDivisionClick(): void {
    this.isModalOpened = true;
    this.isDivisionAssignButtonDisbaled = true;
    const element = document.getElementById('division-modal');
    // @ts-ignore
    const modal = bootstrap.Modal.getInstance(element);
    modal._config.backdrop = 'static';
    modal._config.keyboard = false;
    this.GetInterfaceDivisions(this.interface_id, false);
  }
  public onAddTeamClick(): void {
    this.isModalOpened = true;
    this.isTeamAssignButtonDisbaled = true;
    const element = document.getElementById('team-modal');
    // @ts-ignore
    const modal = bootstrap.Modal.getInstance(element);
    modal._config.backdrop = 'static';
    modal._config.keyboard = false;
    this.GetInterfaceTeams(this.interface_id, false);
  }
  public onAddUserClick(): void {
    this.isModalOpened = true;
    this.isUserAssignButtonDisbaled = true;
    const element = document.getElementById('user-modal');
    // @ts-ignore
    const modal = bootstrap.Modal.getInstance(element);
    modal._config.backdrop = 'static';
    modal._config.keyboard = false;
    this.GetInterfaceUsers(this.interface_id, false);
  }
  public onTeamChkBoxClick(id: number , event: any): void {
    if (event){
      this.teamIdList.push(id);
      this.isTeamAssignButtonDisbaled = false;
    }
    else {
      if (this.teamIdList.length > 0) {
        this.teamIdList.forEach((element, index) => {
          if (element === id) {
            this.teamIdList.splice(index, 1);
          }
        });
        if (this.teamIdList.length === 0) {
          this.isTeamAssignButtonDisbaled = true;
        }
      }
    }
  }
  public onDivisionChkBoxClick(id: number , event: any): void {
    if (event){
      this.divisionIdList.push(id);
      this.isDivisionAssignButtonDisbaled = false;
    }
    else {
      if (this.divisionIdList.length > 0) {
        this.divisionIdList.forEach((element, index) => {
          if (element === id) {
            this.divisionIdList.splice(index, 1);
          }
        });
        if (this.divisionIdList.length === 0) {
          this.isDivisionAssignButtonDisbaled = true;
        }
      }
    }
  }
  public onUserChkBoxClick(id: number , event: any): void {
    if (event){
      this.userIdList.push(id);
      this.isUserAssignButtonDisbaled = false;
    }
    else {
      if (this.userIdList.length > 0) {
        this.userIdList.forEach((element, index) => {
          if (element === id) {
            this.userIdList.splice(index, 1);
          }
        });
        if (this.userIdList.length === 0) {
          this.isUserAssignButtonDisbaled = true;
        }
      }
    }
  }

  public AssignDivisionsToInterface(): void {
    this.googleAnalytics.event('clicked_assign', 'dmbridge_interface_assignment', 'Clicked Assign Division to Interface');
    this.http.post(this.interface_division_url, this.divisionIdList, {observe: 'response', headers: {'X-DM-Authorize': 'management_api'}})
      .pipe(first(), concatMap(response => {
        if (response){
          this.status = 'success';
          this.isModalOpened = false;
          setTimeout(() => {
            this.closeDivisionModalEvent();
            this.GetInterfaceDivisions(this.interface_id, true);
          }, 1000);
          return of(true);
        } else{
          this.status = 'failure';
          return of(false);
        }
      }), catchError(async () => {
        this.status = 'failure';
        return false;
      })).subscribe();
  }

  public AssignTeamsToInterface(): void{
    this.googleAnalytics.event('clicked_assign', 'dmbridge_interface_assignment', 'Clicked Assign Team to Interface');
    this.http.post(this.interface_team_url, this.teamIdList, {observe: 'response', headers: {'X-DM-Authorize': 'management_api'}})
      .pipe(first(), concatMap(response => {
        if (response){
          this.status = 'success';
          this.isModalOpened = false;
          setTimeout(() => {
            this.closeTeamModalEvent();
            this.GetInterfaceTeams(this.interface_id, true);
          }, 1000);
          return of(true);
        } else {
          this.status = 'failure';
          return of(false);
        }
      }), catchError(async () => {
        this.status = 'failure';
        return false;
      })).subscribe();
  }

  public AssignUsersToInterface(): void {
      this.googleAnalytics.event('clicked_assign', 'dmbridge_interface_assignment', 'Clicked Assign User to Interface');
      this.http.post(this.interface_user_url, this.userIdList, {observe: 'response', headers: {'X-DM-Authorize': 'management_api'}})
        .pipe(first(), concatMap(response => {
          if (response){
            this.status = 'success';
            this.isModalOpened = false;
            setTimeout(() => {
              this.closeUserModalEvent();
              this.GetInterfaceUsers(this.interface_id, true);
            }, 1000);
            return of(true);
          } else{
            this.status = 'failure';
            return of(false);
          }
        }), catchError(async () => {
          this.status = 'failure';
          return false;
        })).subscribe();
    }

  public DeleteUser(userId: number): void{
    if (userId > 0){
      this.googleAnalytics.event('clicked_delete', 'dmbridge_interface_assignment', 'Clicked Delete User to Interface');
      this.http.delete(this.interface_user_url + '/' + userId, {observe: 'response', headers: {'X-DM-Authorize': 'management_api'}})
        .pipe(first(), concatMap (response => {
          if (response){
            this.isUserDeleted = true;
            this.isDivisionDeleted = false;
            this.isTeamDeleted = false;
            this.GetInterfaceUsers(this.interface_id, true);
            return of(true);
          }
          else {
            return of(false);
          }
        }), catchError (async () => {
          return false;
        })).subscribe();
    }
  }
  public DeleteTeam(teamId: number): void{
    if (teamId > 0){
      this.googleAnalytics.event('clicked_delete', 'dmbridge_interface_assignment', 'Clicked Delete Team to Interface');
      this.http.delete(this.interface_team_url + '/' + teamId, {observe: 'response', headers: {'X-DM-Authorize': 'management_api'}})
        .pipe(first(), concatMap (response => {
          if (response){
            this.isUserDeleted = false;
            this.isDivisionDeleted = false;
            this.isTeamDeleted = true;
            this.GetInterfaceTeams(this.interface_id, true);
            return of(true);
          }
          else {
            return of(false);
          }
        }), catchError (async () => {
          return false;
        })).subscribe();
    }
  }
  public DeleteDivision(DivisionId: number): void {
    if (DivisionId > 0){
      this.googleAnalytics.event('clicked_delete', 'dmbridge_interface_assignment', 'Clicked Delete Division to Interface');
      this.http.delete(this.interface_division_url + '/' + DivisionId, {observe: 'response', headers: {'X-DM-Authorize': 'management_api'}})
        .pipe(first(), concatMap (response => {
          if (response){
            this.isUserDeleted = false;
            this.isDivisionDeleted = true;
            this.isTeamDeleted = false;
            this.GetInterfaceDivisions(this.interface_id, true);
            return of(true);
          }
          else {
            return of(false);
          }
        }), catchError (async () => {
          return false;
        })).subscribe();
    }
  }
  public closeDivisionModalEvent(): void {
    this.divisionIdList = [];
    const element = document.getElementById('division-modal');
    // @ts-ignore
    const modal = bootstrap.Modal.getInstance(element);
    if (modal) {
      modal.hide();
    }
    this.googleAnalytics.event('division_modal_closed', 'dmbridge_interface_assignment', 'Closed Division Assignment Modal');
  }
  public closeTeamModalEvent(): void {
    this.teamIdList = [];
    const element = document.getElementById('team-modal');
    // @ts-ignore
    const modal = bootstrap.Modal.getInstance(element);
    if (modal) {
      modal.hide();
    }
    this.googleAnalytics.event('team_modal_closed', 'dmbridge_interface_assignment', 'Closed Team Assignment Modal');
  }
  public closeUserModalEvent(): void {
    this.userIdList = [];
    const element = document.getElementById('user-modal');
    // @ts-ignore
    const modal = bootstrap.Modal.getInstance(element);
    if (modal) {
      modal.hide();
    }
    this.googleAnalytics.event('user_modal_closed', 'dmbridge_interface_assignment', 'Closed User Assignment Modal');
  }

  public isDivisionCheckBoxChecked(id: number): void{
    this.divisionIdList.forEach(index => {
      return id === this.divisionIdList[index];
    });
  }

  public isTeamCheckBoxChecked(id: number): void{
    this.divisionIdList.forEach(index => {
      return id === this.divisionIdList[index];
    });
  }
  public isUserCheckBoxChecked(id: number): void{
    this.divisionIdList.forEach(index => {
      return id === this.divisionIdList[index];
    });
  }

}
class User {
  public given_name: string;
  public family_name: string;
  public id: number;
}
class Team {
  public id: number;
  public name: string;
}
class Division {
  public id: number;
  public name: string;
}
