import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {OnPackageOptionChange} from './on-package-option-change';
import {DmConnectPackageOptions} from '../dm-connect-package-options';
import {JsonTranspositions} from '../json-transpositions';

@Component({
  selector: 'app-json-transpositions-selector',
  template: `

    <div *ngIf="this.json_transpositions_object" class="card d-block">
      <div class="card-body">
        <div class="col-12" id="tabJSON">
          <h5 class="card-title mb-3">JSON Transpositions Libraries</h5>
          <div class="row">
            <div class="col-6">
              <div class="form-floating">
                <select class="form-select" id="defaultLib" aria-label="Select Default Library" (change)="selectDefaultLibrary()"
                        [(ngModel)]="json_transpositions_object.default_library">
                  <option *ngFor="let item of libraries_array" [ngValue]="item.key">{{item.key}}</option>
                </select>
                <label for="defaultLib">Select the Default Library</label>
              </div>
            </div>

            <div class="text-sm-end col-md-6">
              <button type="button" class="btn btn-success mb-2 mr-2" data-bs-toggle="modal" data-bs-target="#Json-transp-modal"
                      (click)="onOpenJsonTranspModal(libraries, 'add')">
                <i class="mdi mdi-plus mr-1"></i> Add
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-centered mb-0">
              <thead class="thead-light">
              <tr>
                <th>Library Name</th>
                <th>Library Path</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of libraries_array">
                <td>{{item.key}}</td>
                <td class="text-truncate" style="max-width: 240px;" data-bs-toggle="tooltip" data-container="body" data-bs-placement="top" title="{{item.value}}">{{item.value}}</td>
                <td>
                  <a (click)="onOpenJsonTranspModal(item, 'edit')" data-bs-toggle="modal" data-bs-target="#Json-transp-modal"
                     class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                  <a (click)="onDeleteJsonTranspClick(item)" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                  <a [routerLink]="['../transposition-editor']" [queryParams]="{library_name: item.key}" class="action-icon"> <i class="uil-books"></i></a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> <!-- end card-body-->
    </div> <!-- end card-->

    <div id="Json-transp-modal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header py-3 px-4 border-bottom-0">
            <h4 class="modal-title" id="standard-modalLabel">Add Transposition Library</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div *ngIf="this.is_show_transposition_exception" class="alert alert-danger" role="alert">
            <p class="mb-0">This library name is already exists.</p>
          </div>
          <form class="ps-3 pe-3">
            <div class="modal-body">
              <div class="row g-2">
                <label class="form-label" for="library_name">Name</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" id="library_name" name="library_name" [(ngModel)]="libraries.key"
                         (input)="onKeyModalInput($any($event.target).value)">
                </div>
              </div>
              <div class="row g-2">
                <label class="form-label" for="library_path">Path</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" id="library_path" name="library_path" [(ngModel)]="libraries.value">
                </div>
              </div>
            </div>
            <div class="modal-footer d-block">
              <div class="row g-2 text-end">
                <div class="mb-3 col-12">
                  <button *ngIf="is_add_clicked" [disabled]="this.is_add_button_disabled" type="submit" class="btn btn-primary" data-bs-dismiss="modal"
                          (click)="onAddJsonTranspClick(libraries)"> Add
                  </button>
                  <button *ngIf="is_edit_clicked" type="submit" class="btn btn-primary" (click)="onUpdateJsonTranspClick(libraries)" data-bs-dismiss="modal">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div>

  `,
  styles: [``]
})

export class JsonTranspositionConfiguratorComponent implements OnInit , OnPackageOptionChange{

  @Input() packageOptions: Subject<DmConnectPackageOptions>;
  @Output() onChange = new EventEmitter<any>();
  public libraries_array = new Array<Libraries>();
  public libraries = new Libraries();
  public is_show_transposition_exception: boolean;
  public is_add_clicked = false;
  public is_edit_clicked = false;
  public is_add_button_disabled = true;
  public json_transpositions_object = new JsonTranspositions();

  ngOnInit(): void {

    this.packageOptions.subscribe((next) => {
      this.libraries_array = new Array<Libraries>();
      this.json_transpositions_object = next.json_transpositions;

      if(next.json_transpositions){
        Object.keys(next.json_transpositions?.libraries).forEach(singleKey => {
          const library = {
            key: singleKey,
            value: next.json_transpositions.libraries[singleKey]
          };
          if (!this.libraries_array.includes(library)) {
            this.libraries_array.push(library);
          }
        });
      }

    });
  }

  public onOpenJsonTranspModal(libObject: Libraries , action: string): void {
    if (action === 'add') {
      this.is_add_clicked = true;
      this.is_edit_clicked = false;
      this.libraries = new Libraries();
    } else if (action === 'edit') {
      this.is_add_clicked = false;
      this.is_edit_clicked = true;
      this.libraries = libObject;
    }
    this.is_show_transposition_exception = false;
  }

  public onAddJsonTranspClick(libraryObject: Libraries): void {
    if (libraryObject.key) {
      if (this.libraries_array.find(x => x.key === libraryObject.key)) {
        this.is_show_transposition_exception = true;
        return;
      }
      this.libraries_array.push(libraryObject);
      if(this.libraries_array.length == 1)
        this.selectFirstLibraryArrayOption();
    }
    this.onJsonTranspositionChange();
  }

  public onUpdateJsonTranspClick(libraryObject: Libraries): void {
    if (libraryObject.key) {
      const index = this.libraries_array.findIndex(obj => obj.key === libraryObject.key);
      this.libraries_array[index] = libraryObject;
      this.onJsonTranspositionChange();
    }
  }

  public onDeleteJsonTranspClick(libraryObject: Libraries): void {
    const index = this.libraries_array.indexOf(libraryObject);
    if (index !== -1) {
      this.libraries_array.splice(index, 1);
      if (this.json_transpositions_object.default_library === libraryObject.key) {
        this.selectFirstLibraryArrayOption();
      }
      this.onJsonTranspositionChange();
    }
  }

  public onJsonTranspositionChange(): void {
    this.toArrayToIDictionary();
    this.onChange.emit({ json_transpositions: this.json_transpositions_object });
  }

  selectDefaultLibrary(): void {
    this.onChange.emit({ json_transpositions: this.json_transpositions_object });
  }

  public onKeyModalInput(value: string): void {
    this.is_add_button_disabled = value === '';
  }

  public toArrayToIDictionary(): void {
    this.json_transpositions_object.libraries = {};
    this.libraries_array.forEach(x =>
      this.json_transpositions_object.libraries[x.key]  = x.value
    );
  }
  private selectFirstLibraryArrayOption(): void{
    if(this.libraries_array.length > 0) {
      this.json_transpositions_object.default_library = this.libraries_array[0].key;
    }
  }

}

export class Libraries {
  public key: string;
  public value: string;
}
