<div class="wrapper">
  <!-- ========== Left Sidebar Start ========== -->
  <div class="leftside-menu">

    <!-- LOGO -->
    <a class="logo text-center logo-light">
                    <span class="logo-lg">
                        <img src="/images/dmBridge.png" alt="Dark Matter InsurTech LLC" style="max-height: 50px" />
                    </span>
      <span class="logo-sm">
                        <img src="/images/support_control_logo.png" alt="" height="32">
                    </span>
    </a>

    <div class="h-100" id="leftside-menu-container" data-simplebar>

      <!--- Sidemenu -->
      <ul class="side-nav">

        <li class="side-nav-item" routerLinkActive="menuitem-active">
          <a routerLink="licensing" class="side-nav-link">
            <i class="uil-package"></i>
            <span> DmConnect Licensing </span>
          </a>
        </li>

        <li class="side-nav-item" routerLinkActive="menuitem-active">
          <a routerLink="generate-configuration" class="side-nav-link">
            <i class="uil-file-alt"></i>
            <span> Customer Configuration </span>
          </a>
        </li>

        <li class="side-nav-item" routerLinkActive="menuitem-active">
          <a routerLink="test-launcher" class="side-nav-link">
            <i class="mdi mdi-hammer-wrench"></i>
            <span> Test Launcher </span>
          </a>
        </li>

      </ul>

      <div class="clearfix"></div>

    </div>
    <!-- Sidebar -left -->

  </div>
  <!-- Left Sidebar End -->

  <!-- ============================================================== -->
  <!-- Start Page Content here -->
  <!-- ============================================================== -->

  <div class="content-page">
    <div class="content">

      <!-- Start Content-->
      <router-outlet></router-outlet>
      <!-- container -->

    </div>


    <footer class="footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            2020 - {{ this_year }} © Dark Matter InsurTech, LLC
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- ============================================================== -->
  <!-- End Page content -->
  <!-- ============================================================== -->
</div>
