import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DmconnectBrowserService {

  constructor() { }

  public closeWindow(): void {
    // @ts-ignore
    if (window && window.dmConnectWindow){
      // @ts-ignore
      window.dmConnectWindow.close();
    }
  }

  public openFullBrowser(url: string): void {
    // @ts-ignore
    if (window && window.dmConnectWindow){
      // @ts-ignore
      window.dmConnectWindow.launchUrl(url);
    } else if (window) {
      window.location.replace(url);
    }
  }

  resize(width: number, height: number): void {
    // @ts-ignore
    if (window && window.dmConnectWindow){
      // @ts-ignore
      window.dmConnectWindow.setSize(width, height);
    }
  }
}
