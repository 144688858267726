<body class="" data-layout="topnav" data-layout-config="{'layoutBoxed':false,'darkMode':false,'showRightSidebarOnStart': false}" style="visibility: visible;" data-leftbar-compact-mode="condensed">
  <!-- Begin page -->
  <div class="wrapper">
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
      <div class="content">
        <div class="navbar-custom topnav-navbar topnav-navbar-dark">
          <div class="container-fluid">
            <a href="" class="topnav-logo">
              <span class="topnav-logo-lg">
<!--                  <img src="/images/dmconnect_poweredby.png" height="70" />-->
              </span>
              <span class="topnav-logo-sm">
<!--                <img src="/images/dmconnect_poweredby.png" height="70" />-->
              </span>
            </a>
          </div>
        </div>

        <div class="topnav shadow-sm">
          <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand-sm topnav-menu">

              <div class="collapse navbar-collapse" id="topnav-menu-content">
                <ul class="navbar-nav">
                  <li class="nav-item dropdown">
                    <a class="nav-link" routerLink="credentials" role="button">
                      <i class="mdi mdi-form-textbox-password me-1"></i>Credentials
                    </a>
                  </li>
                  <li class="nav-item dropdown">
                    <a class="nav-link" routerLink="about" id="topnav-dashboards" role="button">
                      <i class="uil-dashboard me-1"></i>Diagnostics
                    </a>
                  </li>
                  <li class="nav-item dropdown">
                    <a class="nav-link" routerLink="analytics" role="button">
                      <i class="uil-chart me-1"></i>Analytics (BETA)
                    </a>
                  </li>
                  <li class="nav-item dropdown" *ngIf="show_transpositions">
                    <a class="nav-link" routerLink="transpositions" role="button">
                      <i class="mdi mdi-swap-horizontal me-1"></i>Transpositions
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <!-- Start Content-->
        <div class="container-fluid">
          <router-outlet></router-outlet>
          <br />
        </div>
      </div>
      <!-- content -->
      <!-- Footer Start -->
      <footer class="footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6">
              2020 - {{ current_year }} © Dark Matter InsurTech, LLC
            </div>
            <div class="col-lg-6 text-right">
              UI Version: <strong>{{ ui_version }}</strong><br />
              Desktop Version: <button class="btn btn-link p-0 fw-bold" (click)="loadDesktopVersion()" *ngIf="!desktop_version; else version">Check version</button><ng-template #version><strong>{{ desktop_version ?? 'N/A' }}</strong></ng-template>
            </div>
          </div>
        </div>
      </footer>
      <!-- end Footer -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->
  </div>
  <!-- END wrapper -->
</body>
