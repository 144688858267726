import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PluginLocalStorageService {
  private readonly keyName = 'create_submission_payload';

  read(type?: StoredDataType): any {
    const stored_item = localStorage.getItem(type ?? this.keyName);

    if (stored_item) {
      return JSON.parse(stored_item);
    }

    return null;
  }

  save(portalId: string, value: any, type?: StoredDataType): void {
    const storageObject = {portal_id: portalId, payload: value};
    localStorage.setItem(type ?? this.keyName, JSON.stringify(storageObject));
  }

  remove(type?: StoredDataType): void {
    localStorage.removeItem(type ?? this.keyName);
  }

  clearSubmissionData(): void {
    localStorage.removeItem(StoredDataType.PREFLIGHT_PAYLOAD);
    localStorage.removeItem(StoredDataType.PORTAL_PAYLOAD);
    localStorage.removeItem(StoredDataType.PREVIOUS_INSURED);
  }

  saveUnderwriter(underwriter_email: string): void {
    localStorage.setItem('underwriter_email', underwriter_email);
  }

  readUnderwiter(): string {
    return localStorage.getItem('underwriter_email');
  }

  saveError(param: { error_message: any; workflow_id: any }): void {
    localStorage.setItem('error', JSON.stringify(param));
  }

  readError(): any {
    const stored_item = localStorage.getItem('error');

    if (stored_item) {
      localStorage.removeItem('error');
      return JSON.parse(stored_item);
    }

    return null;
  }

  readSuccess(): any {
    const stored_item = localStorage.getItem('success');

    if (stored_item) {
      localStorage.removeItem('success');
      return JSON.parse(stored_item);
    }

    return null;
  }

  saveSuccess(success): void {
    localStorage.setItem('success', JSON.stringify(success));
  }

  saveDmLuid(luid: string): void {
    localStorage.setItem(StoredDataType.DM_LUID, luid);
  }

  readDmLuid(): string {
    return localStorage.getItem(StoredDataType.DM_LUID);
  }

  saveTranspositionData(data: any): void {
    localStorage.setItem(StoredDataType.TRANSPOSITION_PAYLOAD, JSON.stringify(data));
  }

  readTranspositionData(): any {
    return JSON.parse(localStorage.getItem(StoredDataType.TRANSPOSITION_PAYLOAD));
  }
}

export enum StoredDataType {
  PREFLIGHT_PAYLOAD = 'preflight_payload',
  PREVIOUS_INSURED = 'previous_insured',
  PORTAL_PAYLOAD = 'portal_payload',
  DM_LUID = 'dm_luid',
  TRANSPOSITION_PAYLOAD = 'can_edit',
}
