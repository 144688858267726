import {Component, OnInit} from '@angular/core';
import {PluginLocalStorageService, StoredDataType} from '../plugin-local-storage.service';
import {WorkflowService} from '../workflows/workflow-service.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Component({
  selector: 'app-id-roadblock',
  template: `
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-6 text-center">
          <img alt="help icon" class="user-help-icon" src="assets/images/help-icon.svg" />
          <h4 class="text-uppercase text-danger">Enter Contract Id</h4>
          <p class="text-muted">Your organization is configured to require you to enter the correct contract identifier for this business. If you'd rather search from within this screen please contact your technology department to enable that functionality.</p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-6 form-floating text-center">
          <input id="contractId" class="form-control" type="text" placeholder="Enter Contract Id" #s1="ngModel" required [class.is-invalid]="!agency_id && s1.touched" [(ngModel)]="agency_id" />
          <label for="contractId">Enter Contract Id</label>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-6 text-center">
          <button type="button" class="btn btn-primary btn-block mt-4" (click)="onClickSubmit()" [disabled]="!agency_id">Continue</button>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .user-help-icon {
      max-width: 200px;
    }
  `]
})
export class IdRoadblockComponent implements OnInit {
  public agency_id: string = null;
  private portal_id: string;
  private preflight_payload: any;
  private portal_payload: any;

  constructor(private workflowService: WorkflowService, private localStorage: PluginLocalStorageService, private googleAnalytics: GoogleAnalyticsService) {
    const temp = this.localStorage.read(StoredDataType.PREFLIGHT_PAYLOAD);
    this.portal_id = temp.portal_id;
    this.preflight_payload = temp.payload;
    this.portal_payload = this.localStorage.read(StoredDataType.PORTAL_PAYLOAD);
  }

  ngOnInit(): void {
  }


  onClickSubmit(): void {
    this.googleAnalytics.event('contract_import_clicked', 'plugin_modal', 'Import to contract.');
    this.workflowService.execute('inbound', { dm_target_id: this.agency_id, dm_portal_id: this.portal_payload.portal_id,  ...this.portal_payload.payload}).subscribe();
  }
}
