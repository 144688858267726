import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, from, of} from 'rxjs';
import {catchError, concatMap, first, map, mergeMap} from 'rxjs/operators';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {FeaturesService} from '../features.service';
import {ODataSearchResponse} from '../ODataSearchResponse';

@Component({
  selector: 'app-data-library',
  template: `<div class="container-fluid">
    <!-- page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Data Library</h4>
        </div>
      </div>
    </div> <!-- end page title -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">

            <!-- Modal: Create Data Library -->
            <div id="modal-create-library" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-create-library-label" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header py-3 px-4 border-bottom-0">
                    <h4 class="modal-title" id="modal-create-library-label">Create Data Library</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true" (click)="this.googleAnalytics.event('create_library_modal_close_x', 'dmbridge_library_management', 'Clicked X on create library modal');"></button>
                  </div>
                  <form action="#" (submit)="onCreateLibrary()">
                    <div class="modal-body px-4 pb-4 pt-0">
                      <div class="row" *ngIf="create_library_status" [ngSwitch]="create_library_status">
                        <div class="col-12">
                          <div class="alert alert-success" role="alert" *ngSwitchCase="'success'">
                            <h4 class="alert-heading">Success</h4>
                            <hr class="my-2">
                            <p class="mb-0">The data library was successfully created.</p>
                          </div>
                          <div class="alert alert-danger" role="alert" *ngSwitchCase="'error'">
                            <h4 class="alert-heading">An error occurred while creating the data library.</h4>
                            <hr class="my-2">
                            <p class="mb-0">If the error persists please contact us at <a class="text-danger" href="mailto:{{support_email}}">{{support_email}}</a>.</p>
                          </div>
                          <span *ngSwitchDefault></span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 mb-3">
                          <label for="txt-data-library-name" class="form-label">Name</label>
                          <div class="input-group">
                            <input name="txt-library-name" id="txt-library-name" class="form-control" type="text" placeholder="e.g. Companies" [(ngModel)]="create_library_name" #library_name="ngModel" [class.is-invalid]="!create_library_name && library_name.touched" required>
                            <div class="invalid-feedback">
                              Please choose a name for the data library.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 mb-3">
                          <label for="chk-library-active" class="form-label">Active</label>
                          <select name="chk-library-active" id="chk-library-active" class="form-select" [(ngModel)]="create_library_active">
                            <option [ngValue]="true">Active</option>
                            <option [ngValue]="false">Inactive</option>
                          </select>
                        </div>
                      </div>
                      <div class="modal-footer pb-0 pt-3 px-0">
                        <div class="row">
                          <div class="col-12 text-end">
                            <button type="button" class="btn btn-light me-1" data-bs-dismiss="modal" (click)="this.googleAnalytics.event('create_library_modal_close', 'dmbridge_library_management', 'Clicked Close on create library modal');">Close</button>
                            <button class="btn btn-primary" type="submit" [disabled]="!create_library_name || creating_library">
                              <span *ngIf="creating_library" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                              {{ creating_library ? 'Creating...' : 'Create' }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <!-- Modal: Import Library Items -->
            <div id="modal-item-import" class="modal fade" tabindex="-1" role="dialog">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header py-3 px-4 border-bottom-0">
                    <h4 class="modal-title">Import Library Items</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="this.googleAnalytics.event('import_modal_close_x', 'dmbridge_library_management', 'Clicked X on item import modal');"></button>
                  </div>
                  <form class="modal-body px-4 pb-4 pt-0" (submit)="this.importLibraryItems();">
                    <div class="row" *ngIf="item_import_status == 'failure' || item_import_status == 'success'" [ngSwitch]="item_import_status">
                      <div class="col-12">
                        <div class="alert alert-danger" role="alert" *ngSwitchCase="'failure'">
                          <h4 class="alert-heading">An error occurred while importing library items.</h4>
                          <hr class="my-2">
                          <p class="mb-0">If the error persists please contact us at <a class="text-danger" href="mailto:{{this.support_email}}">{{this.support_email}}</a>.</p>
                        </div>
                        <div class="alert alert-success" role="alert" *ngSwitchCase="'success'">
                          <h4 class="alert-heading">Success</h4>
                          <hr class="my-2">
                          <p class="mb-0">The library items were successfully imported.</p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label class="form-label">File to Import</label>
                        <input id="item-import-file" name="item-import-file" type="file" class="form-control" accept=".csv" (change)="this.item_import_file = $event.target['files'][0];">
                        <p class="pt-2"> The file should be a .CSV with the following columns:<br><code>description, value</code></p>
                      </div>
                    </div>
                    <div class="modal-footer pb-0 pt-3 px-0">
                      <div class="row">
                        <div class="col-12 text-end">
                          <button type="button" class="btn btn-light me-1" data-bs-dismiss="modal" (click)="this.googleAnalytics.event('import_modal_close', 'dmbridge_library_management', 'Clicked Close on item import modal');">Close</button>
                          <button type="submit" class="btn btn-primary" [ngSwitch]="this.item_import_status" [disabled]="!this.item_import_file">
                            <span *ngSwitchCase="'loading'"><span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Importing...</span>
                            <span *ngSwitchDefault>Import</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div> <!-- end item import modal content -->
              </div>
            </div> <!-- end item import modal -->

            <!-- left sidebar -->
            <div class="page-aside-left">
              <div class="btn-group d-block mb-2">
                <button [disabled]="!this.h_create_library" type="button" class="btn btn-success dropdown-toggle w-100" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="mdi mdi-plus"></i> Create New </button>
                <div class="dropdown-menu">
                  <a *ngIf="h_create_library" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#modal-create-library" href="javascript:void(0);"><i class="uil-books me-1"></i> Data Library</a>
                </div>
              </div>
              <div class="list-group mt-3">
                <a *ngFor="let x of library_page | async" (click)="onLibrarySelect(x)" [ngClass]="{'active': selected_library?.id === x.id}" href="javascript:void(0);" class="list-group-item list-group-item-action"><i class="uil-books font-18 align-middle me-2"></i>{{x.name}}</a>
                <ng-container *ngIf="loading_libraries">
                    <a *ngFor="let x of [].constructor(5)" class="shine list-group-item list-group-item-action w-100 text-nowrap overflow-hidden"><i class="uil-books font-18 align-middle me-2 visually-hidden"></i> &nbsp;</a>
                </ng-container>
              </div>
            </div> <!-- end left sidebar -->

            <!-- right sidebar -->
            <div class="page-aside-right">
              <div class="d-inline-block w-100 text-end">
                <button *ngIf="this.h_import_library_items" type="button" class="btn btn-light" data-bs-toggle="modal" data-bs-target="#modal-item-import"
                        (click)="this.onOpenItemImport();">Import</button>
              </div>
              <div class="mt-3">
                <div class="table-responsive">
                  <table class="table table-centered table-striped dt-responsive nowrap w-100" id="products-datatable">
                    <thead>
                    <tr>
                      <th style="width: 20px;">
                        <div class="form-check">
                          <input type="checkbox" class="form-check-input" id="customCheck1">
                          <label class="form-check-label" for="customCheck1">&nbsp;</label>
                        </div>
                      </th>
                      <th>Value</th>
                      <th>Description</th>
                      <th style="width: 75px;">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of library_item_page | async">
                        <td>
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="customCheck2">
                            <label class="form-check-label" for="customCheck2">&nbsp;</label>
                          </div>
                        </td>
                        <td class="table-user">
                          {{ item.value }}
                        </td>
                        <td>
                          {{ item.description }}
                        </td>
                        <td style="min-width: 100px">
                          <a *ngIf="item.h_update_library_item" href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                          <a *ngIf="item.h_delete_library_item" href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                        </td>
                      </tr>
                      <ng-container *ngIf="loading_items">
                        <tr *ngFor="let x of [].constructor(10)">
                          <td>
                            <div class="form-check shine w-100 h-75">&nbsp;</div>
                          </td>
                          <td class="table-user">
                            <div class="shine w-100 h-75">&nbsp;</div>
                          </td>
                          <td>
                            <div class="shine w-100 h-75">&nbsp;</div>
                          </td>
                          <td style="min-width: 100px">
                            <div class="shine w-100 h-75">&nbsp;</div>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div> <!-- end .mt-3-->
              <div class="mt-3 d-flex justify-content-center">
                <ngb-pagination [collectionSize]="library_item_count" [(page)]="library_item_page_num" (pageChange)="onItemPageChange(library_item_page_num)" [pageSize]="library_item_page_size" >
                  <ng-template ngbPaginationNumber let-page>
                    {{page}}
                  </ng-template>
                </ngb-pagination>
              </div>
            </div> <!-- end right sidebar -->

          </div> <!-- end card body -->
          <div class="clearfix"></div>
        </div> <!-- end card-box -->
      </div> <!-- end col -->
    </div><!-- end row -->
  </div>`,
  styles: [`
    input:not(.is-invalid) ~ .invalid-feedback {
      display: block;
      visibility: hidden;
    }

    .shine {
      background-image: linear-gradient(to right, #f1f3fa00 0%, #dfe3f0 50%, #f1f3fa00 100%);
      background-repeat: no-repeat;
      background-size: 40% 100%;
      display: inline-block;
      position: relative;

      animation-play-state: running;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: shimmer;
      animation-timing-function: linear;
    }

    @keyframes shimmer {
      0% {
        background-position: -100% 0;
      }
      100% {
        background-position: 200% 0;
      }
    }
  `]
})
export class DataLibraryComponent implements OnInit {

  constructor(
    public googleAnalytics: GoogleAnalyticsService,
    private http: HttpClient,
    private features: FeaturesService,
    private router: Router
  ) { }
  public library_page: Observable<DataLibrary[]>;
  public library_item_page: Observable<DataLibraryItem[]>;
  public library_create_task: Observable<boolean>;
  public selected_library: DataLibrary | null;
  public library_item_page_num = 0;
  public library_item_page_size = 10;
  public library_count = 0;
  public library_item_count = 0;
  public readonly support_email = 'Support@DarkMatterIns.com';
  private readonly modal_close_delay = 3000;
  public h_create_library: string;
  public h_import_library_items: string;
  public loading_libraries = false;
  public loading_items = false;
  public creating_library: boolean;
  public create_library_status: string;
  public create_library_name = '';
  public create_library_active = false;
  public item_import_status: string;
  public item_import_file;

  static toFormData(data): FormData {
    const formData = new FormData();
    for (const key of Object.keys(data)) {
      formData.append(key, data[key]);
    }
    return formData;
  }

  ngOnInit(): void {
    this.loadLibraries();
  }

  public onCreateLibrary(): void {
    this.creating_library = true;
    this.create_library_status = null;

    this.http.post(
      this.h_create_library,
      {name: this.create_library_name, is_active: this.create_library_active},
      {headers: {'X-DM-Authorize': 'management_api'}})
      .pipe(first(), concatMap(() => {
        setTimeout(() => {
          const modalElement = document.getElementById('modal-create-library');
          // @ts-ignore
          const modal = bootstrap.Modal.getInstance(modalElement);
          if (modal) {
            modal.hide();
            this.loadLibraries();
            this.create_library_status = null;
          }
        }, this.modal_close_delay);
        this.creating_library = false;
        this.create_library_status = 'success';
        return of(true);
      }), catchError(async () => {
        this.creating_library = false;
        this.create_library_status = 'error';
        return false;
      })).subscribe();
  }

  public onItemPageChange(page: number): void {
    this.loadLibraryItems(this.selected_library?.h_search_library_items, page);
  }

  public onLibrarySelect(library: DataLibrary): void {
    if (!library) {
      this.loading_items = false;
    } else {
      this.selected_library = library;
      this.h_import_library_items = library.h_import_library_items;
      this.loadLibraryItems(library?.h_search_library_items, 1);
    }
  }

  public onOpenItemImport(): void {
    this.googleAnalytics.event('import_modal_open', 'dmbridge_library_management', 'Opened import modal');
    this.item_import_status = null;
    (document.getElementById('item-import-file') as HTMLInputElement).value = null;
    this.item_import_file = null; // .value=null does not trigger change event
  }

  public importLibraryItems(): void {
    this.googleAnalytics.event('import_modal_send', 'dmbridge_library_management', 'Clicked Import on item import modal');
    this.item_import_status = 'loading';

    this.http.post(this.h_import_library_items, DataLibraryComponent.toFormData({file: this.item_import_file}),
      {
        headers: {
          'X-DM-Authorize': 'management_api'
        }
      })
      .pipe(first(), mergeMap(() => {
        this.item_import_status = 'success';
        setTimeout(() => {
          // @ts-ignore
          const modal = bootstrap.Modal.getInstance(document.getElementById('modal-item-import'));
          if (modal) {
            modal.hide();
          }
        }, this.modal_close_delay);
        return of(true);
      }), catchError(() => {
        this.item_import_status = 'failure';
        return of(false);
      })).subscribe(() => {
        // Reloading library items
        this.loadLibraryItems(this.selected_library?.h_search_library_items, 1);
    });
  }

  private loadLibraries(): void {
    if (this.loading_libraries || this.loading_items) {
      return;
    }
    this.loading_libraries = true;
    this.loading_items = true;
    this.library_page = this.features.getFeatures().pipe(mergeMap(response => {
      if (response.h_search_libraries) {
        return this.http.get<ODataSearchResponse<DataLibrary>>(response.h_search_libraries,
          {
            headers: {'X-DM-Authorize': 'management_api'},
            params: new HttpParams().set('$count', true)
          });
      } else {
        return from(this.router.navigate(['/config/error/403'])).pipe(map(() => null));
      }
    }), concatMap(response => {
      this.loading_libraries = false;
      this.library_count = response.count;
      this.h_create_library = response.h_create_entity;
      this.onLibrarySelect(response.items.length > 0 ? response.items[0] : null);
      return of(response.items);
    }));
  }

  private loadLibraryItems(h_search_library_items: string, page: number): void {
    if (h_search_library_items) {
      this.loading_items = true;
      const page_size = this.library_item_page_size;
      this.library_item_page = this.http
        .get<ODataSearchResponse<DataLibraryItem>>(h_search_library_items,
          {
            headers: {'X-DM-Authorize': 'management_api'},
            params: new HttpParams().set('$top', page_size).set('$skip', (page - 1) * page_size).set('$count', true)
          })
        .pipe(map(result => {
          this.library_item_count = result.count;
          this.loading_items = false;
          return result.items;
        }));
    }
  }
}

class DataLibrary {
  public id: number;
  public name: string;
  public h_search_library_items: string;
  public h_import_library_items: string;
  public h_create_library_item: string;
}

class DataLibraryItem{
  public id: number;
  public description: string;
  public value: string;
  public h_update_library_item: string | null;
  public h_delete_library_item: string | null;
}
