import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    `,
  styles: [`

  `]
})
export class AppComponent implements OnInit {
  title = 'Dark Matter DmConnect Portal Plugin';

  constructor() { }

  ngOnInit(): void {
  }
}
