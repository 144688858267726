<div class="wrapper">
  <!-- ========== Left Sidebar Start ========== -->
  <div class="leftside-menu">

    <!-- LOGO -->
    <a href="index.html" class="logo text-center logo-light">
                    <span class="logo-lg">
                        <img src="/images/dmBridge.png" alt="Dark Matter InsurTech LLC" style="max-height: 50px" />
                    </span>
      <span class="logo-sm">
                        <img src="/images/dmconnect_poweredby.png" alt="" height="16">
                    </span>
    </a>

    <div class="h-100" id="leftside-menu-container" data-simplebar>

      <!--- Sidemenu -->
      <ul class="side-nav">
        <li class="side-nav-item">
          <a data-bs-toggle="collapse" routerLink="home" aria-expanded="false" aria-controls="sidebarDashboards" class="side-nav-link">
            <i class="uil-home-alt"></i>
            <span> Home </span>
          </a>
        </li>
        <ng-container *ngIf="(staging_buckets | async) as buckets">
          <li class="side-nav-title side-nav-item" *ngIf="buckets.length">Staging Buckets</li>

          <ng-container *ngFor="let bucket of buckets">
            <li class="side-nav-item" *ngIf="bucket.is_active">
              <!-- TODO: This link value will have to be updated -->
              <a routerLink="batch-management/slt" class="side-nav-link">
                <i class="mdi mdi-file-document-outline"></i>
                <span> {{bucket.name}} </span>
              </a>
            </li>
          </ng-container>
        </ng-container>

        <li class="side-nav-title side-nav-item">Configuration</li>

        <li class="side-nav-item">
          <a routerLink="users" class="side-nav-link">
            <i class="uil-users-alt"></i>
            <span class="badge bg-success float-end">1</span>
            <span> User Management </span>
          </a>
        </li>

        <li class="side-nav-item">
          <a routerLink="data" class="side-nav-link">
            <i class="uil-database"></i>
            <span> Data Library </span>
          </a>
        </li>

        <li class="side-nav-item">
          <a routerLink="interfaces" class="side-nav-link">
            <i class="uil-wifi"></i>
            <span> Interfaces </span>
          </a>
        </li>

        <li class="side-nav-item">
          <a routerLink="batch-management" class="side-nav-link">
            <i class="mdi mdi-bucket-outline"></i>
            <span> Bucket Management </span>
          </a>
        </li>

        <li class="side-nav-item">
          <a routerLink="cost" class="side-nav-link">
            <i class="uil-dollar-sign-alt"></i>
            <span> Cost </span>
          </a>
        </li>
      </ul>

      <!-- Help Box -->
      <div class="help-box text-white text-center">
        <a href="javascript: void(0);" class="float-end close-btn text-white">
          <i class="mdi mdi-close"></i>
        </a>
        <img src="assets/images/help-icon.svg" height="90" alt="Helper Icon Image" />
        <h5 class="mt-3">Unlimited Access</h5>
        <p class="mb-3">Upgrade to plan to get access to unlimited reports</p>
        <a href="javascript: void(0);" class="btn btn-outline-light btn-sm">Upgrade</a>
      </div>
      <!-- end Help Box -->
      <!-- End Sidebar -->

      <div class="clearfix"></div>

    </div>
    <!-- Sidebar -left -->

  </div>
  <!-- Left Sidebar End -->

  <!-- ============================================================== -->
  <!-- Start Page Content here -->
  <!-- ============================================================== -->

  <div class="content-page">
    <div class="content">
      <!-- Topbar Start -->
      <div class="navbar-custom">
        <ul class="list-unstyled topbar-menu float-end mb-0">
          <li class="dropdown notification-list d-lg-none">
            <a class="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
              <i class="dripicons-search noti-icon"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
              <form class="p-3">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
              </form>
            </div>
          </li>

          <li class="dropdown notification-list">
            <a class="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
              <i class="dripicons-bell noti-icon"></i>
              <span class="noti-icon-badge"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg">

              <!-- item-->
              <div class="dropdown-item noti-title">
                <h5 class="m-0">
                                            <span class="float-end">
                                                <a href="javascript: void(0);" class="text-dark">
                                                    <small>Clear All</small>
                                                </a>
                                            </span>Notification
                </h5>
              </div>

              <div style="max-height: 230px;" data-simplebar>
                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <div class="notify-icon bg-primary">
                    <i class="mdi mdi-comment-account-outline"></i>
                  </div>
                  <p class="notify-details">Caleb Flakelar commented on Admin
                    <small class="text-muted">1 min ago</small>
                  </p>
                </a>

                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <div class="notify-icon bg-info">
                    <i class="mdi mdi-account-plus"></i>
                  </div>
                  <p class="notify-details">New user registered.
                    <small class="text-muted">5 hours ago</small>
                  </p>
                </a>

                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <div class="notify-icon">
                    <img src="/assets/images/users/avatar-2.jpg" class="img-fluid rounded-circle" alt="" /> </div>
                  <p class="notify-details">Cristina Pride</p>
                  <p class="text-muted mb-0 user-msg">
                    <small>Hi, How are you? What about our next meeting</small>
                  </p>
                </a>

                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <div class="notify-icon bg-primary">
                    <i class="mdi mdi-comment-account-outline"></i>
                  </div>
                  <p class="notify-details">Caleb Flakelar commented on Admin
                    <small class="text-muted">4 days ago</small>
                  </p>
                </a>

                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <div class="notify-icon">
                    <img src="assets/images/users/avatar-4.jpg" class="img-fluid rounded-circle" alt="" /> </div>
                  <p class="notify-details">Karen Robinson</p>
                  <p class="text-muted mb-0 user-msg">
                    <small>Wow ! this admin looks good and awesome design</small>
                  </p>
                </a>

                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <div class="notify-icon bg-info">
                    <i class="mdi mdi-heart"></i>
                  </div>
                  <p class="notify-details">Carlos Crouch liked
                    <b>Admin</b>
                    <small class="text-muted">13 days ago</small>
                  </p>
                </a>
              </div>

              <!-- All-->
              <a href="javascript:void(0);" class="dropdown-item text-center text-primary notify-item notify-all">
                View All
              </a>

            </div>
          </li>

          <li class="dropdown notification-list d-none d-sm-inline-block">
            <a class="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
              <i class="dripicons-view-apps noti-icon"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg p-0">

              <div class="p-2">
                <div class="row g-0">
                  <div class="col">
                    <a class="dropdown-icon-item" href="#">
                      <img src="assets/images/brands/slack.png" alt="slack">
                      <span>Slack</span>
                    </a>
                  </div>
                  <div class="col">
                    <a class="dropdown-icon-item" href="#">
                      <img src="assets/images/brands/github.png" alt="Github">
                      <span>GitHub</span>
                    </a>
                  </div>
                  <div class="col">
                    <a class="dropdown-icon-item" href="#">
                      <img src="assets/images/brands/dribbble.png" alt="dribbble">
                      <span>Dribbble</span>
                    </a>
                  </div>
                </div>

                <div class="row g-0">
                  <div class="col">
                    <a class="dropdown-icon-item" href="#">
                      <img src="assets/images/brands/bitbucket.png" alt="bitbucket">
                      <span>Bitbucket</span>
                    </a>
                  </div>
                  <div class="col">
                    <a class="dropdown-icon-item" href="#">
                      <img src="assets/images/brands/dropbox.png" alt="dropbox">
                      <span>Dropbox</span>
                    </a>
                  </div>
                  <div class="col">
                    <a class="dropdown-icon-item" href="#">
                      <img src="assets/images/brands/g-suite.png" alt="G Suite">
                      <span>G Suite</span>
                    </a>
                  </div>
                </div> <!-- end row-->
              </div>

            </div>
          </li>

          <li class="notification-list">
            <a class="nav-link end-bar-toggle" href="javascript: void(0);">
              <i class="dripicons-gear noti-icon"></i>
            </a>
          </li>

          <li *ngIf="auth.user$ | async as user" class="dropdown notification-list">
            <a class="nav-link dropdown-toggle nav-user arrow-none me-0" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false"
               aria-expanded="false">
              <span class="account-user-avatar">
                  <img [src]="user.picture ?? '/images/user_profile.png'" alt="user-image" class="rounded-circle">
              </span>
              <span>
                  <span class="account-user-name">{{user.name}}</span>
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
              <!-- item-->
              <div class=" dropdown-header noti-title">
                <h6 class="text-overflow m-0">Welcome {{user.given_name}}!</h6>
              </div>

              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item notify-item">
                <i class="mdi mdi-account-circle me-1"></i>
                <span>My Account</span>
              </a>

              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item notify-item">
                <i class="mdi mdi-account-edit me-1"></i>
                <span>Settings</span>
              </a>

              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item notify-item">
                <i class="mdi mdi-lifebuoy me-1"></i>
                <span>Support</span>
              </a>

              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item notify-item" (click)="logout()">
                <i class="mdi mdi-logout me-1"></i>
                <span>Logout</span>
              </a>
            </div>
          </li>

        </ul>
        <button class="button-menu-mobile open-left">
          <i class="mdi mdi-menu"></i>
        </button>
        <div class="app-search dropdown d-none d-lg-block">
          <form>
            <div class="input-group">
              <input type="text" class="form-control dropdown-toggle"  placeholder="Search..." id="top-search">
              <span class="mdi mdi-magnify search-icon"></span>
              <button class="input-group-text btn-primary" type="submit">Search</button>
            </div>
          </form>

          <div class="dropdown-menu dropdown-menu-animated dropdown-lg" id="search-dropdown">
            <!-- item-->
            <div class="dropdown-header noti-title">
              <h5 class="text-overflow mb-2">Found <span class="text-danger">17</span> results</h5>
            </div>

            <!-- item-->
            <a href="javascript:void(0);" class="dropdown-item notify-item">
              <i class="uil-notes font-16 me-1"></i>
              <span>Analytics Report</span>
            </a>

            <!-- item-->
            <a href="javascript:void(0);" class="dropdown-item notify-item">
              <i class="uil-life-ring font-16 me-1"></i>
              <span>How can I help you?</span>
            </a>

            <!-- item-->
            <a href="javascript:void(0);" class="dropdown-item notify-item">
              <i class="uil-cog font-16 me-1"></i>
              <span>User profile settings</span>
            </a>

            <!-- item-->
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow mb-2 text-uppercase">Users</h6>
            </div>

            <div class="notification-list">
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item notify-item">
                <div class="d-flex">
                  <img class="d-flex me-2 rounded-circle" src="assets/images/users/avatar-2.jpg" alt="Generic placeholder image" height="32">
                  <div class="w-100">
                    <h5 class="m-0 font-14">Erwin Brown</h5>
                    <span class="font-12 mb-0">UI Designer</span>
                  </div>
                </div>
              </a>

              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item notify-item">
                <div class="d-flex">
                  <img class="d-flex me-2 rounded-circle" src="assets/images/users/avatar-5.jpg" alt="Generic placeholder image" height="32">
                  <div class="w-100">
                    <h5 class="m-0 font-14">Jacob Deo</h5>
                    <span class="font-12 mb-0">Developer</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- end Topbar -->

      <!-- Start Content-->
      <router-outlet></router-outlet>
      <!-- container -->

    </div>
    <!-- content -->

    <!-- Footer Start -->
    <footer class="footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            2020 - {{ ThisYear }} © Dark Matter InsurTech, LLC
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->

  </div>

  <!-- ============================================================== -->
  <!-- End Page content -->
  <!-- ============================================================== -->


</div>
