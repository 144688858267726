import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-interface-configuration',
  template: `<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Connectors</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <!-- end row-->

    <div class="row">
      <div class="col-lg-6 col-xxl-3">
        <!-- project card -->
        <div class="card d-block">
          <div class="card-body">
            <div class="dropdown card-widgets">
              <a href="#" class="dropdown-toggle arrow-none" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="dripicons-dots-3"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item"><i class="mdi mdi-pencil me-1"></i>Edit</a>
                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item"><i class="mdi mdi-delete me-1"></i>Delete</a>
                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item"><i class="mdi mdi-email-outline me-1"></i>Invite</a>
                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item"><i class="mdi mdi-exit-to-app me-1"></i>Leave</a>
              </div>
            </div>
            <!-- project title-->
            <h4 class="mt-0">
              <a href="apps-projects-details.html" class="text-title">SQL Server</a>
            </h4>
            <div class="badge bg-success mb-3">Recommended</div>

            <p class="text-muted font-13 mb-3 min-h-100px">SQL Server Connections
            </p>

            <!-- project detail-->
            <p class="mb-1">
                <span class="pe-2 text-nowrap mb-2 d-inline-block">
                    <i class="mdi mdi-format-list-bulleted-type text-muted"></i>
                    <b>21</b> Usages
                </span>
            </p>
          </div> <!-- end card-body-->
        </div> <!-- end card-->
      </div> <!-- end col -->

      <div class="col-md-6 col-xxl-3">
        <!-- project card -->
        <div class="card d-block">
          <div class="card-body">
            <div class="dropdown card-widgets">
              <a href="#" class="dropdown-toggle arrow-none" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="dripicons-dots-3"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item"><i class="mdi mdi-pencil me-1"></i>Edit</a>
                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item"><i class="mdi mdi-delete me-1"></i>Delete</a>
                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item"><i class="mdi mdi-email-outline me-1"></i>Invite</a>
                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item"><i class="mdi mdi-exit-to-app me-1"></i>Leave</a>
              </div>
            </div>
            <!-- project title-->
            <h4 class="mt-0">
              <a href="apps-projects-details.html" class="text-title">REST Connections</a>
            </h4>

            <p class="text-muted font-13 mb-3 min-h-100px">
              RESTful services using json, xml, etc
            </p>

            <!-- project detail-->
            <p class="mb-1">
                  <span class="pe-2 text-nowrap mb-2 d-inline-block">
                      <i class="mdi mdi-format-list-bulleted-type text-muted"></i>
                      <b>1</b> Usage
                  </span>
            </p>
          </div> <!-- end card-body-->
        </div> <!-- end card-->
      </div> <!-- end col -->
      <div class="col-md-6 col-xxl-3">
        <!-- project card -->
        <div class="card d-block">
          <div class="card-body">
            <div class="dropdown card-widgets">
              <a href="#" class="dropdown-toggle arrow-none" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="dripicons-dots-3"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item"><i class="mdi mdi-pencil me-1"></i>Edit</a>
                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item"><i class="mdi mdi-delete me-1"></i>Delete</a>
                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item"><i class="mdi mdi-email-outline me-1"></i>Invite</a>
                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item"><i class="mdi mdi-exit-to-app me-1"></i>Leave</a>
              </div>
            </div>
            <!-- project title-->
            <h4 class="mt-0">
              <a href="apps-projects-details.html" class="text-title">SOAP v1.2</a>
            </h4>
            <div class="badge bg-secondary text-light mb-3">Ongoing</div>

            <p class="text-muted font-13 mb-3 min-h-100px">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid...<a href="javascript:void(0);" class="fw-bold text-muted">view more</a>
            </p>

            <!-- project detail-->
            <p class="mb-1">
                <span class="pe-2 text-nowrap mb-2 d-inline-block">
                    <i class="mdi mdi-format-list-bulleted-type text-muted"></i>
                    <b>0</b> Usages
                </span>
            </p>
          </div> <!-- end card-body-->
        </div> <!-- end card-->
      </div> <!-- end col -->
    </div>
    <!-- end row-->
  </div> <!-- container -->`,
  styles: [`
    .min-h-100px {
      min-height: 100px;
    }

    .card {
      min-height: 305px;
    }
  `]
})
export class ConnectorsConfigurationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
