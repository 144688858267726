import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {OnPackageOptionChange} from './on-package-option-change';
import {DmConnectPackageOptions} from '../dm-connect-package-options';

@Component({
  selector: 'app-cors-configurator',
  template: `

    <div *ngIf="this.cors_model" class="card d-block">
      <div class="card-body">
        <div class="col-12" id="tabJSON">
          <h5 class="card-title mb-3">CORS Origins</h5>
          <div class="row">
            <div class="col-6">
              <div>
              </div>
            </div>
            <div class="col-6">
              <button type="button" class="btn btn-success mb-2 float-end" data-bs-toggle="modal" data-bs-target="#Cors-input-modal"
                      (click)="openModal()">
                <i class="mdi mdi-plus mr-1"></i> Add
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-centered mb-0">
              <thead class="thead-light">
              <tr>
                <th>Origin</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of this.cors_origins; let i = index">
                <td class="text" data-bs-toggle="tooltip" data-container="body"
                    data-bs-placement="top">{{item}}</td>
                <td class="text-end">
                  <a (click)="openModal(i)" data-bs-toggle="modal" data-bs-target="#Cors-input-modal" class="action-icon">
                    <i class="mdi mdi-square-edit-outline"></i></a>
                  <a (click)="deleteOrigin(i)" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> <!-- end card-body-->
    </div> <!-- end card-->

    <div id="Cors-input-modal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" style="display: none;"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header py-3 px-4 border-bottom-0">
            <h4 class="modal-title" id="standard-modalLabel">Add CORS Configuration</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form class="ps-3 pe-3">
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <label class="form-label" for="domain">Domain</label>
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">{{cors_model.protocol}}</span>
                    <input type="text" class="form-control" id="domain" name="domain" placeholder="clarion-door.com" aria-label="Domain"
                           [(ngModel)]="cors_model.domain" #domain="ngModel">
                    <input type="number" class="form-control col-3" id="port" name="port" placeholder="8081"
                           [(ngModel)]="cors_model.port">
                  </div>
                  <div *ngIf="!cors_model.isValid() && (domain.dirty && domain.value)" class="alert alert-danger" role="alert">
                    <p class="mb-0">Please enter a valid CORS domain, it should not contain a scheme (https://) or port number (:8080).</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer d-block">
              <div class="row g-2 text-end">
                <div class="mb-3 col-12">
                  <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" [disabled]="!cors_model.isValid()"
                          (click)="onAddOrUpdate()"> {{ is_update ? 'Update' : 'Add' }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div>
  `,
  styles: [``]
})

export class CorsConfiguratorComponent implements OnInit , OnPackageOptionChange{

  @Input() packageOptions: Subject<DmConnectPackageOptions>;
  @Output() onChange = new EventEmitter<any>();
  public cors_origins = new Array<string>();
  public cors_model = Origin.Parse();
  public focused_origin_ordinal: number | null;
  public is_update = false;

  ngOnInit(): void {
    this.packageOptions.subscribe((next) => {
      if (next && next.cors_origins) {
        this.cors_origins = next.cors_origins;
      }
    });
  }

  public deleteOrigin(index: number): void {
    this.cors_origins.splice(index, 1);
    this.onCorsConfigurationChange();
  }

  public onCorsConfigurationChange(): void {
    this.onChange.emit({ cors_origins: this.cors_origins });
  }

  openModal(index?: number): void {
    this.focused_origin_ordinal = index;
    this.is_update = this.focused_origin_ordinal != null;
    this.cors_model = this.is_update ? Origin.Parse(this.cors_origins[this.focused_origin_ordinal]) : Origin.Parse();
  }

  public onAddOrUpdate(): void {
    const working_copy: string[] = [...this.cors_origins];
    if (this.is_update){
      working_copy.splice(this.focused_origin_ordinal, 1);
    }

    if (!working_copy.includes(this.cors_model.toString())) {
      working_copy.push(this.cors_model.toString()); // TODO Can we push to specific location in array
    }

    this.cors_origins = working_copy;
    this.onCorsConfigurationChange();
  }
}

class Origin {
  private static readonly domain_validator = /^[A-Za-z0-9]+([A-Za-z0-9-]*\.?)*[A-Za-z0-9]+$/;
  public readonly protocol = 'https://';

  constructor(public domain: string | undefined, public port: number | undefined) {

  }

  public static Parse(urlString?: string): Origin {
    let domain = null;
    let port = null;

    if (urlString){
      const new_string = urlString?.replace(/^(https:\/\/)/, '');
      const new_strings = new_string.split(':');
      domain = new_strings[0];
      port = Number(new_strings[1]);
    }

    return new Origin(domain, port);
  }

  public isValid(): boolean{
    return this.domain && Origin.domain_validator.test(this.domain);
  }

  public toString(): string {
    return `${this.protocol}${this.domain}${this.port && this.port !== 443 ? ':' + this.port : ''}`;
  }
}
