import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, finalize, publishReplay, refCount} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {
  // @ts-ignore
  private readonly FEATURES_API_URL = Dmc.FEATURES_API_ADDRESS;
  private readonly FEATURE_LIFETIME = 30000;
  private features: Observable<DmBridgeFeatures>;

  constructor(private http: HttpClient) {
  }

  public getFeatures(): Observable<DmBridgeFeatures> {
    if (!this.features) {
      this.features = this.http.get<DmBridgeFeatures>(this.FEATURES_API_URL, {headers: {'X-DM-Authorize': 'management_api'}}).pipe(
        publishReplay(1, this.FEATURE_LIFETIME), refCount(),
        finalize(() => setTimeout(() => this.features = null, this.FEATURE_LIFETIME)),
        catchError(async () => new DmBridgeFeatures()));
    }
    return this.features;
  }
}

export class DmBridgeFeatures {
  public h_search_libraries: string = null;
  public h_ensure_user: string = null;
  public h_search_teams: string = null;
  public h_search_divisions: string = null;
  public h_search_users: string = null;
  public h_search_staging_buckets: string = null;
  public h_assigned_workflows: string = null;
  public h_search_application_interfaces: string = null;
  public h_search_interface_pricings: string = null;
  public h_search_application_interface_users: string = null;
  public h_search_application_interface_teams: string = null;
  public h_search_application_interface_divisions: string = null;
}
