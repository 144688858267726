import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-error',
  template: `<div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">403 Error</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row justify-content-center">
      <div class="col-lg-4">
        <div class="text-center">
          <img src="assets/images/help-icon.svg" height="90" alt="File not found Image">

          <h1 class="text-error mt-4">403</h1>
          <h4 class="text-uppercase text-danger mt-3">Unauthorized</h4>
          <p class="text-muted mt-3">You do not have permission to view or edit data on the requested page.</p>

          <a class="btn btn-info mt-3" [routerLink]="['/config/home']"><i class="mdi mdi-reply"></i> Return Home</a>
        </div> <!-- end /.text-center-->
      </div> <!-- end col-->
    </div>
  </div>`,
  styles: ['']
})

export class ErrorComponent implements OnInit
{
  constructor() {
  }

  ngOnInit(): void {
  }
}
