import {Component, OnInit} from '@angular/core';
import {PluginLocalStorageService, StoredDataType} from '../plugin-local-storage.service';
import {WorkflowService} from '../workflows/workflow-service.service';
import {WorkflowResult} from '../dm-connect-api.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {Observable, of} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {first, map, retry, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-new-submission',
  template: `
    <div class="alert alert-info" role="alert">
      <p>The information on this page originated from <strong>{{ this.data_source_description }}</strong>.
        To change this information you can <a href="#" (click)="closeWindow()">close</a> this dialog, update the values in your <strong>{{ this.data_source_description }}</strong>, and start the import over.</p>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col col-sm-4">
          <strong>Insured Mailing Address</strong>
        </div>
        <div class="col col-sm-4">
          <strong>Insured Physical Address</strong>
        </div>
        <div class="col col-sm-4">
          <strong>Retail Agency</strong>
        </div>
      </div>
      <div class="row">
        <div class="col col-sm-4">
          {{ display_payload.insured?.name }}
        </div>
        <div class="col col-sm-4">
          {{ display_payload.insured?.name }}
        </div>
        <div class="col col-sm-4">
          {{ display_payload.insured?.retailer?.agency_id }}
        </div>
      </div>
      <div class="row">
        <div class="col col-sm-4">
          {{ display_payload.insured?.mailing_address?.street }}
        </div>
        <div class="col col-sm-4">
          {{ display_payload.insured?.physical_address?.street }}
        </div>
        <div class="col col-sm-4">
        </div>
      </div>
      <div class="row">
        <div class="col col-sm-4">
          {{ display_payload.insured?.mailing_address?.locality }}, {{ display_payload.insured?.mailing_address?.region }} {{ display_payload.insured?.mailing_address?.postal_code }}
        </div>
        <div class="col col-sm-4">
          {{ display_payload.insured?.physical_address?.locality }}, {{ display_payload.insured?.physical_address?.region }} {{ display_payload.insured?.physical_address?.postal_code }}
        </div>
        <div class="col col-sm-4">
          <div class="form-floating">
            <select class="form-select" id="retailAgencyId" aria-label="Select Retail Agency" [(ngModel)]="agency_id" #agent="ngModel" required [class.is-invalid]="!validateSelection(this.agency_id) && agent.touched" [disabled]="is_loading_agent">
              <option value="">{{is_loading_agent ? 'Loading Agents...' : 'Select an Agent'}}</option>
              <option *ngFor="let val of retailer_list | async" value="{{val.key}}">{{val.value}}</option>
            </select>
            <label for="retailAgencyId">Select Retail Agency</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-sm-4">
        </div>
        <div class="col col-sm-4">
        </div>
        <div class="col col-sm-4">
          <div class="form-floating mt-4">
            <select class="form-select" id="underwriterId" aria-label="Select Underwriter" [(ngModel)]="underwriter_email" #underwriter="ngModel" required [class.is-invalid]="!validateSelection(this.underwriter_email) && underwriter.touched" [disabled]="is_loading_underwriter">
              <option value="">{{is_loading_underwriter ? 'Loading Underwriters...' : 'Select an Underwriter'}}</option>
              <option *ngFor="let val of user_list | async" value="{{val.key}}">{{val.value}}</option>
            </select>
            <label for="underwriterId">Select Underwriter</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-sm-4">

        </div>
        <div class="col col-sm-4">
        </div>
        <div class="col col-sm-4">
          <button type="button" class="btn btn-primary btn-block mt-4"
                  (click)="complete()"
                  [disabled]="!validateSelection(this.agency_id) || !validateSelection(this.underwriter_email) || is_importing">
            <span *ngIf="is_importing" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
            {{ is_importing ? 'Importing...' : 'Complete Import' }}
          </button>
        </div>
      </div>
    </div>
  `,
  styles: [``]
})
export class NewSubmissionComponent implements OnInit {
  private supported_portals = {
    cd_mgahub: 'ClarionDoor CDMGA Hub',
    atlantic_casualty: 'Atlantic Casualty ACES',
    ams: 'Agency Management System',
    ftp_pl: 'FTP PL Rater',
    markel_mol: 'Markel Online',
    nic_one: 'Nautilus One',
    sga_retailer: 'SGA Retail Portal',
    sga_cpl_portal: 'SGA CPL Retailer Portal',
    sga_garage_portal: 'SGA Garage Retailer Portal',
    sga_glprop_portal: 'SGA GL/Prop Retailer Portal',
    ategrity_cwb: 'Ategrity',
    western_world_portal: 'Western World WWIP',
  };
  private match_type = {
    no_match: 'no_match',
    exact_match: 'exact_match',
    partial_match: 'partial_match'
  };
  public data_source_description: string;
  private is_preflight_payload: boolean;
  private portal_id: string;
  public display_payload: any;
  public retailer_list: Observable<any[]>;
  public user_list: Observable<any[]>;
  public agency_id = '';
  public underwriter_email = '';
  public is_importing: boolean;
  public is_loading_agent = true;
  public is_loading_underwriter = true;

  constructor(private localStorage: PluginLocalStorageService, private workflowService: WorkflowService, private googleAnalytics: GoogleAnalyticsService) {
    let stored = this.localStorage.read(StoredDataType.PREVIOUS_INSURED);
    this.portal_id = stored?.portal_id;
    this.display_payload = stored?.payload;

    if (!this.display_payload) {
      this.is_preflight_payload = true;
      stored = this.localStorage.read(StoredDataType.PREFLIGHT_PAYLOAD);
      this.portal_id = stored.portal_id;
      this.display_payload = stored.payload;
      this.data_source_description = this.supported_portals[this.portal_id];
    } else {
      this.is_preflight_payload = false;
      this.data_source_description = this.supported_portals.ams;
    }
  }

  ngOnInit(): void {
    this.retailer_list = this.getLookupData('retail_agent').pipe(map(r => {
      this.findMatch(r.output.items, this.display_payload?.insured?.retailer?.agency_id, 'agency_id');
      this.is_loading_agent = false;
      return r.output.items;
    }));

    this.user_list = this.getLookupData('underwriter').pipe(map(r => {
      this.findMatch(r.output.items, this.display_payload?.insured?.underwriter?.email ?? this.localStorage.readUnderwiter(), 'underwriter_email');
      this.is_loading_underwriter = false;
      return r.output.items;
    }));
  }

  closeWindow(): void {
    this.googleAnalytics.event('click', 'plugin_modal', 'Close Modal');
    window.parent.postMessage({dmconnect: true}, '*');
  }

  complete(): void {
    this.is_importing = true;
    this.googleAnalytics.event('clicked_complete', 'non_correlated', 'Clicked Complete Import Button');

    const creation_payload = {
      portal_id: this.portal_id,
      insured_id: this.display_payload?.insured_id,
      underwriter_email: this.underwriter_email,
      retail_agency_id: this.agency_id,
      retail_agency_email: this.display_payload?.insured?.retailer?.agency_email,
      mailing_address: this.display_payload?.insured?.mailing_address,
      physical_address: this.display_payload?.insured?.physical_address,
      named_insured: this.display_payload?.insured?.name,
      dba: this.display_payload?.insured?.dba,
      proposed_effective_date: this.display_payload?.insured?.proposed_effective_date,
      proposed_expiration_date: this.display_payload?.insured?.proposed_expiration_date,
      coverage_id: this.display_payload?.insured?.coverage_id
    };

    this.workflowService.execute('submission', creation_payload, 'ams/').pipe(
      switchMap(r => {
        if (!r.success) {
          return of(new WorkflowResult(false, null));
        }
        const portal_payload = this.localStorage.read(StoredDataType.PORTAL_PAYLOAD);
        return this.workflowService.execute('inbound', { dm_target_id: r.output.submission_id, dm_portal_id: portal_payload.portal_id,  ...portal_payload.payload});
      }),
      first(), map(() => {
        this.localStorage.saveUnderwriter(this.underwriter_email);
        this.localStorage.clearSubmissionData();
        this.is_importing = false;
      })
    ).subscribe();
  }

  public getLookupData(lookupType: string): Observable<WorkflowResult> {
    const options = { params: new HttpParams().set('lookup_type', lookupType) };

    return this.workflowService.execute('lookup', options, 'ams/', 'GET').pipe(retry(3));
  }

  validateSelection(value): boolean {
    return value && typeof value === 'string';
  }

  isItemMatch(lookupItem: any, matchValue: any): any {
      const normalizedValue = lookupItem?.value?.toLowerCase();
      const normalizedKey = lookupItem?.key?.toLowerCase();
      const normalizeMatchValue = matchValue?.toLowerCase();

      if (!normalizedKey || !normalizedValue || !normalizeMatchValue) {
        return this.match_type.no_match;
      }

      if (normalizedKey === normalizeMatchValue) {
        return this.match_type.exact_match;
      }

      if (normalizedKey.indexOf(normalizeMatchValue) >= 0 || normalizedValue.indexOf(normalizeMatchValue) >= 0) {
        return this.match_type.partial_match;
      }

      return this.match_type.no_match;
  }

  private findMatch(items: any, value: string, property_name: string): void {
    let match_value = '';

    for (const item of items) {
      const match = this.isItemMatch(item, value);

      if (match === this.match_type.exact_match) {
        match_value = item.key;
        break;
      } else if (match === this.match_type.partial_match) {
        match_value = item.key;
      }
    }

    this[property_name] = match_value;
  }
}
