import {Component, OnInit} from '@angular/core';
import {ODataSearchResponse} from '../ODataSearchResponse';
import {catchError, combineAll, finalize, first, map, retry, switchMap, timeout} from 'rxjs/operators';
import {WorkflowService} from '../workflows/workflow-service.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {LocalCredentialsApiService} from '../local-credentials-api.service';

@Component({
  selector: 'app-root',
  template:
    `
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <h4 class="page-title">Diagnostics</h4>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col-xl-6 col-lg-7">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title mb-2">Test Correlation Status</h5>
              <app-test-correlation [configured_interfaces]="available_interfaces"></app-test-correlation>
            </div> <!-- end card-body -->
          </div>
        </div>
        <div class="col-xl-6 col-lg-5">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Active Users</h5>
              <div class="table-responsive">
                <table class="table table-centered nowrap" role="grid">
                  <thead class="table-light">
                  <tr role="row">
                    <th>User</th>
                    <th>Last Seen</th>
                    <th>Process Id</th>
                    <th>Port</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody app-loading-shimmer [loading]="this.loadingUsers" columns="5">
                    <tr role="row" *ngFor="let user of dmconnect_users | async">
                      <td class="text-truncate">{{user.user_name}}</td>
                      <td class="text-truncate">{{user.last_heart_beat_at | date:'medium' }}</td>
                      <td>{{user.process_id}}</td>
                      <td>{{user.port}}</td>
                      <td [ngSwitch]="user.status">
                        <span *ngSwitchCase="this.PORT_LISTENING_VALUE" class="badge bg-success">Connected</span>
                        <span *ngSwitchDefault class="badge bg-danger">Disconnected</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> <!-- end card-body-->
          </div>
        </div> <!-- end col -->
      </div>
      <div class="row">
        <div class="col-xl-6 col-lg-7">
          <div class="row" [ngSwitch]="loading_interfaces">
            <ng-container *ngSwitchCase="false">
              <div class="col-lg-6" *ngFor="let i of available_interfaces | async">
                <div class="card widget-flat">
                  <div class="card-body">
                    <div class="float-end" *ngIf="i.image" >
                      <img src="{{i.image}}" alt="{{i.title}}" width="80px" class="widget-icon bg-white"/>
                    </div>
                    <h6 class="text-muted fw-normal mt-0">{{i.description}}</h6>
                    <h4 class="mt-3 mb-3">{{i.name}}</h4>
                  </div> <!-- end card-body-->
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div class="col-lg-6">
                <div class="card widget-flat">
                  <div class="card-body shine w-100">
                    <h6 class="text-muted fw-normal mt-0"></h6>
                    <h2 class="mt-3 mb-3"></h2>
                  </div> <!-- end card-body-->
                </div>
              </div>
              <div class="col-lg-6">
                <div class="card widget-flat">
                  <div class="card-body shine w-100">
                    <h6 class="text-muted fw-normal mt-0"></h6>
                    <h2 class="mt-3 mb-3"></h2>
                  </div> <!-- end card-body-->
                </div>
              </div>
            </ng-container>
          </div>
        </div> <!-- end col -->
        <div class="col-xl-6 col-lg-5">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Credentials</h5>
              <div class="table-responsive">
                <table class="table table-centered nowrap" role="grid">
                  <thead class="table-light">
                  <tr role="row">
                    <th>Credential Name</th>
                    <th>Configured User Name</th>
                  </tr>
                  </thead>
                  <tbody app-loading-shimmer [loading]="this.loadingCredentials" [columns]="2">
                    <tr role="row" *ngFor="let credential of credentials | async">
                      <td class="text-truncate">{{credential.display_name}}</td>
                      <td>{{credential.username}} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> <!-- end card-body -->
          </div>
        </div>
      </div>
      <!-- end row -->
    `,
  styles: [``]
})
export class DiagnosticsComponent implements OnInit {
  private readonly SERVICE_URL_ROOT = 'http://127.0.0.1';
  public readonly PORT_LISTENING_VALUE = 'LISTENING';
  public dmconnect_users: Observable<DmConnectUser[]>;
  public credentials: Observable<any[][]>;
  public available_interfaces: Subject<any[]> = new ReplaySubject<any[]>();
  public loading_interfaces = true;
  public loadingUsers = true;
  public loadingCredentials = true;

  constructor(private workflowService: WorkflowService, private http: HttpClient, private credentialService: LocalCredentialsApiService) {}

  ngOnInit(): void {
    this.setupUserList();
    this.setupCredentialList();
    this.getLookupData();
  }

  private setupUserList(): void {
    this.dmconnect_users = this.http.get<ODataSearchResponse<DmConnectUser>>(`${this.SERVICE_URL_ROOT}:8080/host/user`).pipe(
      switchMap(response => {
        const output: Observable<DmConnectUser>[] = [];

        for (const user of response.items) {
          output.push(this.checkUserStatus(user));
        }

        return output;
      }),
      combineAll(),
      finalize(() => {
        this.loadingUsers = false;
      })
    );
  }

  private checkUserStatus(user: DmConnectUser): Observable<DmConnectUser> {
    return this.http.get<DmConnectUser>(`${this.SERVICE_URL_ROOT}:${user.port}/dmrpc/status`, { observe: 'response' }).pipe(map(r => {
      return { ...user, ...{ status: r.status === 200 ? this.PORT_LISTENING_VALUE : null} };
    }), catchError( async () => user));
  }

  private setupCredentialList(): void {
    this.credentials = this.credentialService.listCredentials().pipe(timeout(10000), catchError(() => []),
      finalize(() => {
        this.loadingCredentials = false;
      })
    );
  }

  private getLookupData(): void {
    const options = { headers: {'Cache-Control': 'no-cache'}, params: new HttpParams().set('lookup_type', 'available_interfaces').set('direction', 'both') };
    this.workflowService.execute('lookup', options, 'ams/', 'GET')
      .pipe(first(), retry(3), map(r => {
        if (r && r.output && r.output.items && r.output.items.length > 0) {
          this.available_interfaces.next(r.output.items);
        }
        this.loading_interfaces = false;
      })).subscribe();
  }
}

class DmConnectUser {
  user_name: string;
  port: number;
  status: string;
  process_id: number;
  last_heart_beat_at: string;
}
