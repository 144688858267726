import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ODataSearchResponse} from './ODataSearchResponse';

@Injectable({
  providedIn: 'root'
})
export class DmConnectApiService {
  // @ts-ignore
  private readonly SERVICE_URL = Dmc.API_ADDRESS;

  constructor(private http: HttpClient) { }

  public getStatus2(portalId: string, recordId: string): Observable<StatusApiResult> {
    return this.http.get<StatusApiResult>(`${this.SERVICE_URL}status`, {
        observe: 'response',
        headers: {'Cache-Control': 'no-cache'},
        params: new HttpParams().set('portal_id', portalId).set('record_id', recordId)})
      .pipe(map(p =>
        new StatusApiResult(p.body && (p.status === 200 || p.status === 304), p.body.correlation_id, p.body.dmconnect_host, p.body.desktop_version, p.body.skip_clearance)),
        catchError(async () => new StatusApiResult(false, null, null, null, false)));
  }

  public clearanceSearch(filters: object): Observable<any[]> {
    return this.http.post<ODataSearchResponse<any>>(this.SERVICE_URL + 'ams/clearance', filters, { observe: 'response' })
      .pipe(map(r => r.body?.items));
  }
}

export class WorkflowResult {
  constructor(readonly success: boolean, readonly output: object | null | any) {}
}

export class StatusApiResult {
  // tslint:disable-next-line:variable-name
  constructor(readonly success: boolean, readonly correlation_id: string, readonly dmconnect_host: string, readonly desktop_version: string, readonly skip_clearance: boolean) {
  }
}
