import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, concatMap, first, map} from 'rxjs/operators';
import {FeaturesService} from '../features.service';
import {ODataSearchResponse} from '../ODataSearchResponse';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {NgModel} from '@angular/forms';

@Component({
  selector: 'app-user-management',
  template: `
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <h4 class="page-title">User Management</h4>
          </div>
        </div>
      </div>
      <!-- end page title -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-sm-4"></div>
                <div class="col-sm-8">
                  <div class="text-sm-end d-none">
                    <button type="button" class="btn btn-success mb-2 me-1"><i class="mdi mdi-cog"></i></button>
                    <button type="button" class="btn btn-light mb-2 me-1">Import</button>
                    <button type="button" class="btn btn-light mb-2">Export</button>
                  </div>
                </div><!-- end col-->
              </div>

              <div class="table-responsive">
                <table class="table table-centered table-striped dt-responsive nowrap w-100" id="users-datatable">
                  <thead>
                  <tr>
                    <th style="width: 20px">
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="customCheck1">
                        <label class="form-check-label" for="customCheck1">&nbsp;</label>
                      </div>
                    </th>
                    <th>User</th>
                    <th>Division</th>
                    <th>Team</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngIf="this.loading">
                    <tr>
                      <td colspan="6" style="height: 50px; padding-top: 5px; padding-bottom: 0px; margin-bottom: 0px;">
                        <div class="shine" style="width: 100%; height: 75%;"></div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6" style="height: 50px; padding-top: 5px; padding-bottom: 0px; margin-bottom: 0px;">
                        <div class="shine" style="width: 100%; height: 75%;"></div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6" style="height: 50px; padding-top: 5px; padding-bottom: 0px; margin-bottom: 0px;">
                        <div class="shine" style="width: 100%; height: 75%;"></div>
                      </td>
                    </tr>
                  </ng-container>
                  <tr *ngIf="!this.loading && !this.hasUsers">
                    <td colspan="6">
                      <span>No users were found. Try broadening your search.</span>
                    </td>
                  </tr>
                  <tr *ngFor="let match of this.search_results | async">
                    <ng-container *ngIf="!this.loading">
                      <td>
                        <div class="form-check">
                          <input type="checkbox" class="form-check-input" id="customCheck2">
                          <label class="form-check-label" for="customCheck2">&nbsp;</label>
                        </div>
                      </td>
                      <td class="table-user">
                        <img src="{{ match.profile_photo ?? '/images/user_profile.png'}}" alt="table-user" class="rounded-circle">
                        <a href="javascript:void(0);" class="text-body fw-semibold">
                          {{ match.given_name }} {{ match.family_name }}
                        </a>
                      </td>
                      <td> {{ match.division_name }} </td>
                      <td> {{ match.team_name }} </td>
                      <td [ngSwitch]="match.status">
                        <span *ngSwitchCase="'INACTIVE'" class="badge badge-danger-lighten">Inactive</span>
                        <span *ngSwitchCase="'ACTIVE'" class="badge badge-success-lighten">Active</span>
                        <span *ngSwitchCase="'PENDING'" class="badge badge-warning-lighten">Pending</span>
                        <span *ngSwitchDefault class="badge badge-danger-lighten">Error</span>
                      </td>
                      <td>
                        <a href="javascript:void(0);" class="action-icon" data-bs-toggle="modal" data-bs-target="#edit-modal"
                           [class.d-none]="!match.h_update_user" (click)="openModal(match)"><i class="mdi mdi-square-edit-outline"></i></a>
                      </td>
                    </ng-container>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div *ngIf="this.hasUsers" class="mt-3 d-flex justify-content-center">
                <ngb-pagination [collectionSize]="this.search_count" [(page)]="this.search_page" [pageSize]="this.search_size"
                                (pageChange)="getUsers($event)">
                  <ng-template ngbPaginationNumber let-page> {{ page }} </ng-template>
                </ngb-pagination>
              </div>
            </div> <!-- end card-body-->
          </div> <!-- end card-->
        </div> <!-- end col -->
      </div>
    </div> <!-- container -->
    <div id="edit-modal" class="modal fade" tabindex="-1" style="display: none;" aria-hidden="true" [class.hide]="status == 'success'">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header py-3 px-4 border-bottom-0">
            <h4 class="modal-title" id="standard-modalLabel">Edit User</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeModalEvent('update_modal_closed', 'Closed Update User Modal', team_id, ams_id)"></button>
          </div>
          <form class="ps-3 pe-3">
            <div class="modal-body">
              <div *ngIf="status == 'failure' || status == 'success'" class="row g-2" [ngSwitch]="status">
                <div class="col-12">
                  <div class="alert alert-danger" role="alert" *ngSwitchCase="'failure'">
                    <h4 class="alert-heading">An error occurred while updating the user.</h4>
                    <hr class="my-2">
                    <p class="mb-0">If the error persists please contact us at <a class="text-danger"
                                                                                  href="mailto:{{support_email}}">{{support_email}}</a>.</p>
                  </div>
                  <div class="alert alert-success" role="alert" *ngSwitchCase="'success'">
                    <h4 class="alert-heading">Update Successful</h4>
                    <hr class="my-2">
                    <p class="mb-0">The user update was successful.</p>
                  </div>
                  <span *ngSwitchDefault></span>
                </div>
              </div>
              <div class="row g-2">
                <div class="mb-3 col-12">
                  <label for="amsId" class="form-label">AMS Id</label>
                  <input type="text" class="form-control" id="amsId" [(ngModel)]="selected_user.ams_id" #ams_id="ngModel" name="ams_id"
                         required
                         [class.is-invalid]="!selected_user.ams_id && ams_id.touched" placeholder="e.g. JSMITH">
                  <div class="invalid-feedback">
                    Please enter an AMS Id
                  </div>
                </div>
              </div>
              <div class="row g-2">
                <div class="mb-3 col-12">
                  <label for="inputTeam" class="form-label">Team</label>
                  <select id="inputTeam" class="form-select" [(ngModel)]="selected_user.team_id" #team_id="ngModel" name="team_id" required
                          [class.is-invalid]="!selected_user.team_id && team_id.touched">
                    <option value="">{{is_loading_teams ? 'Loading Teams...' : 'Select a Team'}}</option>
                    <option *ngFor="let match of teams | async" value="{{match.id}}">{{match.name}}</option>
                  </select>
                  <div class="invalid-feedback">
                    Please select a Team
                  </div>
                </div>
              </div>
              <div class="row g-2">
                <div class="mb-3 col-md-6">
                  <label class="form-label" for="active">Active</label>
                  <select class="form-select" id="active" [(ngModel)]="selected_user.is_active" name="is_active">
                    <option [ngValue]="true">Active</option>
                    <option [ngValue]="false">Inactive</option>
                  </select>
                </div>
                <div class="mb-3 col-md-6">
                  <label class="form-label" for="admin">Admin</label>
                  <select class="form-select" id="admin" [(ngModel)]="selected_user.is_admin" name="is_admin">
                    <option [ngValue]="true">Admin</option>
                    <option [ngValue]="false">Not Admin</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer d-block">
              <div class="row g-2 text-end">
                <div class="mb-3 col-12">
                  <button type="button" class="btn btn-light me-1" data-bs-dismiss="modal"
                          (click)="closeModalEvent('update_modal_close_button', 'Clicked close button on Update User Modal', team_id, ams_id)">
                    Close
                  </button>
                  <button type="submit" class="btn btn-primary" (click)="updateUser(team_id, ams_id)" [ngSwitch]="status"
                          [disabled]="!selected_user.team_id || !selected_user.ams_id || status == 'failure' || status == 'loading'">
                    <span *ngSwitchCase="'loading'"><span class="spinner-border spinner-border-sm me-1" role="status"
                                                          aria-hidden="true"></span>Saving...</span>
                    <span *ngSwitchDefault>Save Changes</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div>
  `,
  styles: [`
    .shine {
      background-image: linear-gradient(to right, #f1f3fa00 0%, #dfe3f0 50%, #f1f3fa00 100%);
      background-repeat: no-repeat;
      background-size: 40% 100%;
      display: inline-block;
      position: relative;

      animation-play-state: running;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: shimmer;
      animation-timing-function: linear;
    }

    @keyframes shimmer {
      0% {
        background-position: -100% 0;
      }
      100% {
        background-position: 200% 0;
      }
    }

    input:not(.is-invalid) ~ .invalid-feedback, select:not(.is-invalid) ~ .invalid-feedback {
      display: block;
      visibility: hidden;
    }
  `]
})

export class UserManagementComponent implements OnInit {
  private readonly REQUEST_DELAY = 250; // in ms

  public loading = true;
  private users_search_url: string;
  private teams_search_url: string;
  public update_user_url: string;
  public search_results: Observable<User[]>;
  public search_size = 25;
  public search_page: number;
  public search_count: number;
  public teams: Observable<Team[]>;
  public selected_user: any = new User();
  public is_loading_teams = true;
  public status: string;
  public readonly support_email = 'Support@DarkMatterIns.com';
  public hasUsers = true;

  constructor(private features: FeaturesService, private http: HttpClient,
              private route: ActivatedRoute, private routerService: Router,
              private googleAnalytics: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
    this.features.getFeatures().subscribe(feat => {
      if (!feat.h_search_users) {
        this.routerService.navigate(['../error/403'], {relativeTo: this.route}).then();
      } else {
        this.users_search_url = feat.h_search_users;
        this.teams_search_url = feat.h_search_teams;
        this.getUsers(1);
      }
    });
  }

  getUsers(page: number): void {
    this.search_results = null; // Resetting table
    this.loading = true;
    const requestStart = Date.now();

    this.search_results = this.search(this.users_search_url, page).pipe(map(response => {
      if (response.count > 0 || response.items.length > 0) {
        this.search_page = page;
        this.search_count = response.count;
        const delay = this.REQUEST_DELAY - (Date.now() - requestStart);
        setTimeout(() => {
          this.loading = false;
        }, delay);
        return response.items;
      } else {
        this.hasUsers = false;
        this.loading = false;
      }

    }));
  }

  search(url: string, page: number): Observable<ODataSearchResponse<User>> {
    return this.http.get<ODataSearchResponse<User>>(url, {
      headers: {'X-DM-Authorize': 'management_api'},
      params: new HttpParams().set('$top', this.search_size).set('$skip', this.search_size * (page - 1)).set('$count', true)
    });
  }

  openModal(user): void {
    this.googleAnalytics.event('clicked_edit', 'dmbridge_user_management', 'Clicked Edit User');
    this.status = '';
    this.update_user_url = user.h_update_user;
    this.mapSelectedUser(user);
    if (this.teams == null) {
      this.teams = this.searchTeams(this.teams_search_url).pipe(map(response => {
        this.is_loading_teams = false;
        return response.items;
      }));
    }
  }

  searchTeams(url: string): Observable<ODataSearchResponse<Team>> {
    return this.http.get<ODataSearchResponse<Team>>(url, {headers: {'X-DM-Authorize': 'management_api'}});
  }

  updateUser(teamId: NgModel, amsId: NgModel): void {
    this.status = 'loading';
    this.googleAnalytics.event('clicked_update', 'dmbridge_user_management', 'Clicked Update User');
    this.http.post(this.update_user_url, this.selected_user, {observe: 'response', headers: {'X-DM-Authorize': 'management_api'}})
      .pipe(first(), concatMap(response => {
        if (response) {
          this.status = 'success';
          this.getUsers(1);
          setTimeout(() => {
            const element = document.getElementById('edit-modal');
            // @ts-ignore
            const modal = bootstrap.Modal.getInstance(element);
            if (modal) {
              this.closeModalEvent('update_modal_closed', 'Closed Update User Modal', teamId, amsId);
              modal.hide();
            }
          }, 3000);
          return of(true);
        } else {
          this.status = 'failure';
          return of(false);
        }
      }), catchError(async () => {
        this.status = 'failure';
        return false;
      })).subscribe();
  }

  mapSelectedUser(user): void {
    this.selected_user = {
      ams_id: user.ams_id,
      team_id: user.team_id ?? '',
      is_active: user.status === 'ACTIVE',
      is_admin: user.is_admin
    };
  }

  closeModalEvent(action: string, label: string, teamId: NgModel, amsId: NgModel): void {
    teamId.reset();
    amsId.reset();
    this.googleAnalytics.event(action, 'dmbridge_user_management', label);
  }
}

class User {
  public given_name: string;
  public family_name: string;
  public team_name: string;
  public division_name: string;
  public status: number | string;
  public profile_photo: string;
  public id: number;
  public ams_id: string;
  public team_id: number;
  public is_active: boolean;
  public is_admin: boolean;
  public h_update_user: string;
}

class Team {
  public id: number;
  public name: string;
}
