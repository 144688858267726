import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-dmbridge-master',
  templateUrl: './dmbridge-master.component.html',
  styleUrls: ['./dmbridge-master.component.css']
})
export class DmBridgeMasterComponent implements OnInit {
  public readonly ThisYear: number;
  PageTitle: string;

  constructor(route: ActivatedRoute) {
    this.ThisYear = new Date().getFullYear();

    route.url.subscribe(() => {
      this.PageTitle = route.snapshot.firstChild?.data?.title ?? 'DmBridge';
    });
  }

  ngOnInit(): void {
  }

}
