import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {DmConnectPackageOptions} from '../dm-connect-package-options';
import {OnPackageOptionChange} from './on-package-option-change';
import {SqlConfiguration} from "../sql-configuration";

@Component({
  selector: 'app-sql-configuration',
  template: `
    <div class="card d-block">
      <div class="card-body">
        <div class="row">
          <div class="col-9">
            <h5 class="card-title mb-3">SQL Connections</h5>
          </div>
          <div class="col-3">
            <button type="button" class="btn btn-success mb-2 float-end" data-bs-toggle="modal" data-bs-target="#sql-modal"
                    (click)="onOpenSqlConfigModal(newSqlObject, 'add')">
              <i class="mdi mdi-plus mr-1"></i> Add
            </button>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-centered mb-0">
            <thead class="thead-light">
            <tr>
              <th>Key</th>
              <th>Display Name</th>
              <th>Use Credentials Store</th>
              <th>Is Production</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of this.sqlConfigurationArray">
              <td>{{item.key}}</td>
              <td>{{item.display_name}}</td>
              <td>
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="credStore-{{item.key}}" [disabled]="true"
                         [checked]="item.use_credential_store">
                  <label class="custom-control-label" for="credStore-{{item.key}}"></label>
                </div>
              </td>
              <td>
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="prod-{{item.key}}" [disabled]="true"
                         [checked]="item.is_production">
                  <label class="custom-control-label" for="prod-{{item.key}}"></label>
                </div>
              </td>
              <td>
                <a (click)="onOpenSqlConfigModal(item, 'edit')" data-bs-toggle="modal" data-bs-target="#sql-modal"
                   class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                <a (click)="onDeleteSqlItemClick(item)" class="action-icon"> <i class="mdi mdi-delete"></i></a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div> <!-- end card-body-->
    </div> <!-- end card-->

    <div id="sql-modal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header py-3 px-4 border-bottom-0">
            <h4 class="modal-title" id="standard-modalLabel">Add SQL Configuration</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form class="ps-3 pe-3">
            <div class="modal-body">
              <div class="row">
                <div class="col-6">
                  <label class="form-label" for="sqlkey">Key</label>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" id="sqlkey" name="key" [(ngModel)]="newSqlObject.key"
                           (input)="onKeyModalInput($any($event.target).value)">
                  </div>
                </div>
                <div class="col-6">
                  <label class="form-label" for="sqlDisplayName">Display Name</label>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" id="sqlDisplayName" name="display_name" [(ngModel)]="newSqlObject.display_name">
                  </div>
                </div>
              </div>
              <div class="row g-2">
                <div class="col-12">
                  <label class="form-label" for="sqlConnectionString">Connection String</label>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" id="sqlConnectionString" name="connection_string" [(ngModel)]="newSqlObject.connection_string">
                  </div>
                </div>
              </div>
              <div class="row g-2">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="useCredStore" name="use_credential_store" [(ngModel)]="newSqlObject.use_credential_store">
                  <label class="form-check-label" for="useCredStore">Use Credential Store</label>
                </div>
              </div>
              <div class="row g-2">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="isProduction" name="is_production" [(ngModel)]="newSqlObject.is_production">
                  <label class="form-check-label" for="isProduction">Is Production</label>
                </div>
              </div>
            </div>
            <div class="modal-footer d-block">
              <div class="row g-2 text-end">
                <div class="mb-3 col-12">
                  <button *ngIf="isAddClicked" [disabled]="this.isAddButtonDisabled" type="submit" class="btn btn-primary" data-bs-dismiss="modal"
                          (click)="onAddSqlModalClick(newSqlObject)"> Add
                  </button>
                  <button *ngIf="isEditClicked" type="submit" class="btn btn-primary" (click)="onUpdateSqlModalClick()" data-bs-dismiss="modal"> Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div>
  `,
  styles: [``]
})

export class SqlConnectionConfiguratorComponent implements OnInit , OnPackageOptionChange {

  @Input() packageOptions: Subject<DmConnectPackageOptions>;
  @Output() onChange = new EventEmitter<any>();
  public isAddClicked = false;
  public isEditClicked = false;
  public newSqlObject = new SqlConfiguration();
  public sqlConfigurationArray = new Array<SqlConfiguration>();
  public isAddButtonDisabled = true;

  ngOnInit(): void {
    this.packageOptions.subscribe((next) => {
      this.sqlConfigurationArray = next.sql_configuration;
    });
  }

  public onOpenSqlConfigModal(sql_object: SqlConfiguration, action: string): void {
    if (action === 'add') {
      this.isAddClicked = true;
      this.isEditClicked = false;
      this.newSqlObject = new SqlConfiguration();
    } else if (action === 'edit') {
      this.isAddClicked = false;
      this.isEditClicked = true;
      this.newSqlObject = sql_object;
    }
  }

  public onAddSqlModalClick(sqlObj: SqlConfiguration): void {
    if (sqlObj) {
      this.sqlConfigurationArray.push(sqlObj);
      this.onSqlConfigurationChange();
    }
  }

  public onUpdateSqlModalClick(): void {
    this.onSqlConfigurationChange();
  }

  public onDeleteSqlItemClick(sqlItem: SqlConfiguration): void {
    const index = this.sqlConfigurationArray.indexOf(sqlItem);
    if (index !== -1) {
      this.sqlConfigurationArray.splice(index, 1);
    }
    this.onSqlConfigurationChange();
  }

  public onSqlConfigurationChange(): void{
    this.onChange.emit({ sql_configuration: this.sqlConfigurationArray });
  }

  public onKeyModalInput(value: string): void {
    this.isAddButtonDisabled = value === '';
  }
}
