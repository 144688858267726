import {Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-function-details',
  template: `
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <h4 class="page-title">Transposition Mappings</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class=" margin card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="margin col-3">
                    <h5>Data Library</h5>
                  </div>

                  <div class="margin col-3">
                    <select class="form-select">
                      <option>Carriers</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                  </div>
                </div>

                <div class="row">
                  <div class="margin col-3">
                    <select class="form-select">
                      <option>Option 1</option>
                      <option>Option 2</option>
                      <option>Option 3</option>
                      <option>Option 4</option>
                    </select>
                  </div>

                  <div class="margin col-3">
                    <select class="form-select">
                      <option>Option 1</option>
                      <option>Option 2</option>
                      <option>Option 3</option>
                      <option>Option 4</option>
                    </select>
                  </div>

                  <div class="col-3">
                    <button type="button" class="margin btn btn-secondary">+</button>
                    <button type="button" class="margin btn btn-danger">-</button>
                  </div>
                </div>

                <div class="row">
                  <div class="margin col-3">
                    <select class="form-select">
                      <option>Option 1</option>
                      <option>Option 2</option>
                      <option>Option 3</option>
                      <option>Option 4</option>
                    </select>
                  </div>

                  <div class="margin col-3">
                    <select class="form-select">
                      <option>Option 1</option>
                      <option>Option 2</option>
                      <option>Option 3</option>
                      <option>Option 4</option>
                    </select>
                  </div>

                  <div class="col-3">
                    <button type="button" class="margin btn btn-secondary">+</button>
                    <button type="button" class="margin btn btn-danger">-</button>
                  </div>
                </div>

                <div class="row">
                  <div class="margin col-3">
                    <select class="form-select">
                      <option>Option 1</option>
                      <option>Option 2</option>
                      <option>Option 3</option>
                      <option>Option 4</option>
                    </select>
                  </div>

                  <div class="margin col-3">
                    <select class="form-select">
                      <option>Option 1</option>
                      <option>Option 2</option>
                      <option>Option 3</option>
                      <option>Option 4</option>
                    </select>
                  </div>

                  <div class="col-3">
                    <button type="button" class="margin btn btn-secondary">+</button>
                    <button type="button" class="margin btn btn-danger">-</button>
                  </div>

                </div>

                <div class="row">
                  <div class="margin col-3">
                    <select class="form-select">
                      <option>Option 1</option>
                      <option>Option 2</option>
                      <option>Option 3</option>
                      <option>Option 4</option>
                    </select>
                  </div>

                  <div class="margin col-3">
                    <select class="form-select">
                      <option>Option 1</option>
                      <option>Option 2</option>
                      <option>Option 3</option>
                      <option>Option 4</option>
                    </select>
                  </div>

                  <div class="col-3">
                    <button type="button" class="margin btn btn-secondary">+</button>
                    <button type="button" class="margin btn btn-danger">-</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .margin {
      margin: 20px;
    }
  `]
})
export class TranspositionMappingsComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
