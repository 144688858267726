import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ODataSearchResponse} from '../../ODataSearchResponse';
import {catchError, mergeMap} from 'rxjs/operators';
import {OnPackageOptionChange} from './on-package-option-change';
import {DmConnectPackageOptions} from '../dm-connect-package-options';

@Component({
  selector: 'app-gateway-config',
  template: `
    <div class="card d-block">
      <div class="card-body">
        <h5 class="card-title mb-3">Customer Information</h5>
        <div class="input-group mb-3">
          <div class="form-floating w-100">
            <select class="form-select" [ngModel]="selectedCustomer" (ngModelChange)="onSelectCustomer($event)">
              <option disabled="true" [ngValue]="undefined" [hidden]="!isLoading">Loading Options...</option>
              <option *ngFor="let customer of customerOptions | async" [ngValue]="customer">{{customer.name}}</option>
            </select>
            <label>Select Customer</label>
          </div>
        </div>

        <div class="input-group mb-3">
          <label class="input-group-text">Client Token</label>
          <input type="text" class="form-control" list="client-tokens" [ngModel]="clientToken" (ngModelChange)="setGatewayConfig($event, this.supportDetails)" placeholder="556f9BB433d44e4e85168e1551829737">
        </div>
        <div class="input-group mb-3">
          <label class="input-group-text">Support Text</label>
          <input type="text" class="form-control" [ngModel]="supportDetails" (ngModelChange)="setGatewayConfig(this.clientToken, $event)" placeholder="Contact Ivan the I.T. for support <ivan@support.darkmatterins.com>">
        </div>
      </div>
    </div>
  `,
  styles: [`
    .input-group-text { min-width: 8em }
  `]
})

export class GatewayConfigComponent implements OnInit, OnPackageOptionChange {
  // @ts-ignore
  private readonly SERVICE_URL = Dmc.SUPPORT_ADDRESS + 'customer';
  public isLoading = true;
  public customerOptions = new Observable<Customer[]>();
  public selectedCustomer: Customer;
  public clientToken: string;
  public supportDetails: string;
  @Input() packageOptions: Subject<DmConnectPackageOptions>;
  @Output() onChange = new EventEmitter<any>();

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.packageOptions.subscribe((next) => {
      this.clientToken = next.client_token;
      this.supportDetails = next.support_details;
    });

    this.customerOptions = this.getCustomers();
  }

  getCustomers(): Observable<Customer[]> {
    this.isLoading = true;
    return this.http.get<ODataSearchResponse<Customer>>(this.SERVICE_URL).pipe(mergeMap((response) => {
      if (response) {
        if (response.items.length > 0) {

          if (this.clientToken != null) {
            var selectedCustomer = response.items.find(cutomer=> cutomer.client_token == this.clientToken);
            selectedCustomer.support_details =this.supportDetails;
            this.onSelectCustomer(selectedCustomer);
          }
          else {
            this.onSelectCustomer(response.items[0]);
          }
        }
        this.isLoading = false;
        return of(response.items);
      }
    }), catchError(() => {
      return of([]);
    }));
  }

  setGatewayConfig(token: string, details: string): void {
    this.clientToken = token;
    this.supportDetails = details;
    this.onChange.emit({client_token: this.clientToken, support_details: this.supportDetails});
  }

  onSelectCustomer(customer: Customer): void {
    this.selectedCustomer = customer;
    this.setGatewayConfig(customer.client_token, customer.support_details);
  }
}

class Customer {
  name: string;
  client_token: string;
  support_details: string;
}
