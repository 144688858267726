import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {DmConnectPackageOptions} from '../dm-connect-package-options';
import {JsonTranspositions} from '../json-transpositions';
import {SqlTranspositions} from '../sql-transpositions';

@Component({
  selector: 'app-general-settings',
  template: `
    <div class="card d-block">
      <div class="card-body">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="signWrkflwFile" [(ngModel)]="sign_files" (change)="settingsChanged()">
          <label class="form-check-label" for="signWrkflwFile"> Sign Workflow Files</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="includeSP" [(ngModel)]="include_stored_procedures" (change)="settingsChanged()">
          <label class="form-check-label" for="includeSP"> Include Database Schema Files</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="isService" [(ngModel)]="is_service" (change)="settingsChanged()">
          <label class="form-check-label" for="isService"> Use Service Implementation</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="chkSQL" [(ngModel)]="use_sql_transpositions" (change)="settingsChanged(true)">
          <label class="form-check-label" for="chkSQL"> Use SQL Transpositions</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="disableNewInsured" [(ngModel)]="disable_new_insured" (change)="settingsChanged()">
          <label class="form-check-label" for="disableNewInsured"> Disable New Insured</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="disableNewSubmission" [(ngModel)]="disable_new_submission" (change)="settingsChanged()">
          <label class="form-check-label" for="disableNewSubmission"> Disable New Submission</label>
        </div>
      </div> <!-- end card-body-->
    </div>
  `,
  styles: [``]
})

export class GeneralSettingsComponent implements OnInit {
  public sign_files =  true;
  public include_stored_procedures = true;
  public is_service = false;
  public disable_new_insured = false;
  public disable_new_submission = false;
  public use_sql_transpositions = false;

  @Input() packageOptions: Subject<DmConnectPackageOptions>;
  @Output() onChange = new EventEmitter<any>();

  constructor() {  }

  ngOnInit(): void {
    this.packageOptions.subscribe((next) => {
      this.sign_files = next.sign_files;
      this.include_stored_procedures = next.include_stored_procedures;
      this.is_service = next.is_service;
      this.disable_new_insured = next.disable_new_insured;
      this.disable_new_submission = next.disable_new_submission;
      this.use_sql_transpositions = next.sql_transpositions != null;
    });
  }

  settingsChanged(transposition_changed: boolean = false): void {

    const output = {
      sign_files: this.sign_files,
      include_stored_procedures: this.include_stored_procedures,
      is_service: this.is_service,
      disable_new_insured: this.disable_new_insured,
      disable_new_submission: this.disable_new_submission
    };

    if (transposition_changed) {
      // @ts-ignore
      output.json_transpositions = this.use_sql_transpositions && transposition_changed ? null : new JsonTranspositions();
      // @ts-ignore
      output.sql_transpositions  = this.use_sql_transpositions && transposition_changed ? new SqlTranspositions() : null;
    }

    this.onChange.emit(output);
  }
}
