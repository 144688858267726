import { Injectable } from '@angular/core';
import {DmConnectPackageOptions} from './dm-connect-package-options';

@Injectable({
  providedIn: 'root'
})
export class SupportLocalStorageService {
  private readonly key_name = 'scp_package_configuration';

  read(): DmConnectPackageOptions {
    const package_options = localStorage.getItem(this.key_name);

    if (package_options) {
      return JSON.parse(package_options);
    }

    return null;
  }

  save(package_options: DmConnectPackageOptions): void {
    localStorage.setItem(this.key_name, JSON.stringify(package_options));
  }

}
