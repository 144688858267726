import {Component, OnInit} from '@angular/core';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {LocalCredentialsApiService} from '../local-credentials-api.service';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, of, Subject, throwError, TimeoutError} from 'rxjs';
import {catchError, first, map, mergeMap, timeout} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  template:
    `
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <h4 class="page-title">Credentials</h4>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col">
          <div class="text-end text-nowrap">
            <div class="btn-group mb-3">
              <button type="button" class="btn" [class]="status == StatusType.ALL ? 'btn-primary' : 'btn-light'" (click)="status = StatusType.ALL">All</button>
            </div>
            <div class="btn-group mb-3 ms-1">
              <button type="button" class="btn" [class]="status == StatusType.BROKEN ? 'btn-primary' : 'btn-light'" (click)="status = StatusType.BROKEN">Broken</button>
              <button type="button" class="btn" [class]="status == StatusType.HIDDEN ? 'btn-primary' : 'btn-light'" (click)="status = StatusType.HIDDEN">Hidden</button>
            </div>
          </div>
        </div><!-- end col-->
      </div> <!-- end row -->
      <div class="row">
        <!-- start alerts -->
        <div aria-live="polite" aria-atomic="true" role="alert" class="position-relative z-1">
          <div class="toast-container px-2 mb-2 position-absolute end-0">
            <ng-container *ngFor="let alert of alerts; let i = index">
              <ng-container [ngSwitch]="alert.type">
                <div class="toast fade show bg-success text-white d-flex" *ngSwitchCase="'success'">
                  <div class="toast-body">
                    <span class="fw-bold">{{alert.message}}</span> successfully updated.
                  </div>
                  <button type="button" class="btn-close btn-close-white me-2 m-auto" (click)="alerts.splice(i, 1)" aria-label="Close"></button>
                </div>
                <div class="toast fade show bg-danger text-white d-flex" *ngSwitchCase="'error'">
                  <div class="toast-body">
                    <h4 class="me-auto">Error While Updating Credentials</h4>
                    <p class="my-2 pt-2 border-top">{{alert.message}}</p>
                    <p class="mb-0">If the error persists please contact us at <a class="text-white text-decoration-underline" href="mailto:{{support_email}}">{{support_email}}</a>.</p>
                  </div>
                  <button type="button" class="btn-close btn-close-white me-2 mt-2" (click)="alerts.splice(i, 1)" aria-label="Close"></button>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <!-- end alerts -->
        <app-loading-indicator loading_text="Working..." [is_loading]="request_loading"></app-loading-indicator>
        <ng-container *ngIf="(credentials | async)?.length > 0; else message">
          <ng-container *ngFor="let cred of credentials | async">
            <div class="col-md-6 col-xxl-3" *ngIf="cred.status == status || status == StatusType.ALL">
              <div class="card d-block card-fixed">
                <div class="card-background-img card-img-top" *ngIf="credentials_list[cred.id]?.image; else default" style="background-image: url('/assets/images/brands/{{credentials_list[cred.id]?.image}}.png'); background-position: center"></div>
                <ng-template #default><div class="card-background-img card-img-top" style="background-image: url('/assets/images/waves.png')"></div></ng-template>
                <div class="card-body pb-0">
                  <!-- project title-->
                  <h4 class="mt-0 text-title">{{ credentials_list[cred.id]?.display_name ?? cred?.display_name }}</h4>
                  <p class="text-muted font-13 mb-0 ph-1">{{ credentials_list[cred.id]?.description }}</p>
                  <a href="#" class="stretched-link" data-bs-toggle="modal" data-bs-target="#credential-modal" (click)="displayModal(cred)"></a>
                </div> <!-- end card-body-->
                <ul class="list-group list-group-flush">
                  <li class="list-group-item p-3">
                    <!-- status bar -->
                    <div class="progress progress-sm">
                      <div class="progress-bar" [class]="cred.status == StatusType.BROKEN ? 'bg-danger' : 'bg-success'" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;">
                      </div><!-- /.progress-bar -->
                    </div><!-- /.progress -->
                  </li>
                </ul>
              </div>
            </div> <!-- end col -->
          </ng-container>
          <div class="d-flex justify-content-center"
               *ngIf="!request_loading && ((status === StatusType.BROKEN && broken_count === 0) || status === StatusType.HIDDEN && hidden_count === 0)">
            <div class="col">
              <div class="text-center">
                <img src="/assets/images/file-searching.svg" height="90" alt="Search returned no results.">
                <h4 class="text-uppercase text-info mt-3">There are no credentials with the status
                  of {{status === StatusType.BROKEN ? 'Broken' : 'Hidden'}}</h4>
                <button class="btn btn-info mt-3" (click)="status = StatusType.ALL">Show All</button>
              </div>
            </div> <!-- end col -->
          </div>
        </ng-container>
        <ng-template #message>
          <div class="d-flex justify-content-center" *ngIf="!request_loading">
            <div class="col">
              <div class="text-center">
                <img src="/assets/images/file-searching.svg" height="90" alt="Search returned no results.">
                <h4 class="text-uppercase text-info mt-3">There are no credentials.</h4>
              </div>
            </div> <!-- end col -->
          </div>
        </ng-template>
      </div> <!-- end row -->

      <!-- start modal -->
      <div id="credential-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">

            <div class="modal-body">
              <div class="text-center mt-2 mb-4">
                <h3>{{ activeCredential.display_name }}</h3>
              </div>

              <form class="ps-3 pe-3">
                <div class="mb-3" [class.visually-hidden]="activeCredential.is_password_only">
                  <label for="username" class="form-label">Username</label>
                  <input class="form-control" type="email" name="username" autocomplete="false" required="required" placeholder="SystemUser1"
                         [(ngModel)]="activeCredential.username">
                </div>

                <div class="mb-3">
                  <label for="password" class="form-label">Password</label>
                  <input class="form-control" type="password" autocomplete="false" required="required" name="password" placeholder="Enter your password"
                         [(ngModel)]="activeCredential.password">
                </div>

                <div class="mb-3 float-end">
                  <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                  <button class="btn btn-primary mx-1" data-bs-dismiss="modal" type="button" (click)="onClickSave()" [disabled]="request_loading || !activeCredential.password || (!activeCredential.is_password_only && !activeCredential.username)">
                    <ngb-panel *ngIf="request_loading">
                      <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                      Updating...
                    </ngb-panel>
                    <ngb-panel *ngIf="!request_loading">
                      Update Credentials
                    </ngb-panel>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> <!-- end modal -->
    `,
  styles: [`
    .ph-1 {
      min-height: 1rem;
    }
    .z-1 {
      z-index: 100;
    }
    .card-background-img {
      height: 100px;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: #FAF9F6;
    }
    .card-fixed {
      height: 300px;
      min-width: 300px;
    }
    .list-group-flush {
      position: absolute;
      bottom: 0px;
      width: 100%;
    }
  `]
})
export class LocalCredentialsComponent implements OnInit {
  public readonly support_email = 'Support@DarkMatterIns.com';
  public failed_ids: string[];
  public request_loading: boolean;
  public credentials: Subject<any[]> = new BehaviorSubject<any[]>([]);
  public activeCredential: any = {display_name: null, username: null, is_password_only: false};
  public alerts: any[] = [];
  public StatusType = StatusType;
  public broken_count = 0;
  public hidden_count = 0;
  public readonly credentials_list = {
    'sql-default': {image: null, description: 'Your unique username and password combination which you use to sign in to AIM.', display_name: 'AIM Username & Password' },
    'rest-ategrity': {image: 'ategrity', description: 'Private value provided by the Ategrity development team. Contact your Project Manager or Dark Matter Account Manager for this value.', display_name: 'Ategrity Webservice API Key' },
    'rest-cd_pl': { image: 'clarion-door', description: 'Private username and password provided by the ClarionDoor Personal Lines development team. Contact your Project Manager or Dark Matter Account Manager for these values.', display_name: 'ClarionDoor Personal Lines Webservice Username & Password' },
    'rest-cdmgahub': { image: 'clarion-door', description: 'Private username and password provided by the ClarionDoor MGA Hub development team. Contact your Project Manager or Dark Matter Account Manager for these values.', display_name: 'ClarionDoor MGA Hub Webservice Username & Password' },
    'rest-markel_password_auth': { image: 'markel', description: 'Private username and password provided by the Markel development team. Contact your Project Manager or Dark Matter Account Manager for these values.', display_name: 'Markel Webservice Username & Password' },
    'rest-nationwide': { image: 'nationwide', description: 'Login credentials for pulling a quote from Nationwide.' },
    'rest-nautilus_auth': { image: 'nautilus', description: 'Private username and password provided by the Nautilus development team. Contact your Project Manager or Dark Matter Account Manager for these values.', display_name: 'Nautilus Webservice Username & Password' },
    'rest-pbs': {image: 'pbs', description: 'Private username and password provided by the Input1 development team. Contact your Project Manager or Dark Matter Account Manager for these values.', display_name: 'Input1 Webservice Username & Password' },
    'rest-western_world_auth': { image: 'western_world', description: 'Private username and password provided by the Western World development team. Contact your Project Manager or Dark Matter Account Manager for these values.', display_name: 'Western World Webservice Username & Password' }
  };
  public status = StatusType.BROKEN;

  constructor(private route: ActivatedRoute, private localCredentialsApiService: LocalCredentialsApiService, private googleAnalytics: GoogleAnalyticsService) {
    this.failed_ids = this.toLowerArrayItems(this.route.snapshot.queryParamMap.getAll('failed_credential_id'));
    this.request_loading = true;
    this.getCredentials();
  }

  ngOnInit(): void {
  }

  displayModal(credential: any): void {
    this.googleAnalytics.event('clicked_update', 'credentials', 'Clicked Update Credentials');
    this.activeCredential = credential;
  }

  onClickSave(): boolean {
    this.request_loading = true;
    this.googleAnalytics.event('clicked_save', 'credentials', 'Clicked Save Changes Button');
    if (this.activeCredential != null) {
      const payload = { username: this.activeCredential.username ?? this.activeCredential.id, password: this.activeCredential?.password };

      this.localCredentialsApiService.updateCredentials(this.activeCredential.id, payload).pipe(first(),
        timeout(10000),
        catchError(err => {
          if (err instanceof TimeoutError) {
            this.setAlert('error', 'Request timed out.');
          }
          this.request_loading = false;
          return throwError(err);
        }),
        mergeMap(response => {
          if (response) {
            this.setAlert('success', this.activeCredential.display_name);
            this.failed_ids = [];
          } else {
            this.setAlert('error', 'An error occurred while updating credentials.');
          }

          this.request_loading = false;
          this.activeCredential.password = null;
          this.activeCredential = { display_name: null, username: null, is_password_only: false };
          this.getCredentials();
          return of(true);
        }),
        catchError(_ => {
          this.request_loading = false;
          this.setAlert('error', 'An error occurred while updating credentials.');
          return of([]);
        })
      ).subscribe();
    }

    return false;
  }

  private getCredentials(): void {
    this.hidden_count = 0;
    this.broken_count = 0;
    this.localCredentialsApiService.listCredentials().pipe(first(), map((response: any) => {
      for (const value of response) {
        value.status = !value.exists || !value?.is_password_only && !value.username || this.failed_ids?.includes(value.id) ? StatusType.BROKEN : StatusType.HIDDEN;
        value.status === StatusType.BROKEN ? this.broken_count += 1 : this.hidden_count += 1;
      }

      this.request_loading = false;
      this.credentials.next(response);
    }),
      timeout(10000),
      catchError(err => {
        if (err instanceof TimeoutError) {
          this.setAlert('error', 'Request timed out.');
        } else {
          this.setAlert('error', 'An error occurred while retrieving credentials.');
        }
        this.request_loading = false;
        return throwError(err);
      }
    )).subscribe();
  }

  private toLowerArrayItems(all: string[]): string[] {
    const output = [];

    for (const val of all) {
      output.push(val.toLowerCase());
    }

    return output;
  }

  private setAlert(alert_type: string, alert_message: string): void {
    this.alerts.push({ type: alert_type, message: alert_message });
    setTimeout(() => this.alerts.splice(0, 1), 15000);
  }
}

export enum StatusType {
  BROKEN,
  ALL,
  HIDDEN
}
