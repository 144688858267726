import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Component({
  selector: 'app-function-details',
  template: `
    <div class="container-fluid">

      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <h4 class="page-title">Function: Import Quote</h4>
          </div>
        </div>
      </div>
      <!-- end page title -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-centered mb-0">
                  <thead class="thead-light">
                  <tr>
                    <th style="width: 20px;">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                        <label class="custom-control-label" for="customCheck1">&nbsp;</label>
                      </div>
                    </th>
                    <th>Order ID</th>
                    <th>Date</th>
                    <th>Payment Status</th>
                    <th>Total</th>
                    <th>Payment Method</th>
                    <th>Order Status</th>
                    <th style="width: 125px;">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck2">
                        <label class="custom-control-label" for="customCheck2">&nbsp;</label>
                      </div>
                    </td>
                    <td><a href="apps-ecommerce-orders-details.html" class="text-body font-weight-bold">#BM9708</a></td>
                    <td>
                      August 05 2018 <small class="text-muted">10:29 PM</small>
                    </td>
                    <td>
                      <h5><span class="badge badge-success-lighten"><i class="mdi mdi-coin"></i> Paid</span></h5>
                    </td>
                    <td>
                      $176.41
                    </td>
                    <td>
                      Mastercard
                    </td>
                    <td>
                      <h5><span class="badge badge-info-lighten">Shipped</span></h5>
                    </td>
                    <td>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                      <a class="action-icon" (click)="onEditClick()"> <i class="mdi mdi-square-edit-outline"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck3">
                        <label class="custom-control-label" for="customCheck3">&nbsp;</label>
                      </div>
                    </td>
                    <td><a href="apps-ecommerce-orders-details.html" class="text-body font-weight-bold">#BM9707</a></td>
                    <td>August 04 2018 <small class="text-muted">08:18 AM</small></td>
                    <td>
                      <h5><span class="badge badge-warning-lighten"><i class="mdi mdi-timer-sand"></i> Awaiting Authorization</span></h5>
                    </td>
                    <td>
                      $1,458.65
                    </td>
                    <td>
                      Visa
                    </td>
                    <td>
                      <h5><span class="badge badge-warning-lighten">Processing</span></h5>
                    </td>
                    <td>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck4">
                        <label class="custom-control-label" for="customCheck4">&nbsp;</label>
                      </div>
                    </td>
                    <td><a href="apps-ecommerce-orders-details.html" class="text-body font-weight-bold">#BM9706</a></td>
                    <td>August 04 2018 <small class="text-muted">10:29 PM</small></td>
                    <td>
                      <h5><span class="badge badge-success-lighten"><i class="mdi mdi-coin"></i> Paid</span></h5>
                    </td>
                    <td>
                      $801.99
                    </td>
                    <td>
                      Credit Card
                    </td>
                    <td>
                      <h5><span class="badge badge-warning-lighten">Processing</span></h5>
                    </td>
                    <td>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck5">
                        <label class="custom-control-label" for="customCheck5">&nbsp;</label>
                      </div>
                    </td>
                    <td><a href="apps-ecommerce-orders-details.html" class="text-body font-weight-bold">#BM9705</a></td>
                    <td>August 03 2018 <small class="text-muted">07:56 AM</small></td>
                    <td>
                      <h5><span class="badge badge-success-lighten"><i class="mdi mdi-coin"></i> Paid</span></h5>
                    </td>
                    <td>
                      $215.35
                    </td>
                    <td>
                      Mastercard
                    </td>
                    <td>
                      <h5><span class="badge badge-success-lighten">Delivered</span></h5>
                    </td>
                    <td>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck6">
                        <label class="custom-control-label" for="customCheck6">&nbsp;</label>
                      </div>
                    </td>
                    <td><a href="apps-ecommerce-orders-details.html" class="text-body font-weight-bold">#BM9704</a></td>
                    <td>May 22 2018 <small class="text-muted">07:22 PM</small></td>
                    <td>
                      <h5><span class="badge badge-danger-lighten"><i class="mdi mdi-cancel"></i> Payment Failed</span></h5>
                    </td>
                    <td>
                      $2,514.36
                    </td>
                    <td>
                      Paypal
                    </td>
                    <td>
                      <h5><span class="badge badge-danger-lighten">Cancelled</span></h5>
                    </td>
                    <td>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck7">
                        <label class="custom-control-label" for="customCheck7">&nbsp;</label>
                      </div>
                    </td>
                    <td><a href="apps-ecommerce-orders-details.html" class="text-body font-weight-bold">#BM9703</a></td>
                    <td>April 02 2018 <small class="text-muted">03:02 AM</small></td>
                    <td>
                      <h5><span class="badge badge-success-lighten"><i class="mdi mdi-coin"></i> Paid</span></h5>
                    </td>
                    <td>
                      $183.20
                    </td>
                    <td>
                      Payoneer
                    </td>
                    <td>
                      <h5><span class="badge badge-info-lighten">Shipped</span></h5>
                    </td>
                    <td>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck8">
                        <label class="custom-control-label" for="customCheck8">&nbsp;</label>
                      </div>
                    </td>
                    <td><a href="apps-ecommerce-orders-details.html" class="text-body font-weight-bold">#BM9702</a></td>
                    <td>March 18 2018 <small class="text-muted">11:19 PM</small></td>
                    <td>
                      <h5><span class="badge badge-warning-lighten"><i class="mdi mdi-timer-sand"></i> Awaiting Authorization</span></h5>
                    </td>
                    <td>
                      $1,768.41
                    </td>
                    <td>
                      Visa
                    </td>
                    <td>
                      <h5><span class="badge badge-warning-lighten">Processing</span></h5>
                    </td>
                    <td>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck9">
                        <label class="custom-control-label" for="customCheck9">&nbsp;</label>
                      </div>
                    </td>
                    <td><a href="apps-ecommerce-orders-details.html" class="text-body font-weight-bold">#BM9701</a></td>
                    <td>February 01 2018 <small class="text-muted">07:22 AM</small></td>
                    <td>
                      <h5><span class="badge badge-info-lighten"><i class="mdi mdi-cash"></i> Unpaid</span></h5>
                    </td>
                    <td>
                      $3,582.99
                    </td>
                    <td>
                      Paypal
                    </td>
                    <td>
                      <h5><span class="badge badge-info-lighten">Shipped</span></h5>
                    </td>
                    <td>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck10">
                        <label class="custom-control-label" for="customCheck10">&nbsp;</label>
                      </div>
                    </td>
                    <td><a href="apps-ecommerce-orders-details.html" class="text-body font-weight-bold">#BM9700</a></td>
                    <td>January 22 2018 <small class="text-muted">08:09 PM</small></td>
                    <td>
                      <h5><span class="badge badge-success-lighten"><i class="mdi mdi-coin"></i> Paid</span></h5>
                    </td>
                    <td>
                      $923.95
                    </td>
                    <td>
                      Credit Card
                    </td>
                    <td>
                      <h5><span class="badge badge-success-lighten">Delivered</span></h5>
                    </td>
                    <td>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck11">
                        <label class="custom-control-label" for="customCheck11">&nbsp;</label>
                      </div>
                    </td>
                    <td><a href="apps-ecommerce-orders-details.html" class="text-body font-weight-bold">#BM9699</a></td>
                    <td>January 17 2018 <small class="text-muted">02:30 PM</small></td>
                    <td>
                      <h5><span class="badge badge-success-lighten"><i class="mdi mdi-coin"></i> Paid</span></h5>
                    </td>
                    <td>
                      $5,177.68
                    </td>
                    <td>
                      Mastercard
                    </td>
                    <td>
                      <h5><span class="badge badge-info-lighten">Shipped</span></h5>
                    </td>
                    <td>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                      <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                    </td>
                  </tr>

                  </tbody>
                </table>
              </div> <!-- end table-responsive-->
            </div> <!-- end card body-->
          </div> <!-- end card -->
        </div> <!-- end col -->
      </div><!--end row-->
    </div><!-- end container-->
  `,
  styleUrls: []
})
export class FunctionDetailsComponent implements OnInit {

  constructor(private router: Router, private googleAnalytics: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
  }

  public onEditClick(): void {
    this.googleAnalytics.event('clicked_edit', 'dmbridge_function_detail', 'Clicked Edit Transposition Mapping');
    this.router.navigate(['/config/transposition-mappings']);
  }

}
