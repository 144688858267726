import {TransposableValue} from './transposable-value';
import {HasMergeableChildren} from './HasMergeableChildren';
import {IdentifiableRecord} from './identifiable-record';

export class TranspositionCategory extends HasMergeableChildren<TransposableValue> implements IdentifiableRecord {
  private noneditable_ids: { [key: string]: TransposableValue } = {};

  constructor(public readonly id: string, public readonly is_left_editable: boolean, public readonly transpositions: TransposableValue[]) {
    super(transpositions);
    this.buildStructures(transpositions);
  }

  static ensure(input: TranspositionCategory): TranspositionCategory {
    const id = input.id;
    const is_left_editable = input.is_left_editable;
    const attachedTranspositions = input.transpositions;

    return input instanceof TranspositionCategory ? input : new TranspositionCategory(id, is_left_editable, attachedTranspositions);
  }

  protected ensureChildType(input: TransposableValue): TransposableValue {
    return TransposableValue.ensure(input, this.is_left_editable);
  }

  protected mergeChild(original: TransposableValue, supersede: TransposableValue): void {
    if (original && supersede) {
      original.left_value = supersede.left_value;
      original.right_value = supersede.right_value;
    }
  }

  mergeChildren(input: TransposableValue[], is_default?: boolean): void {
    this.buildStructures(this.children);

    if (input) {
      for (const item of input) {
        const child = this.ensureChildType(item);
        if (!this.noneditable_ids[child.noneditable_id]){
          this.noneditable_ids[child.noneditable_id] = child;
          this.children_dictionary[child.id] = child;
          this.children.push(child);
        } else if (!this.children_dictionary[child.id] && !is_default) {
          this.children_dictionary[child.id] = child;
          this.children.push(child);
        }
      }
    }
  }

  protected buildStructures(input: TransposableValue[]): void {
    if (input) {
      this.noneditable_ids = {};
      this.children_dictionary = {};
      for (let x = 0; x < input.length; x++) {
        const record = this.ensureChildType(input[x]);
        this.children_dictionary[record.id] = record;
        this.noneditable_ids[record.noneditable_id] = record;
        input[x] = record;
      }
    } else {
      input = [];
    }

    this.children = input;
  }

  public toPersistableClone(): TranspositionCategory {
    const values: TransposableValue[] = [];

    for (const value of this.children) {
      values.push(value.toPersistableClone());
    }

    const output = new TranspositionCategory(this.id, this.is_left_editable, values);
    delete output.children;
    delete output.noneditable_ids;
    delete output.children_dictionary;

    return output;
  }
}
