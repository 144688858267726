import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {routes} from '../app-routing.module';
import {from, Observable, of} from 'rxjs';
import {PluginLocalStorageService} from '../plugin-local-storage.service';
import {WorkflowResult} from '../dm-connect-api.service';

@Injectable({
  providedIn: 'root'
})
export class WorkflowResponseHandler {
  private static readonly AVAILABLE_ROUTES = new Map<string, { success_route, failure_route }>();

  constructor(private location: Location, private localStorage: PluginLocalStorageService, private router: Router) {
    this.buildRouteStructure();
  }

  private buildRouteStructure(): void {
    if (WorkflowResponseHandler.AVAILABLE_ROUTES && WorkflowResponseHandler.AVAILABLE_ROUTES.size > 0) {
      return;
    }

    for (const x of routes) {
      const record = { success_route: null, failure_route: null };
      const parent_path = this.normalizeUrl(x.path);
      WorkflowResponseHandler.AVAILABLE_ROUTES.set('/' + parent_path, record);

      for (const c of x.children ?? []){
        let child_path = parent_path;
        child_path += this.normalizeUrl(c.path);

        if (c.data?.is_success) { record.success_route = child_path; }
        if (c.data?.is_error) { record.failure_route = child_path; }

        WorkflowResponseHandler.AVAILABLE_ROUTES.set('/' + child_path, record);
      }
    }
  }

  private normalizeUrl(input: string): string{
    let output = input?.toLowerCase();
    output = output.split('?')[0];
    output = output.replace(/(\/$|$)/i, '/');
    return output;
  }

  public handle(workflow_name: string, response: any): Observable<WorkflowResult> {
    const data = WorkflowResponseHandler.AVAILABLE_ROUTES.get(this.normalizeUrl(this.location.path()));

    if (response?.error && data?.failure_route) {
      const workflow_id = response.error.workflow_id;
      const error_message = response.error.message;
      this.localStorage.saveError({ workflow_id, error_message });
      return from(this.router.navigate([data?.failure_route])
        .then(() => new WorkflowResult(false, response)));
    } else if (response?.success && data?.success_route) {
      this.localStorage.saveSuccess(response.success);
      return from(this.router.navigate([data?.success_route], { queryParams:
          { submission_ids: Object.keys(response.success), workflow_name }}).then(() => new WorkflowResult(true, response)));
    } else if (!data?.failure_route || !data?.success_route ) {
      console.log('No route is configured for success/failure.');
    }

    return of(new WorkflowResult(!response?.error, response));
  }
}
