import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {WorkflowService} from '../workflows/workflow-service.service';
import {map, retry} from 'rxjs/operators';
import {DmConnectImportPlugin} from '../../DmConnectImportPlugin';
import {HttpParams} from '@angular/common/http';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Component({
  selector: 'app-root',
  template: `
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Personal Lines Processor</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div aria-live="polite" aria-atomic="true" role="alert" class="position-relative z-1">
        <div class="toast-container px-2 mb-2 position-absolute end-0">
          <ng-container *ngFor="let alert of alerts; let i = index">
            <ng-container [ngSwitch]="alert.type">
              <div class="toast fade show bg-success text-white d-flex" *ngSwitchCase="'success'">
                <div class="toast-body">
                  <span class="fw-bold">{{alert.message}}</span>
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" (click)="alerts.splice(i, 1)" aria-label="Close"></button>
              </div>
              <div class="toast fade show bg-danger text-white d-flex" *ngSwitchCase="'error'">
                <div class="toast-body">
                  <h4 class="me-auto">Error While Updating Credentials</h4>
                  <p class="my-2 pt-2 border-top">{{alert.message}}</p>
                  <p class="mb-0">If the error persists please contact us at
                    <a class="text-white text-decoration-underline" href="mailto:{{support_email}}">{{support_email}}</a>.</p>
                </div>
                <button type="button" class="btn-close btn-close-white me-2 mt-2" (click)="alerts.splice(i, 1)" aria-label="Close"></button>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div class="col-12">
        <div class="card card-fixed d-block">
          <div class="card-body">
            <h5 class="card-title">File Select</h5>

            <form>
              <table class="table">
                <tbody app-loading-shimmer [loading]="this.status == LoadingStatus.LOADING_FILES" [columns]="3">
                <tr *ngFor="let option of file_options | async">
                  <td class="col-1"><input type="radio" name="option_{{option.name}}" value="{{option.path}}" [(ngModel)]="selected_path"/>
                  </td>
                  <td>{{option.name}}</td>
                </tr>
                </tbody>
              </table>

              <div class="col-12 text-end">
                <button class="btn btn-primary" (click)="onSelectFile()" [disabled]="this.selected_path == undefined || this.status == 2">
                  <span [hidden]="this.status != LoadingStatus.WAITING_FOR_PORTAL">
                    <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> Loading...
                  </span>
                  <span [hidden]="this.status == LoadingStatus.WAITING_FOR_PORTAL">Import</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .z-1 {
      z-index: 100;
    }
  `]
})

export class FtpPlProcessorComponent implements OnInit
{
  public readonly support_email = 'Support@DarkMatterIns.com';
  public file_options: Observable<File[]>;
  public selected_path: string;
  public status: LoadingStatus;
  public alerts: {type: string, message: string}[] = [];
  public LoadingStatus = LoadingStatus;

  constructor(private workflow_service: WorkflowService, private google: GoogleAnalyticsService) {}

  ngOnInit(): void
  {
    this.file_options = this.getFiles();
  }

  getFiles(): Observable<File[]>
  {
    this.status = LoadingStatus.LOADING_FILES;
    return this.workflow_service.execute('lookup', { headers: {'Cache-Control': 'no-cache'}, params: new HttpParams().set('lookup_type', 'directory') }, 'ams/', 'GET').pipe(retry(3),
      map(r =>
      {
        const response = r.output.items;

        if (response?.length > 0) {
          response.sort((a, b) => Date.parse(b.modified_at) - Date.parse(a.modified_at));

          this.status = LoadingStatus.SELECTING_FILE;
          return response;
        }
      })
    );
  }

  async onSelectFile(): Promise<void> {
    this.status = LoadingStatus.WAITING_FOR_PORTAL;
    this.google.event('clicked_import', 'ftp-pl', 'Clicked Import');
    // @ts-ignore
    const dmconnect = new DmConnectImportPlugin('ftp_pl', 'ftp', 'id');

    const path_components = this.selected_path.split('\\');
    const response = await dmconnect.inlineTrigger({
      id: path_components[path_components.length - 1].split('.')[0],
      file_path: this.selected_path
    });

    switch (response) {
      case 'NO_DMCONNECT':
        this.addToast('error', 'Could not find DmConnect.');
        this.google.event('response_nodmconnect', 'ftp-pl', 'Could not connect to DmConnect');
        break;
      case 'CORRELATED':
      case 'NOT_CORRELATED':
        this.addToast('success', `Successfully imported submission.`);
        this.google.event('response_ok', 'ftp-pl', 'DmConnect successfully imported');
        break;
      default:
        this.addToast('error', 'An unexpected error occurred.');
        this.google.event('response_error', 'ftp-pl', 'An unexpected error occurred');
        break;
    }
    this.file_options = this.getFiles();
  }

  private addToast(type: string, message: string): void
  {
    this.alerts.push({type, message});
    setTimeout(() => this.alerts.splice(0, 1), 15000);
  }
}

class File
{
  name: string;
  path: string;
}

enum LoadingStatus
{
  LOADING_FILES,
  SELECTING_FILE,
  WAITING_FOR_PORTAL
}
