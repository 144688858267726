import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from '@auth0/auth0-angular';
import {concatMap} from 'rxjs/operators';
import {PluginLocalStorageService} from '../plugin-local-storage.service';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService, private localStorage: PluginLocalStorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    const dmLuid = this.localStorage.readDmLuid();
    const url = req.url;

    if ((url.includes('localhost:8080') || url.includes('127.0.0.1:8080')) && dmLuid) {
      const luidReq = req.clone({
        headers: req.headers.set('Authorization', 'DmConnect ' + dmLuid)
      });
      return next.handle(luidReq);
    }
    else if (req.headers.has('X-DM-Authorize')) {
      return this.auth.getAccessTokenSilently().pipe(concatMap(token => {
        const authReq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`).delete('X-DM-Authorize')
        });
        return next.handle(authReq);
      }));
    }
    return next.handle(req);
  }
}
