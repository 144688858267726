import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {DmConnectPackageOptions} from '../dm-connect-package-options';
import {ConfigurationOption} from '../configuration-option';
@Component({
  selector: 'app-clariondoor-config',
  template: `
    <div *ngIf="is_clariondoor_selected" class="card d-block">
      <div class="card-body">
        <h5 class="card-title mb-3">Clarion Door</h5>
        <div class="form-check mb-3">
          <input type="checkbox" class="form-check-input" id="useActvUser" [(ngModel)]="uar_use_active_user" (change)="clariondoorConfigChanged()">
          <label class="form-check-label" for="useActvUser"> Use active user on push instead of Account Executive</label>
        </div>
        <div class="input-group mb-3">
          <label class="input-group-text" for="prodUrl">Production</label>
          <input type="text" class="form-control" id="prodUrl" [(ngModel)]="cdmgahub_production_url"
                 (change)="clariondoorConfigChanged()" placeholder="https://darkmatter.hub.clariondoor.com">
        </div>
        <div class="input-group mb-3">
          <label class="input-group-text" for="devUrl">Testing</label>
          <input type="text" class="form-control" id="devUrl" [(ngModel)]="cdmgahub_development_url"
                 (change)="clariondoorConfigChanged()" placeholder="https://darkmatter-dev.hub.clariondoor.com">
        </div>
        <p class="text-muted mb-2 font-13 fw-bold">Markets</p>
        <div class="form-check form-check-inline mb-3" *ngFor="let market of markets_array">
          <input type="checkbox" class="form-check-input" id="market_{{market.id}}"
                 (click)="this.addOrRemoveFromArray(this.selected_markets, market.id)"
                 [checked]="selected_markets.includes(market.id)">
          <label class="form-check-label" for="market_{{market.id}}"> {{market.name}}</label>
        </div>
      </div> <!-- end card-body-->
    </div> <!-- end card-->
  `,
  styles: [`
    .input-group-text { min-width: 9em }
  `]
})

export class ClariondoorConfigComponent implements OnInit {
  public uar_use_active_user: boolean;
  public cdmgahub_production_url: string;
  public cdmgahub_development_url: string;
  public markets_array;
  public selected_markets: string[] = [];
  public is_clariondoor_selected = false;
  @Input() packageOptions: Subject<DmConnectPackageOptions>;
  @Output() onChange = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
    this.markets_array = this.populateMarkets();
    this.packageOptions.subscribe((next) => {
      this.is_clariondoor_selected = next.application_interface_ids.includes('cd_mgahub');
      this.uar_use_active_user = next.uar_use_active_user;
      this.cdmgahub_development_url = next.cdmgahub_development_url;
      this.cdmgahub_production_url = next.cdmgahub_production_url;
      if (next.markets){
        this.selected_markets = next.markets.split(',');
      } else {
        this.selected_markets = this.markets_array.map(market => market.id);
        this.clariondoorConfigChanged();
      }
    });
  }

  private populateMarkets(): Array<ConfigurationOption> {
    const markets = [
      {id: 'markel', name: 'Markel'},
      {id: 'nautilus', name: 'Nautilus'},
      {id: 'usli', name: 'USLI'},
      {id: 'atlantic', name: 'Atlantic Casualty'},
      {id: 'centurysurety', name: 'Century Surety'},
      {id: 'hallmark', name: 'Hallmark'},
      {id: 'westchester', name: 'Westchester / Chubb'},
      {id: 'nationwide2', name: 'Nationwide 2'},
      {id: 'rsui', name: 'RSUI'},
      {id: 'northfield', name: 'Northfield'},
      {id: 'hudson', name: 'Hudson'},
      {id: 'ifg', name: 'Burlington IFG'},
      {id: 'pennamerica', name: 'Penn-America'},
      {id: 'ategrity', name: 'Ategrity'},
      {id: 'genstar', name: 'GenStar'},
      {id: 'crumforster', name: 'Crum & Forster'},
      {id: 'westernworld', name: 'Western World'},
      {id: 'amtrust', name: 'AmTrust'},
      {id: 'greatamericanrs', name: 'Great American Risk Solutions'},
      {id: 'iat', name: 'IAT Specialty'},
      {id: 'jamesriver', name: 'James River'},
      {id: 'music', name: 'MUSIC'},
      {id: 'ufcic', name: 'UFCIC'},
      {id: 'maxum', name: 'The Hartford E&S Binding'},
      {id: 'coaction', name: 'Coaction'},
      {id: 'coterie', name: 'Coterie'},
      {id: 'starstone', name: 'Starstone'}
    ];

    return markets.sort((a, b)  => a.name.localeCompare(b.name));
  }

  public addOrRemoveFromArray(array, value): void {
    const index = array.findIndex(m => m === value);
    if (index !== -1) {
      array.splice(index, 1);
    } else {
      array.push(value);
    }
    this.clariondoorConfigChanged();
  }

  clariondoorConfigChanged(): void {
    this.onChange.emit({
      uar_use_active_user: this.uar_use_active_user,
      cdmgahub_production_url: this.cdmgahub_production_url,
      cdmgahub_development_url: this.cdmgahub_development_url,
      markets: this.selected_markets.join(',')
    });
  }
}
