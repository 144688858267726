import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-workflow',
  template: `
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <h4 class="page-title">Create Workflow</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-8">
                  <h4>Workflow Details</h4>
                  <form>
                    <div class="row g-2">
                      <div class="mb-3 col-md-12">
                        <label for="inputEmail4" class="form-label">Workflow Id</label>
                        <input type="text" class="form-control" id="inputEmail4" placeholder="ID">
                      </div>
                    </div>
                    <div class="row g-2">
                      <div class="mb-3 col-md-12">
                        <label for="example-fileinput" class="form-label">Workflow Icon</label>
                        <input type="file" id="example-fileinput" class="form-control">
                      </div>
                    </div>
                    <div class="row g-2">
                      <div class="mb-3 col-md-12">
                        <label for="inputEmail5" class="form-label">Workflow Description</label>
                        <input type="text" class="form-control" id="inputEmail5" placeholder="Description">
                      </div>
                    </div>

                    <div class="row g-2">
                      <div class="mb-3 col-md-6">
                        <label for="inputEmail6" class="form-label">Launch Point</label>
                        <select id="inputEmail6" class="form-control select2" data-toggle="select2" >
                          <option>Select</option>
                          <option>Portal</option>
                          <option>Browser</option>
                          <option>Agency Management System</option>
                        </select>
                      </div>
                      <div class="mb-3 col-md-6">
                        <label for="assignedUsers" class="form-label">Assigned Users</label>
                        <select id="assignedUsers"  class="select2 form-control select2-multiple" data-toggle="select2" multiple="multiple" data-placeholder="Choose ...">
                          <option>Division: North West</option>
                          <option>Team: Accounting</option>
                          <option>User: J. Clarkson</option>
                        </select>
                      </div>
                    </div>

                    <button type="submit" class="btn btn-primary">Create</button>
                  </form>
                </div>
                <div class="col-lg-4">
                  <h4>Workflow Steps</h4>
                  <div class="list-group">
                    <a href="#" class="list-group-item list-group-item-action">
                      Accept Data
                    </a>
                    <a href="#" class="list-group-item list-group-item-action">
                      Push to Clarion Door
                    </a>
                  </div>
                  <br />
                  <button type="submit" class="btn btn-primary">Add Step</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [``]
})
export class CreateWorkflowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
