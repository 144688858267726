import {Component, OnInit} from '@angular/core';
import {PluginLocalStorageService} from '../plugin-local-storage.service';
import {DmconnectBrowserService} from '../dmconnect-browser.service';

@Component({
  selector: 'app-success',
  template: `
    <div class="alert alert-success" role="alert">
      <p>The data from this import has been placed in the following AMS records. Tip: You can click on the desired record to copy the value into your clipboard.</p>
      <ul>
        <li *ngFor="let id of submission_ids" data-bs-container="#tooltip-container" data-bs-toggle="tooltip" title="Copy to clipboard" (click)="copy_id(id)"><i class="uil uil-copy"></i> {{ id }}</li>
      </ul>
    </div>
  `,
  styles: [`
     ul {
       list-style-type: none;
     }

     li:hover {
       cursor: pointer;
       text-decoration: underline;
     }
  `]
})
export class SuccessComponent implements OnInit {
  public submission_ids: string[];

  constructor(private pluginLocalStorage: PluginLocalStorageService, private browserService: DmconnectBrowserService) {}

  ngOnInit(): void {
    const success = this.pluginLocalStorage.readSuccess();
    this.submission_ids = Object.keys(success);

    const me = this;
    setTimeout(() => { me.browserService.closeWindow(); }, 10000);
  }

  copy_id(id: string): void{
    const listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (id));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }
}
