import { Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkflowService} from '../workflows/workflow-service.service';
import {DmconnectBrowserService} from '../dmconnect-browser.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {ODataSearchResponse} from '../ODataSearchResponse';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, first, map, retry} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {WorkflowResult} from '../dm-connect-api.service';

@Component({
  selector: 'app-aim-screens',
  template: `
    <div class="container-fluid">
      <app-loading-indicator loading_text="Checking for Related Submissions..." [is_loading]="this.is_loading"></app-loading-indicator>
      <ng-template [ngIf]="!this.is_loading">
        <form>
          <table class="table mb-0">
            <thead>
            <tr>
              <th>Submission ID</th>
              <th>Insured Name</th>
              <th>Coverage</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let submission of related_submissions | keyvalue; index as i">
              <tr class="align-middle">
                <td>{{submission.key}}</td>
                <td>{{submission.value.insured}}</td>
                <td>{{submission.value.coverage}}</td>
              </tr>
              <tr>
                <td colspan="3" class="bg-lt px-5 py-0">
                  <table class="table mb-0">
                    <tbody>
                    <ng-container *ngFor="let value of submission.value.submissions;" class="form-group">
                      <tr class="border-white">
                        <td *ngIf="value.hasPremium()"><input type="radio" name="customRadio{{i}}" [(ngModel)]="submission.value.selected_version" value="{{value.version}}"></td>
                        <td *ngIf="!value.hasPremium()"><input type="radio" name="customRadio{{i}}" disabled="disabled"></td>
                        <td>Version {{value.version}}</td>
                        <td *ngIf="value.hasPremium()">Premium <strong>{{value.premium | currency:'USD':'symbol':'1.0-0'}}</strong></td>
                        <td *ngIf="!value.hasPremium()"><strong>No Premium</strong></td>
                        <td><span *ngIf="value.company">{{value.company}} ({{value.company_id}})</span></td>
                      </tr>
                    </ng-container>
                    <tr>
                      <td>
                        <input type="radio" name="customRadio{{i}}" [(ngModel)]="submission.value.selected_version" value="">
                      </td>
                      <td class="fw-bold">Exclude from Premium Finance
                      </td>
                      <td></td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
          <div class="col col-sm-4" style="width: 100%; display: flex; justify-content: right;">
            <button type="button" class="btn btn-primary btn-block mt-4" (click)="complete()" [disabled]="is_generating_quote">
              <span *ngIf="is_generating_quote" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Generate
              Finance Agreement
            </button>
          </div>
        </form>
      </ng-template>
    </div>
  `,
  styles: [`
    .table {
      --bs-table-bg: #eef2f7;
      --bs-table-accent-bg: transparent;
    }
    .table .table, .table thead {
      --bs-table-bg: #fff !important;
      --bs-table-accent-bg: transparent;
    }
    .bg-lt {
      background-color: #fff !important;
    }
  `]
})
export class SelectSubmissionComponent implements OnInit{

  // @ts-ignore
  private readonly SERVICE_URL = Dmc.API_ADDRESS;
  public is_loading = true;
  public related_submissions: any = {};
  public is_generating_quote = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private workflowService: WorkflowService,
              private dmConnectBrowser: DmconnectBrowserService,
              private googleAnalytics: GoogleAnalyticsService,
              private http: HttpClient) {
  }

  private submissionId = this.route.snapshot.queryParamMap.get('submission_id').toUpperCase();
  private version = this.route.snapshot.queryParamMap.get('version').toUpperCase();

  ngOnInit(): void {
    this.getRelatedSubmissions(this.submissionId, this.version)
      .pipe(first(), map( (hasErrors) => {
        if (Object.keys(this.related_submissions).length === 0 || hasErrors === true){
          return this.sendDefault();
        }
      }), catchError(() => this.sendDefault())).subscribe();
  }

  complete(): void {
    this.googleAnalytics.event('generate_agreement', 'tsga_product_landing', 'Generate Finance Agreement');
    this.is_generating_quote = true;
    const submissionsToSend = [];

    for (const [s] of Object.entries(this.related_submissions)) {
      if (this.related_submissions[s].selected_version) {
        submissionsToSend.push({ submission_id: s, version: this.related_submissions[s].selected_version });
      }
    }

    if (submissionsToSend.length) {
      this.workflowService.execute('input1', { submissions: submissionsToSend }).pipe(first(), map(() => {
        this.is_generating_quote = false;
      })).subscribe();
    } else {
      this.is_generating_quote = false;
    }
  }

  private getRelatedSubmissions(submission_id: string, version: string): Observable<any>{
    const options = { headers: {'Cache-Control': 'no-cache'}, params: new HttpParams().set('lookup_type', 'related_submissions').set('submission_id', submission_id)};
    return this.http
      .get<ODataSearchResponse<{ submission_id: string, insured: string, coverage: string, version: string, premium: number | undefined, company: string, company_id: string}>>
    (this.SERVICE_URL + 'ams/lookup', options).pipe(retry(3), map(response => {
      if (response.error) {
        return true;
      }

      if ( response && response.items ){
        for (const item of response.items) {
          const submission = Submission.loadFromDto(item);
          if (!this.related_submissions.hasOwnProperty(submission.submission_id)) {
            this.related_submissions[submission.submission_id] = {
              insured: submission.insured,
              coverage: submission.coverage,
              company: submission.company,
              company_id: submission.company_id,
              selected_version: submission_id === submission.submission_id.toUpperCase() && submission.hasPremium() ? version : '',
              submissions: []};
          }
          this.related_submissions[submission.submission_id].submissions.push(submission);
        }
        this.is_loading = false;
      }

      return false;
    }));
  }

  private sendDefault(): Observable<WorkflowResult> {
    return this.workflowService.execute('input1', { submissions: { ...this.route.snapshot.queryParams } });
  }
}

class Submission
{
  public submission_id: string;
  public insured: string;
  public coverage: string;
  public version: string;
  public premium: number | undefined;
  public company: string;
  public company_id: string;

  static loadFromDto(s: { submission_id: string, insured: string, coverage: string, version: string, premium: number | undefined, company: string, company_id: string}):
    Submission {
    const output = new Submission();
    output.submission_id = s.submission_id;
    output.insured = s.insured;
    output.coverage = s.coverage;
    output.version = s.version;
    output.premium = s.premium;
    output.company = s.company;
    output.company_id = s.company_id;

    return output;
  }

  hasPremium(): boolean{
    return this.premium && this.premium > 0;
  }
}


