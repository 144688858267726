import { Component, OnInit } from '@angular/core';
import {from, Observable, of} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {DmconnectBrowserService} from '../dmconnect-browser.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {HttpClient} from '@angular/common/http';
import {WorkflowService} from '../workflows/workflow-service.service';
import {FeaturesService} from '../features.service';
import {catchError, concatMap, first, map, retry, switchMap} from 'rxjs/operators';
import {ODataSearchResponse} from '../ODataSearchResponse';
import {StagingBucket} from './stagingBucket/StagingBucket';

@Component({
  selector: 'app-interface-configuration',
  template: `<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Staging Bucket Management</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
      <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-xl-8">
              </div>
              <div class="col-xl-4">
                <div class="text-xl-end mt-xl-0 mt-2">
                  <button [disabled]="!h_create_staging_bucket" routerLink="create" (click)="googleAnalytics.event('clicked_create', 'dmbridge_staging_buckets', 'Clicked Create Staging Bucket')"
                          class="btn btn-danger mb-2 me-2"><i class="uil-plus me-1"></i> Create Staging Bucket</button>
                </div>
              </div><!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-striped dt-responsive nowrap w-100 mb-0">
                <thead class="table-light">
                <tr>
                  <th style="width: 20px;">
                  </th>
                  <th>ID</th>
                  <th>Reference ID</th>
                  <th>Name</th>
                  <th>Columns</th>
                  <th>Active/Inactive</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="!this.is_loading && !this.hasStagingBuckets">
                  <td colspan="6">
                    <span>No staging buckets found. Try broadening your search.</span>
                  </td>
                </tr>
                <ng-container *ngIf="this.is_loading">
                  <tr>
                    <td colspan="6" style="height: 50px; padding-top: 5px; padding-bottom: 0px; margin-bottom: 0px;">
                      <div class="shine" style="width: 100%; height: 75%;"></div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="6" style="height: 50px; padding-top: 5px; padding-bottom: 0px; margin-bottom: 0px;">
                      <div class="shine" style="width: 100%; height: 75%;"></div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="6" style="height: 50px; padding-top: 5px; padding-bottom: 0px; margin-bottom: 0px;">
                      <div class="shine" style="width: 100%; height: 75%;"></div>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngFor="let value of staging_bucket_list | async; let i = index">
                <tr>
                  <td>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="customCheck{{i}}">
                      <label class="form-check-label" for="customCheck{{i}}">&nbsp;</label>
                    </div>
                  </td>
                  <td><a href="#" class="text-body fw-bold">{{value.id}}</a> </td>
                  <td>
                    {{value.reference_id}}
                  </td>
                  <td>
                      {{value.name}}
                  </td>
                  <td>
                      {{value.columns}}
                  </td>
                  <td class="max-w">
                    <input type="checkbox" id="switch{{i}}" [checked]="value.is_active" data-switch="success" [disabled]="!value.h_toggle_active" (change)="updateIsActive(value, $event)"/>
                    <label for="switch{{i}}" [attr.data-on-label]="'Active'" [attr.data-off-label]="'Inactive'"></label><i *ngIf="value.displayErrorMessage" class="dripicons-warning text-danger align-top mx-1"></i>
                    <p *ngIf="value.displayErrorMessage" class="text-danger m-0"> An error occurred while updating the staging bucket.</p>
                  </td>
                </tr>
                </ng-container>
                </tbody>
              </table>
            </div>
          </div> <!-- end card-body-->
        </div> <!-- end card-->
      </div> <!-- end col -->
    </div>
    <!-- end row -->
  </div>`,

  styles: [`
    .shine {
      background-image: linear-gradient(to right, #f1f3fa00 0%, #dfe3f0 50%, #f1f3fa00 100%);
      background-repeat: no-repeat;
      background-size: 40% 100%;
      display: inline-block;
      position: relative;

      animation-play-state: running;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: shimmer;
      animation-timing-function: linear;
    }

    @keyframes shimmer {
      0% {
        background-position: -100% 0;
      }
      100% {
        background-position: 200% 0;
      }
    }
    input[data-switch] + label {
      width: 75px !important;
    }
    input[data-switch]:checked + label:after {
      left: 53px !important;
    }
    .max-w {
      width: 215px;
    }
  `]
})

export class StagingBucketListComponent implements OnInit {
  public is_loading = true;
  public staging_bucket_list: Observable<StagingBucket[]>;
  public h_create_staging_bucket: string;
  public hasStagingBuckets = true;
  constructor(private router: Router,
              private route: ActivatedRoute,
              private dmConnectBrowser: DmconnectBrowserService,
              public googleAnalytics: GoogleAnalyticsService,
              private http: HttpClient,
              private workflowService: WorkflowService,
              private features: FeaturesService) { }

  ngOnInit(): void {
    this.staging_bucket_list = this.features.getFeatures().pipe(
      switchMap(features => {
        if (!features.h_search_staging_buckets) {
          return from(this.router.navigate(['/config/error/403'])).pipe(map(() => null));
        }
        return this.http.get<ODataSearchResponse<StagingBucket>>
        (features.h_search_staging_buckets, {headers: {'Cache-Control': 'no-cache', 'X-DM-Authorize': 'management_api'}}).pipe(retry(3));
      }),
      map(response => {
        if (response.items.length === 0){
          this.hasStagingBuckets = false;
        }
        this.is_loading = false;
        this.h_create_staging_bucket = response.h_create_entity;
        return response.items;
      })
    );
  }

  updateIsActive(bucket: StagingBucket, toggle: any): void {
    this.googleAnalytics.event('clicked_is_active', 'dmbridge_staging_buckets', 'Updated Is Active For Staging Bucket');
    const isActive = toggle.target.checked;
    bucket.is_active = isActive;
    this.http.post(bucket.h_toggle_active, bucket, { observe: 'response', headers: {'X-DM-Authorize': 'management_api'}})
      .pipe(first(), concatMap(response => {
        if (response) {
          bucket.displayErrorMessage = false;
          return of(true);
        } else {
          bucket.is_active = !isActive;
          bucket.displayErrorMessage = true;
          return of(false);
        }
      }), catchError(async () => {
        bucket.is_active = !isActive;
        bucket.displayErrorMessage = true;
        return false;
      })).subscribe();
  }
}
