import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {DmConnectPackageOptions} from '../dm-connect-package-options';

@Component({
  selector: 'app-tsga-invoice-bridge-config',
  template: `
    <div *ngIf="is_tsga_invoicebridge_selected" class="card d-block">
      <div class="card-body">
        <h5 class="card-title mb-3">TSGA Invoice Bridge</h5>
        <div class="input-group mb-3">
          <label class="input-group-text" for="processedXmlFolder">Processed XML Folder</label>
          <input type="text" class="form-control" id="processedXmlFolder" [(ngModel)]="processed_xml_folder"
                (change)="tsgaConfigChanged()" placeholder="\\\\fileshare\\dm\\processed-xml">
        </div>
        <div class="input-group mb-3">
          <label class="input-group-text" for="unprocessedXmlFolder">Unprocessed XML Folder</label>
          <input type="text" class="form-control" id="unprocessedXmlFolder" [(ngModel)]="unprocessed_xml_folder"
                 (change)="tsgaConfigChanged()" placeholder="\\\\fileshare\\dm\\unprocessed-xml">
        </div>
      </div> <!-- end card-body-->
    </div> <!-- end card-->
  `,
  styles: [`
    .input-group-text {
        min-width: 14em;
    }
  `]
})

export class TsgaInvoiceBridgeConfigComponent implements OnInit {
  public processed_xml_folder: string;
  public unprocessed_xml_folder: string;
  public is_tsga_invoicebridge_selected: boolean;
  @Input() packageOptions: Subject<DmConnectPackageOptions>;
  @Output() onChange = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
    this.packageOptions.subscribe((next) => {
      this.is_tsga_invoicebridge_selected = next.application_interface_ids.includes('tsga_invoice_bridge');
      this.processed_xml_folder = next.processed_xml_folder;
      this.unprocessed_xml_folder = next.unprocessed_xml_folder;
    });
  }

  public tsgaConfigChanged(): void {
    this.onChange.emit({
      processed_xml_folder: this.processed_xml_folder,
      unprocessed_xml_folder: this.unprocessed_xml_folder
    });
  }
}
