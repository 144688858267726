import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DmConnectApiService, WorkflowResult} from '../dm-connect-api.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {DmconnectBrowserService} from '../dmconnect-browser.service';
import {catchError, mergeMap, timeout} from 'rxjs/operators';
import {Observable, of, TimeoutError} from 'rxjs';
import {WorkflowService} from '../workflows/workflow-service.service';
import {WorkflowResponseHandler} from '../workflows/workflow-response-handler.service';

@Component({
  selector: 'app-aim-screens',
  template: `
      <app-loading-indicator loading_text="Preparing your submission for markets..." [is_loading]="!this.correlationId || !this.enablePull"></app-loading-indicator>
      <ng-container *ngIf="this.correlationId && this.enablePull">
        <p>This submission has already been sent to ClarionDoor. Would you like to bring the indication into AIM?</p>
        <div class="dmc-button-panel">
          <button type="button" class="btn btn-primary" (click)="pullIndication()" [disabled]="this.request_loading">
            <ngb-panel *ngIf="this.request_loading">
              <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
              Loading...
            </ngb-panel>
            <ngb-panel *ngIf="!this.request_loading">
                Yes
            </ngb-panel>
          </button>&nbsp;
          <button type="button" class="btn btn-light" (click)="continueWorking()">No, continue working in ClarionDoor</button>
        </div>
      </ng-container>
  `,
  styles: []
})
export class AimClarionDoorComponent implements OnInit {
  private readonly clarionDoorHost: string;
  private submissionId: string;
  private dataSource: string;
  private quoteId: string;
  private version: string;
  private policyId: string;
  private userId: string;
  public correlationId: string;
  public enablePull: boolean;
  public error_message: string;
  public request_loading: boolean;

  constructor(private route: ActivatedRoute,  private dmConnectApiService: DmConnectApiService,
              private browserService: DmconnectBrowserService, private googleAnalytics: GoogleAnalyticsService,
              private workflowService: WorkflowService, private router: Router, private handler: WorkflowResponseHandler) {
    const routeData = route.snapshot;
    this.submissionId = routeData.queryParamMap.get('submission_id');
    this.quoteId = routeData.queryParamMap.get('quote_id');
    this.version = routeData.queryParamMap.get('version');
    this.policyId = routeData.queryParamMap.get('policy_id');
    this.userId = routeData.queryParamMap.get('user_id');
    this.dataSource = routeData.queryParamMap.get('data_source');
    this.clarionDoorHost = routeData.queryParamMap.get('clarion_door_host');
    this.enablePull = this.isPullEnabled(routeData.queryParamMap.get('enable_pull'));

    if (!this.submissionId) {
      this.handler.handle('status', {error: {
        workflow_id: 'SUBMISSION_ID_MISSING',
        message: 'A submission ID was not included in the request.'
      }});
    } else {
      this.dmConnectApiService.getStatus2('cd_mgahub', this.submissionId)
      .pipe(
        timeout(10000),
        mergeMap(result => this.handleStatusResponse(result, this)),
        mergeMap(wasHandled => {
          if (wasHandled) {
            return of(true);
          }
          return this.handler.handle('status', {error: {
            workflow_id: 'STATUS_REQUEST',
            message: 'An error was encountered while requesting DmConnect status.'
          }});
        }),
        catchError(err => {
          return this.handler.handle('status', {error: {
              workflow_id: 'STATUS_REQUEST',
              message: err instanceof TimeoutError
                ? 'The request timed out while requesting DmConnect status.'
                : 'An error was encountered while requesting DmConnect status.'
            }});
        })
      ).subscribe();
    }
  }

  ngOnInit(): void {
  }

  private handleStatusResponse(result, thisComponent: this): Observable<boolean> {
    if (!result || !result.success) {
      return of(false);
    }

    thisComponent.correlationId = result.correlation_id;

    if (thisComponent.correlationId && !thisComponent.enablePull){
      thisComponent.continueWorking();
      return of(true);
    } else if (thisComponent.correlationId && thisComponent.enablePull) {
      return of(true);
    } else if (!thisComponent.correlationId) {
      return thisComponent.workflowService.execute('outbound', {
        submission_id: thisComponent.submissionId,
        quote_id: thisComponent.quoteId,
        version: thisComponent.version,
        policy_id: thisComponent.policyId,
        user_id: thisComponent.userId,
        data_source: thisComponent.dataSource,
        dm_portal_id: 'cd_mgahub'
      }).pipe(mergeMap<WorkflowResult, Observable<boolean>>(r2 => {

        if (r2 && r2.output && r2.output.open_browser) {
          thisComponent.browserService.openFullBrowser(r2.output.open_browser);
          return of(true);
        }
        if (r2.success || r2.output.error){
          return of (true);
        }
        return of(false);
      }));
    }

    return of(false);
  }


  public isPullEnabled(enablePull: string): boolean {
    return enablePull && enablePull.toLowerCase() === 'true';
  }

  public pullIndication(): void {
    this.request_loading = true;
    this.googleAnalytics.event('pulled_cdmgahub_indication', 'clariondoor', 'Pulled indication from CDMGAHub');
    this.workflowService.execute('inbound', { id: this.correlationId, dm_target_id: this.submissionId, dm_portal_id: 'cd_mgahub', portal_id: 'cd_mgahub' }).
    subscribe(() => this.browserService.closeWindow());
  }

  public continueWorking(): void {
    this.googleAnalytics.event('continue_working_cdmgahub', 'clariondoor', 'Continuing work in CDMGAHub');
    this.browserService.openFullBrowser(this.clarionDoorHost + this.correlationId);
  }
}
