import {Component, OnInit} from '@angular/core';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {ODataSearchResponse} from '../ODataSearchResponse';
import {HttpClient, HttpParams} from '@angular/common/http';
import {from, Observable, of} from 'rxjs';
import {FeaturesService} from '../features.service';
import {Router} from '@angular/router';
import {HateoasRoutesService} from '../hateoas-routes.service';
@Component({
  selector: 'app-interface-costing',
  template: `<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Interface Costing</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-centered mb-0">
                <thead class="thead-light">
                <tr>
                  <th>Interface ID</th>
                  <th>Per User Rate $</th>
                  <th>Minimum Users</th>
                  <th>Active Users This Month</th>
                  <th>Estimated Cost $</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngIf="this.loading_cost">
                  <tr>
                    <td colspan="5" style="height: 50px; padding-top: 5px; padding-bottom: 0px; margin-bottom: 0px;">
                      <div class="shine" style="width: 100%; height: 75%;"></div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5" style="height: 50px; padding-top: 5px; padding-bottom: 0px; margin-bottom: 0px;">
                      <div class="shine" style="width: 100%; height: 75%;"></div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5" style="height: 50px; padding-top: 5px; padding-bottom: 0px; margin-bottom: 0px;">
                      <div class="shine" style="width: 100%; height: 75%;"></div>
                    </td>
                  </tr>
                </ng-container>
                <tr *ngFor="let item of this.interface_cost_page | async">
                  <td>{{item.id}}</td>
                  <td>
                      {{item.per_user_rate | currency}}
                  </td>
                  <td>
                      {{item.user_minimum}}
                  </td>
                  <td>
                      {{active_users_this_month}}
                  </td>
                  <td>
                    {{(item.user_minimum > active_users_this_month ? item.user_minimum*item.per_user_rate : active_users_this_month*item.per_user_rate)| currency}}
                  </td>
                </tr>

                </tbody>
              </table>
            </div> <!-- end table-responsive-->
          </div> <!-- end card body-->
        </div> <!-- end card -->
      </div> <!-- end col -->
    </div><!--end row-->
  </div><!-- end container-->`,
  styles: [`
    .shine {
      background-image: linear-gradient(to right, #f1f3fa00 0%, #dfe3f0 50%, #f1f3fa00 100%);
      background-repeat: no-repeat;
      background-size: 40% 100%;
      display: inline-block;
      position: relative;

      animation-play-state: running;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: shimmer;
      animation-timing-function: linear;
    }

    @keyframes shimmer {
      0% {
        background-position: -100% 0;
      }
      100% {
        background-position: 200% 0;
      }
    }
  `]
})

export class InterfaceCostingComponent implements OnInit {
  public interface_cost_count = 0;
  public interface_cost_page: Observable<InterfaceCost[]>;
  public loading_cost = false;
  public estimated_cost: number;
  public active_users_this_month = 3;
  constructor(private http: HttpClient,
              private features: FeaturesService,
              private router: Router,
              private hateoas: HateoasRoutesService) {
  }

  ngOnInit(): void {
    this.LoadInterfaceCosts();
  }
  private LoadInterfaceCosts(): void {
    this.loading_cost = true;
    this.interface_cost_page = this.features.getFeatures().pipe(mergeMap(response => {
      if (response.h_search_interface_pricings) {
        return this.http.get<ODataSearchResponse<InterfaceCost>>(response.h_search_interface_pricings,
          {
            headers: {'X-DM-Authorize': 'management_api'},
            params: new HttpParams().set('$count', true)
          });
      } else {
        return from(this.router.navigate(['/config/error/403'])).pipe(map(() => null));
      }
    }), switchMap(response => {
      response.items.forEach(item => {
        this.hateoas.store('h_read_interface_price', item.id, item.h_read_interface_price);
      });
      this.loading_cost = false;
      this.interface_cost_count = response.count;
      return of(response.items);
    }));
  }
}
class InterfaceCost {
  public id: number;
  public user_minimum: number;
  public per_user_rate: number;
  public h_read_interface_price: string;
}
